// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { TemplateDto, TemplateDtoFormGroup } from './TemplateDto';

export class TemplateEmailDto extends TemplateDto {
  subject: string = '';
}

export interface TemplateEmailDtoFormGroup extends TemplateDtoFormGroup {
  subject?: any;
}
