@if (numberBadge === 0) {
<mat-icon
  [style.width.px]="styleSize"
  [style.height.px]="styleSize"
  [style.font-size.px]="styleSize">
  <div [style]="'transform: rotate(' + rotate + 'deg)'">
    {{ icon }}
  </div>
</mat-icon>
} @else {
<mat-icon
  [style.width.px]="styleSize"
  [style.height.px]="styleSize"
  [style.font-size.px]="styleSize"
  [matBadge]="numberBadge"
  matBadgeColor="primary">
  <div [style]="'transform: rotate(' + rotate + 'deg)'">
    {{ icon }}
  </div>
</mat-icon>
}
