import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import {
  AccreditationsByAccountVoucherDto,
  AccreditationsByAccountVoucherDtoFormGroup,
} from '../../../../api/dtos/AccreditationsByAccountVoucherDto';
import { ApiAdminUploadFile } from '../../../../api/endpoints/ApiAdminUploadFile';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';

@Component({
  selector: 'mf-accreditations-by-account-voucher',
  templateUrl: './accreditations-by-account-voucher.component.html',
  styleUrls: ['./accreditations-by-account-voucher.component.scss'],
})
export class AccreditationsByAccountVoucherComponent implements OnInit {
  constructor(private _cfb: CustomFormBuilder, private _apiAdminUploadFile: ApiAdminUploadFile) {
    this.endpoint = (fileList: FileList, httpApiRequestOptions: HttpApiRequestOptions) =>
      this._apiAdminUploadFile.uploadFile(fileList, httpApiRequestOptions);
  }

  endpoint: any;
  @Input()
  accreditationsByAccountVoucherDto: AccreditationsByAccountVoucherDto;

  @Output()
  save = new EventEmitter<AccreditationsByAccountVoucherDto>();

  enumName = enumName_mi_pspc;

  ngOnInit(): void {
    this.accreditationsByAccountVoucherForm.patchValue(this.accreditationsByAccountVoucherDto);
  }

  accreditationsByAccountVoucherForm = this._cfb.group<AccreditationsByAccountVoucherDtoFormGroup>({
    accreditationId: ['', Validators.required],
    accreditationsByAccountId: ['', Validators.required],
    imageUrl: [null, Validators.required, 'Comprobante de cobro'],
  });
}
