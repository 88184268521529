import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModelValueBaseComponent } from '../model-value-base.component';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-json';

@Component({
  selector: 'mf-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeEditorComponent),
      multi: true,
    },
  ],
})
export class CodeEditorComponent extends ModelValueBaseComponent<string> {
  @ViewChild('editingTextArea')
  editingTextArea: ElementRef;

  @ViewChild('codeElement')
  codeElement: ElementRef;

  @Input()
  height = 520;

  @Input()
  language: 'html' | 'json' | 'css' = 'html';

  get rowStyle() {
    return 'min-height: ' + this.height + 'px';
  }

  ngAfterViewInit() {
    setTimeout(() => Prism.highlightElement(this.codeElement.nativeElement), 0);
  }

  updateCodeValue(): void {
    this.codeElement.nativeElement.textContent = this.value;
    Prism.highlightElement(this.codeElement.nativeElement);
    this.syncScroll();
  }

  syncScroll() {
    /* Scroll result to scroll coords of event - sync with textarea */
    const resultEditing = document.querySelector('#editing');
    const resultElement = document.querySelector('#highlighting');
    if (resultElement && resultEditing) {
      resultElement.scrollTop = resultEditing.scrollTop;
      resultElement.scrollLeft = resultEditing.scrollLeft;
    }
  }

  insertAtCaret(text: string) {
    let currentText = this.value;
    if (currentText == null) {
      currentText = '';
    }

    const cartePosition = this.editingTextArea.nativeElement.selectionStart;

    const textBefore = currentText.substring(0, cartePosition);
    const textAfter = currentText.substring(cartePosition, currentText.length);
    this.value = textBefore + text + textAfter;
    this.updateCodeValue();
  }
}
