import {Component, HostBinding, HostListener, Input} from '@angular/core';
import {Notification, NotificationService, NotificationType} from '../notification.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {

  @Input()
  notification: Notification;

  constructor(private notificationService: NotificationService) {
  }

  @HostBinding('class')
  get class() {
    switch (this.notification.type) {
      case NotificationType.Error:
        return 'show error';
      case NotificationType.Warning:
        return 'show warning';
      case NotificationType.Info:
        return 'show info';
      default:
        return 'show success';
    }
  }

  @HostListener('click')
  onClick() {
    this.notificationService.remove();
  }
}
