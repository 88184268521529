// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { Currency } from '../enums/Currency';

export class OperationSimpleDto extends BaseEntityDto {
  currency: Currency;
  operationNumber: number | null = null;
  total: number = 0;
}

export interface OperationSimpleDtoFormGroup extends BaseEntityDtoFormGroup {
  currency?: any;
  operationNumber?: any;
  total?: any;
}
