<mf-page title="Configuración">
  <mf-form
    [formGroup]="configurationForm"
    [entity]="configurationDto"
    (save)="save($event)">
    <mf-column>
      <mf-field-text formControlName="issueHours" />
    </mf-column>

    <mf-column>
      <mf-field-text formControlName="daMapTemplateId" />
    </mf-column>
    <mf-column>
      <mf-field-text formControlName="daMapUyuAccountId" />
    </mf-column>
    <mf-column>
      <mf-field-text formControlName="daMapUsdAccountId" />
    </mf-column>
    <mf-column>
      <mf-field-text formControlName="daMapUyuConciliationJobId" />
    </mf-column>
    <mf-column>
      <mf-field-text formControlName="daMapUsdConciliationJobId" />
    </mf-column>
  </mf-form>
</mf-page>
