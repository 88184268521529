// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum FilterDateRangeType {
	Today = 1,
	Yesterday = 2,
	ThisWeek = 3,
	ThisMonth = 4,
	ThisQuarter = 5,
	ThisYear = 6,
	LastWeek = 7,
	LastMonth = 8,
	LastQuarter = 9,
	LastYear = 10,
	Range = 11,
	Date = 12,
}
