// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { TransferVoucherForCollectOrderItemDto } from './TransferVoucherForCollectOrderItemDto';
import { Currency } from '../enums/Currency';
import { GatewayBankAccountDto } from './GatewayBankAccountDto';
import { BankAccountDto } from './BankAccountDto';

export class TransferVoucherForCollectOrderDto extends BaseEntityDto {
  amount: number = 0;
  clientId: string = '';
  createsNewOrderForRemainingAmount: boolean = false;
  createsNewOrderForRemainingAmountByPassExternalIdValidation: boolean = false;
  collectOrders: TransferVoucherForCollectOrderItemDto[] = [];
  currency: Currency;
  uploadDate: Date = new Date();
  destinationBankAccountId: string = '';
  destinationBankAccount: GatewayBankAccountDto;
  voucherUrl: string = '';
  reference: string = '';
  sourceBankAccountId: string = '';
  sourceBankAccount: BankAccountDto;
}

export interface TransferVoucherForCollectOrderDtoFormGroup extends BaseEntityDtoFormGroup {
  amount?: any;
  clientId?: any;
  createsNewOrderForRemainingAmount?: any;
  createsNewOrderForRemainingAmountByPassExternalIdValidation?: any;
  collectOrders?: any;
  currency?: any;
  uploadDate?: any;
  destinationBankAccountId?: any;
  destinationBankAccount?: any;
  voucherUrl?: any;
  reference?: any;
  sourceBankAccountId?: any;
  sourceBankAccount?: any;
}
