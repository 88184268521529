@if (!hideFilters || !!actionButtonText || !!actionButtons) {
<div class="grid-actions">
  <div class="grid-actions-tags-container">
    @if (actionButtonText) {
    <mf-button
      [text]="actionButtonText"
      [disabled]="disableActionButton"
      (click)="actionButtonClick.emit()"></mf-button>
    } @for (action of actionButtons; track $index) {
    <mf-button
      text="{{ action.text }}"
      (click)="onActionButtonClick(action.onClick)"></mf-button>
    } @if (!hideFilters) {
    <mf-grid-filter-tags [gridFilterService]="gridFilterService"></mf-grid-filter-tags>
    }
  </div>
  <div class="grid-actions-filters-container">
    @if (!hideFilters) {
    <mf-button-icon
      [icon]="icons.filterList"
      tooltip="Aplicar filtros de búsqueda"
      (click)="toggleFiltersPopup()">
    </mf-button-icon>
    }
    <mf-button-icon
      [icon]="icons.refresh"
      tooltip="Recargar la grilla"
      (click)="refresh()">
    </mf-button-icon>
  </div>
</div>
}
<div
  #gridRef
  class="grid">
  <div class="grid-header-row">
    @if(rowSelection) {
    <div
      class="grid-header-column"
      style="flex: 48px 0 0; display: block; padding: 0"></div>
    } @for (internalColumn of internalColumns(); track $index) {
    <div
      class="grid-header-column"
      [ngStyle]="{
        flex: internalColumn.calculatedWith + ' 0 0%',
        minWidth: '0',
        textAlign: internalColumn.column.align ?? internalColumn.column.cellOptions?.type == cellValueType.Numeric ? 'right' : undefined
      }">
      <span class="grid-header-text">
        {{ internalColumn.column.header }}
      </span>
      @if (!internalColumn.column.orderByPrevent) {
      <button
        class="grid-header-button-sort"
        mat-button
        (click)="sortBy(internalColumn.column)">
        <mf-icon
          [class.sorting]="isSortingBy(internalColumn.column)"
          icon="{{ isSortingByAsc(internalColumn.column) ? icons.expandLess : icons.expandMore }}"
          (click)="sortBy(internalColumn.column)"></mf-icon>
      </button>
      }
    </div>
    } @if (menuItems) {
    <div
      class="grid-header-column"
      style="flex: 48px 0 0; display: block"></div>
    }
  </div>

  <div class="grid-body">
    @if (isLoading()) {
    <div class="grid-row">
      <div class="grid-column loading">
        <mf-spinner></mf-spinner>
        Cargando...
      </div>
    </div>
    } @if (!isLoading()) { @if (page() && page()?.pageItemList?.length) { @for (row of page()?.pageItemList; track $index) {
    <div class="grid-row">
      @if (rowSelection) {
      <div
        class="grid-column text-center"
        style="flex: 48px 0 0; display: block; padding: 0">
        <mf-field-check
          type="checkbox"
          [ngModel]="row.selected"
          (ngModelChange)="selectItem(row)" />
      </div>
      } @for (internalColumn of internalColumns(); track $index) {
      <div
        class="grid-column"
        [ngStyle]="{
          flex: internalColumn.calculatedWith + ' 0 0%',
          minWidth: '0',
          textAlign: internalColumn.column.align ?? internalColumn.column.cellOptions?.type == cellValueType.Numeric ? 'right' : undefined
        }"
        [innerHTML]="renderColumnValue(row, internalColumn.column)"></div>
      } @if (menuItems) {
      <div
        class="grid-column text-center"
        style="flex: 48px 0 0; display: block; padding: 0">
        <mf-grid-menu
          [menuItems]="menuItems"
          [item]="row.item"></mf-grid-menu>
      </div>
      }
    </div>
    } } @if (!page() || page()?.pageItemList?.length == 0) {
    <div class="grid-row">
      @if (errorMessage) {
      <div class="grid-column error-message">
        {{ errorMessage }}
      </div>
      } @if (!errorMessage) {
      <div class="grid-column no-data">No hay datos para mostrar</div>
      }
    </div>
    } }
  </div>
  @if (searchDto && page()) {
  <mf-grid-paginator
    [page]="page()!"
    [searchDto]="searchDto"
    (pageChangeEvent)="reloadPage()"></mf-grid-paginator>
  }
</div>
