// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class BaseEntityDto  {
  id: string = '';
  inactive: boolean = false;
  displayText: string = '';
}

export interface BaseEntityDtoFormGroup  {
  id?: any;
  inactive?: any;
  displayText?: any;
}
