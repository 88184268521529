import { Component } from '@angular/core';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

@Component({
  selector: 'mf-field-check',
  templateUrl: './field-check.component.html',
  styleUrls: ['./field-check.component.scss'],
})
export class FieldCheckComponent extends FieldMatBaseComponent<boolean> {
  override get controlType(): string {
    return 'field-check';
  }
}
