import { Component, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { ConfigurationDto, ConfigurationDtoFormGroup } from '../../../api/dtos/ConfigurationDto';
import { ApiClientAdminConfiguration } from '../../../api/endpoints/ApiClientAdminConfiguration';
import { ApiAdminConfiguration } from '../../../api/endpoints/ApiAdminConfiguration';

@Component({
  selector: 'mf-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent {
  constructor(
    private _cfb: CustomFormBuilder,
    private _configurationApiClientAdmin: ApiClientAdminConfiguration,
    private _configurationApiAdmin: ApiAdminConfiguration,
    private _notificationService: NotificationService
  ) {
    this.loadConfiguration();
  }

  async loadConfiguration() {
    this.configurationDto = await this._configurationApiClientAdmin.get();
    this.configurationForm.patchValue(this.configurationDto);
  }

  @Input()
  configurationDto: ConfigurationDto;

  configurationForm = this._cfb.group<ConfigurationDtoFormGroup>({
    id: [GUID_EMPTY],
    issueRenotifyTimeout: [null, Validators.required, 'Horas para incidentes'],
    daMapTemplateId: [null, Validators.required, 'DaMap template id'],
    daMapUyuAccountId: [null, Validators.required, 'Cuenta DaMap UYU'],
    daMapUsdAccountId: [null, Validators.required, 'Cuenta DaMap USD'],
    daMapUyuConciliationJobId: [null, Validators.required, 'Job id conciliación DaMap UYU'],
    daMapUsdConciliationJobId: [null, Validators.required, 'Job id conciliación DaMap USD'],
    domain: [null, Validators.required, 'Domain'],
    currencyListCsv: [null, Validators.required, 'Currencies'],
  });

  async save(form: ConfigurationDto) {
    await this._configurationApiAdmin.edit(form);
    this._notificationService.showSuccess('La configuración se editó con éxito');
  }
}
