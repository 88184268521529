// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { PersonDto, PersonDtoFormGroup } from './PersonDto';

export class NaturalPersonDto extends PersonDto {
  firstName: string = '';
  lastName: string = '';
}

export interface NaturalPersonDtoFormGroup extends PersonDtoFormGroup {
  firstName?: any;
  lastName?: any;
}
