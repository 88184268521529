<mf-page title="Pasarelas">
  <mf-row>
    <mf-grid
      #grid
      actionButtonText="Nueva pasarela"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems"
      (actionButtonClick)="openNewGatewayPopup()" />
  </mf-row>
</mf-page>
