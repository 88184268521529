// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { SearchOrderDto } from './SearchOrderDto';

export class SearchDto<T>  {
  echoId: number = 0;
  includedFields: string[] = [];
  orderBy: SearchOrderDto[] = [];
  filters: T;
}

export interface SearchDtoFormGroup<T>  {
  echoId?: any;
  includedFields?: any;
  orderBy?: any;
  filters?: any;
}
