import { Pipe, PipeTransform } from '@angular/core';
import { EnumTranslateProviderService } from '../services/enum-translate-provider.service';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(private _provider: EnumTranslateProviderService) {}

  transform(value: number, enumName: string, moduleName?: string): string {
    return this._provider.translateEnum(enumName, value, moduleName);
  }
}
