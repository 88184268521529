// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { LogEventDto } from "../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { LogEventFiltersDto } from "../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { LogEventCompleteDto } from "../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventCompleteDto";

@Injectable({providedIn: 'root'})
export class ApiAdminLoggingViewer {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getPaged(
    searchDto: PagedSearchDto<LogEventFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<LogEventDto>> {
    return this._httpApiClient.post("/admin/logging-viewer/paged", searchDto, httpApiRequestOptions);
  }

  getEventComplete(
    partitionKey: string,
    
    rowKey: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<LogEventCompleteDto> {
    return this._httpApiClient.get("/admin/logging-viewer/complete?" + 
      (partitionKey ? "&partitionKey=" + partitionKey : "") + 
      (rowKey ? "&rowKey=" + rowKey : ""), httpApiRequestOptions);
  }

}
