// File generated by: MiFinanzas.Utils.Net.MifinanzasPostBuildEvents
export enum LoggingLevel {
	Trace = 1,
	Debug = 2,
	Information = 3,
	Warning = 4,
	Error = 5,
	Critical = 6,
	None = 7,
}
