import { ComponentType } from '@angular/cdk/portal';
import { Component, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../notification/notification.service';
import { YesOrNoModalComponent } from '../modals/yes-or-no-modal.component';

class ModalRef {
  constructor(public modalInfo: ModalInfo) {}

  public matDialogRef: MatDialogRef<any>;
}

@Injectable()
export class ModalService {
  private _modalRefList: ModalRef[] = [];

  constructor(private _matDialog: MatDialog, private _notificationService: NotificationService) {}

  private open<T>(component: ComponentType<T>, info: ModalInfo, matDialogConfig: MatDialogConfig) {
    const modalRef = new ModalRef(info);
    this._modalRefList.push(modalRef);
    modalRef.matDialogRef = this._matDialog.open(component, matDialogConfig);
    modalRef.matDialogRef.afterClosed().subscribe(() => {
      this._modalRefList.pop();
    });
  }

  private openSide<T>(component: ComponentType<T>, info: ModalInfo, width: string) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
      top: '0',
      right: '0',
    };
    matDialogConfig.width = width;
    matDialogConfig.maxWidth = '100%';
    matDialogConfig.height = '100vh';
    matDialogConfig.data = info.openData;
    matDialogConfig.panelClass = ['modal-animation'];
    this.open(component, info, matDialogConfig);
  }

  openRightModal<T>(component: ComponentType<T>, info: ModalInfo) {
    this.openSide(component, info, '85%');
  }

  openRightModalSmall<T>(component: ComponentType<T>, info: ModalInfo) {
    this.openSide(component, info, '25%');
  }

  openRightModalFull<T>(component: ComponentType<T>, info: ModalInfo) {
    this.openSide(component, info, '100%');
  }

  openNormalModal<T>(component: ComponentType<T>, info: ModalInfo) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '35%';
    matDialogConfig.data = info.openData;
    this.open(component, info, matDialogConfig);
  }

  openLargeModal<T>(component: ComponentType<T>, info: ModalInfo) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '70%';
    matDialogConfig.data = info.openData;
    this.open(component, info, matDialogConfig);
  }

  openYesOrNoModal(message: string, info: ModalInfo) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.width = '35%';
    matDialogConfig.data = message;
    info.openData = message;
    this.open(YesOrNoModalComponent, info, matDialogConfig);
  }

  get currentModal() {
    return this._modalRefList[this._modalRefList.length - 1];
  }

  get openData() {
    return this.currentModal.modalInfo.openData;
  }

  closeAsSuccess(message?: string, info?: any) {
    if (message) {
      this._notificationService.showSuccess(message);
    }

    const currentDialogRef = this.currentModal;
    currentDialogRef!.matDialogRef.close();

    if (currentDialogRef?.modalInfo.onCloseSuccess) {
      currentDialogRef.modalInfo.onCloseSuccess(info);
    }
  }

  closeAsCancel(info?: any) {
    const currentDialogRef = this.currentModal;
    currentDialogRef!.matDialogRef.close();
    if (currentDialogRef?.modalInfo.onCloseCancel) {
      currentDialogRef?.modalInfo.onCloseCancel(info);
    }
  }

  closeWithWarnings(message?: string, info?: any) {
    if (message) {
      this._notificationService.showWarning(message);
    }

    const currentDialogRef = this.currentModal;
    currentDialogRef!.matDialogRef.close();

    if (currentDialogRef?.modalInfo.onCloseSuccess) {
      currentDialogRef.modalInfo.onCloseSuccess(info);
    }
  }
}

export class ModalInfo {
  openData?: any;
  onCloseSuccess?: (info: any) => void;
  onCloseCancel?: (info: any) => void;
}
