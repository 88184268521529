{
	"_locale": "EsUy",
	"TemplateSectionType": {
		"4": "Asunto correo",
		"9": "Campo repetible",
		"5": "Plantilla genérica",
		"10": "Esquema relacionado",
		"6": "Contenedor",
		"7": "Encabezado de campo repetible",
		"8": "Pie de campo repetible",
		"1": "Cuerpo",
		"2": "Encabezado",
		"3": "Pie de página"
	},
	"TemplateGenericType": {
		"1": "Contenedor",
		"3": "Estilo en línea",
		"2": "Texto simple"
	},
	"TemplateDesignFieldType": {
		"3": "Repetible",
		"2": "Condicional",
		"1": "Campo",
		"5": "Adjunto",
		"4": "Esquema relacionado"
	},
	"PaperSize": {
		"12": "Rol 120mm",
		"9": "Legal",
		"10": "Folio",
		"2": "A3",
		"3": "A4",
		"5": "A5",
		"7": "B5",
		"6": "B4",
		"8": "Carta",
		"1": "Custom",
		"11": "Rol 80mm"
	},
	"TemplateGroup": {
		"2": "Correo",
		"3": "PDF",
		"1": "Genérico"
	},
	"PaperOrientation": {
		"1": "Landscape",
		"2": "Portrait"
	}
}
