import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GatewayBankAccountDto } from '../../../../api/dtos/GatewayBankAccountDto';
import { GatewayBankAccountFiltersDto } from '../../../../api/dtos/GatewayBankAccountFiltersDto';
import { ApiAdminGatewayBankAccount } from '../../../../api/endpoints/ApiAdminGatewayBankAccount';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { GatewayBankAccountCreateComponent } from './gateway-bank-account-create.component';
import { GatewayBankAccountEditComponent } from './gateway-bank-account-edit.component';
import { Country } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/Country';

@Component({
  selector: 'mf-gateway-bank-account-grid',
  templateUrl: './gateway-bank-account-grid.component.html',
  styleUrls: ['./gateway-bank-account-grid.component.scss'],
})
export class GatewayBankAccountGridComponent {
  constructor(private _apiAdminGatewayBankAccount: ApiAdminGatewayBankAccount, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  gatewayBankAccount: GatewayBankAccountDto;
  refreshIcon = Icons.refresh;

  columns: IGridColumn<GatewayBankAccountDto>[] = [
    { field: nameof<GatewayBankAccountDto>((p) => p.bank.name), header: 'Banco', width: 10 },
    { field: nameof<GatewayBankAccountDto>((p) => p.accountNumber), header: 'Nro cuenta', width: 10 },
    {
      field: nameof<GatewayBankAccountDto>((p) => p.currency),
      header: 'Moneda',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<GatewayBankAccountFiltersDto>((p) => p.currencyList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<GatewayBankAccountDto>((p) => p.balance),
      header: 'Saldo',
      width: 10,
      cellOptions: {
        type: CellValueType.Numeric,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (gatewayBankAccountDto: GatewayBankAccountDto) => {
        this._modalService.openRightModal(GatewayBankAccountEditComponent, {
          openData: gatewayBankAccountDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<GatewayBankAccountFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminGatewayBankAccount.getPaged(search, httpApiRequestOptions);

  openNewGatewayBankAccountPopup() {
    this._modalService.openRightModal(GatewayBankAccountCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }

  async updateGatewayBalance() {
    await this._apiAdminGatewayBankAccount.updateGatewayBalance(Country.Uruguay);
    this.grid.reloadPage();
  }
}
