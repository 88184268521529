// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class BankAccountValidationInfoDto  {
  externalServiceIsSuccess: boolean = false;
  holderName: string = '';
  currencies: string = '';
  errorMessage: string = '';
}

export interface BankAccountValidationInfoDtoFormGroup  {
  externalServiceIsSuccess?: any;
  holderName?: any;
  currencies?: any;
  errorMessage?: any;
}
