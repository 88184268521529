// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';

export class AccreditationsByAccountVoucherDto extends BaseEntityDto {
  accreditationId: string = '';
  accreditationsByAccountId: string = '';
  imageUrl: string = '';
}

export interface AccreditationsByAccountVoucherDtoFormGroup extends BaseEntityDtoFormGroup {
  accreditationId?: any;
  accreditationsByAccountId?: any;
  imageUrl?: any;
}
