// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { TemplateTypeDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateTypeDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { TemplateTypeFiltersDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateTypeFiltersDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiTemplateType {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  suggest(
    searchDto: SuggestSearchDto<TemplateTypeFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<TemplateTypeDto>> {
    return this._httpApiClient.post("/shared/template/type/suggest", searchDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateTypeDto> {
    return this._httpApiClient.get("/shared/template/type/" + id, httpApiRequestOptions);
  }

}
