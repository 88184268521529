<mat-label> {{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }} </mat-label>
<mat-chip-listbox
  selectable
  multiple
  [(ngModel)]="value">
  @for (element of elementList; track $index) {
  <mat-chip-option
    (click)="onSelectedItemChange()"
    #chipOption
    [value]="element">
    {{ element }}
  </mat-chip-option>
  }
</mat-chip-listbox>
