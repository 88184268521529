<mf-grid
  #grid
  actionButtonText="{{ showCreateButton ? 'Nueva orden de cobro' : '' }}"
  [hideFilters]="hideFilters"
  [disableActionButton]="disableCreateButton"
  [initialFilters]="filters"
  [apiEndpoint]="apiEndpoint"
  [columns]="columns"
  [menuItems]="menuItems"
  [mustIncludeFields]="mustIncludeFields"
  (actionButtonClick)="openNewCollectOrderPopup()" />
