<div
  class="sidenav"
  [ngClass]="collapsed() ? 'sidenav-collapsed' : ''">
  <input
    type="text"
    title="Buscar..."
    [(ngModel)]="searchText"
    (keyup)="search()"
    class="search-box form-control"
    placeholder="Buscar..." />

  @if (!delaySearch) { @if (displayMenuItems.length > 0) { @for (menuGroup of displayMenuItems; track $index) {
  <mf-menu-group
    [menuGroup]="menuGroup"
    [roleProvider]="roleProvider"
    (navigateTo)="navigate($event)">
  </mf-menu-group>
  }} }
</div>

<div
  class="backdrop"
  [ngClass]="collapsed() ? '' : 'show'"
  (click)="closeSidenav()"></div>
