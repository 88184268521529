import { Component } from '@angular/core';
import { FilterRangeNumericDto } from '../../../../../../ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';
import { IGridFilterNumeric } from '../../interface/grid-filter';
import { GridFilterBase } from '../grid-filter-base';

@Component({
  selector: 'mf-grid-filter-numeric-range',
  templateUrl: './grid-filter-numeric-range.component.html',
  styleUrls: ['./grid-filter-numeric-range.component.scss'],
})
export class GridFilterNumericRangeComponent extends GridFilterBase<IGridFilterNumeric, FilterRangeNumericDto | null> {
  min: number | null = null;
  max: number | null = null;

  onNgModelChange(): void {
    if (this.min != null || this.max != null) {
      const value = new FilterRangeNumericDto();
      value.min = this.min;
      value.max = this.max;
      this.value = value;
    } else {
      this.value = this.value;
    }
  }

  protected override onWriteValue(val: FilterRangeNumericDto | null): void {
    this.min = val?.min ?? null;
    this.max = val?.max ?? null;
  }
}
