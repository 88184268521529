// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { TemplateDto, TemplateDtoFormGroup } from './TemplateDto';
import { PaperOrientation } from '../enums/PaperOrientation';
import { PaperSize } from '../enums/PaperSize';

export class TemplatePdfDto extends TemplateDto {
  footer: string = '';
  footerIgnoreFirstPage: boolean = false;
  header: string = '';
  headerIgnoreFirstPage: boolean = false;
  displayPageNumber: boolean = false;
  orientation: PaperOrientation;
  isGrayScale: boolean = false;
  paperSize: PaperSize;
  paperCustomHeight: number | null = null;
  paperCustomWidth: number | null = null;
  marginBottom: number | null = null;
  marginLeft: number | null = null;
  marginRight: number | null = null;
  marginTop: number | null = null;
  showPageNumbers: boolean = false;
}

export interface TemplatePdfDtoFormGroup extends TemplateDtoFormGroup {
  footer?: any;
  footerIgnoreFirstPage?: any;
  header?: any;
  headerIgnoreFirstPage?: any;
  displayPageNumber?: any;
  orientation?: any;
  isGrayScale?: any;
  paperSize?: any;
  paperCustomHeight?: any;
  paperCustomWidth?: any;
  marginBottom?: any;
  marginLeft?: any;
  marginRight?: any;
  marginTop?: any;
  showPageNumbers?: any;
}
