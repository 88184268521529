// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { TemplateDesignTestDto, TemplateDesignTestDtoFormGroup } from './TemplateDesignTestDto';
import { TemplateEmailDto } from './TemplateEmailDto';

export class TemplateDesignTestEmailDto extends TemplateDesignTestDto {
  testToAdminUserId: string | null = null;
  testEmailTo: string = '';
  testEmailToName: string = '';
  template: TemplateEmailDto;
}

export interface TemplateDesignTestEmailDtoFormGroup extends TemplateDesignTestDtoFormGroup {
  testToAdminUserId?: any;
  testEmailTo?: any;
  testEmailToName?: any;
  template?: any;
}
