// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { ValueListDto } from "../dtos/ValueListDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { ValueListFiltersDto } from "../dtos/ValueListFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminValueList {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    valueListDto: ValueListDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ValueListDto> {
    return this._httpApiClient.post("/admin/value-list", valueListDto, httpApiRequestOptions);
  }

  edit(
    valueListDto: ValueListDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ValueListDto> {
    return this._httpApiClient.put("/admin/value-list", valueListDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ValueListDto> {
    return this._httpApiClient.get("/admin/value-list/" + id, httpApiRequestOptions);
  }

  getForType(
    type: number,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ValueListDto[]> {
    return this._httpApiClient.get("/admin/value-list/for-type/" + type, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<ValueListFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<ValueListDto>> {
    return this._httpApiClient.post("/admin/value-list/paged", filters, httpApiRequestOptions);
  }

  suggest(
    valueListFilterDto: SuggestSearchDto<ValueListFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<ValueListDto>> {
    return this._httpApiClient.post("/admin/value-list/suggest", valueListFilterDto, httpApiRequestOptions);
  }

}
