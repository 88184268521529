<form [formGroup]="addressForm">
  <mf-column>
    <mf-field-text formControlName="addressLine" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="city" />
  </mf-column>
  <mf-column>
    <mf-field-select
      formControlName="country"
      [enumName]="enumName.Country"
      [enumNameModule]="enumNameModule" />
  </mf-column>
</form>
