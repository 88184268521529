<div class="notification">
  <div>
    <div class="title">
      {{ notification.title }}
    </div>
    <span class="date">
      {{ notification.date | date : 'dd-MM-yy, h:mm a' }}
    </span>
    <p>
      {{ notification.text }}
    </p>
  </div>

  <div class="btn-container">
    <mf-button-icon
      [icon]="!notification.isRead ? icons.markAsRead : icons.markAsUnread"
      size="small"
      (click)="toggleRead(notification.isRead)">
    </mf-button-icon>
    <mf-button-icon
      [icon]="icons.delete"
      size="small"
      (click)="inactivate()"></mf-button-icon>

    @if (notification.hasUrl) {
    <mf-button-icon
      [icon]="icons.download"
      size="small"
      (click)="downloadFile()"></mf-button-icon>
    } @if (notification.hasIssue) {
    <mf-button-icon
      [icon]="icons.openInNew"
      size="small"
      (click)="navigateToRelatedEntityPage()"></mf-button-icon>
    }
  </div>
</div>
