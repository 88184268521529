import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mf-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class LayoutFormComponent {
  @Input() entity: any;
  @Input() formGroup: FormGroup;
  @Input() isLoading: boolean = false;
  @Input() validationSummaryHide: boolean = false;
  @Input() acceptButtonHide: boolean = false;
  @Input() textButton = 'Aceptar';
  @Input() closeButtonShow = false;
  @Input() closeButtonText = 'Cerrar';
  @Input() disableButton = false;

  @Output() save = new EventEmitter<any>();
  @Output() closeButtonClick = new EventEmitter<any>();

  ngOnInit(): void {
    //TODO: Uncomment and make it work with specs
    // if (!this.entity) {
    //   throw new Error('Entity attribute bind is required for mf-form');
    // }
    this.formGroup.patchValue(this.entity);
  }

  triedSubmit = false;

  submit() {
    this.triedSubmit = true;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.entity = { ...this.entity, ...this.formGroup.value };
      this.save.emit(this.entity);
    }
  }
}
