// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { TemplateDesignTestDto, TemplateDesignTestDtoFormGroup } from './TemplateDesignTestDto';
import { TemplateGenericDto } from './TemplateGenericDto';

export class TemplateDesignTestGenericDto extends TemplateDesignTestDto {
  template: TemplateGenericDto;
}

export interface TemplateDesignTestGenericDtoFormGroup extends TemplateDesignTestDtoFormGroup {
  template?: any;
}
