// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { CompanyDto } from "../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { CompanyFiltersDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/CompanyFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminCompany {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    companyDto: CompanyDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CompanyDto> {
    return this._httpApiClient.post("/admin/company", companyDto, httpApiRequestOptions);
  }

  edit(
    companyDto: CompanyDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CompanyDto> {
    return this._httpApiClient.put("/admin/company", companyDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CompanyDto> {
    return this._httpApiClient.get("/admin/company/" + id, httpApiRequestOptions);
  }

  getByDocument(
    country: number,
    
    idDocumentType: number,
    
    documentNumber: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CompanyDto> {
    return this._httpApiClient.get("/admin/company/by-document?" + 
      (country ? "&country=" + country : "") + 
      (idDocumentType ? "&idDocumentType=" + idDocumentType : "") + 
      (documentNumber ? "&documentNumber=" + documentNumber : ""), httpApiRequestOptions);
  }

  getDefault(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CompanyDto> {
    return this._httpApiClient.get("/admin/company/default", httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<CompanyFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<CompanyDto>> {
    return this._httpApiClient.post("/admin/company/paged", filters, httpApiRequestOptions);
  }

  suggest(
    companyFilterDto: SuggestSearchDto<CompanyFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<CompanyDto>> {
    return this._httpApiClient.post("/admin/company/suggest", companyFilterDto, httpApiRequestOptions);
  }

}
