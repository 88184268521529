import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiAdminAppUser } from '../../../api/endpoints/ApiAdminAppUser';
import { AppUserDto } from '../../../api/dtos/AppUserDto';
import { AppUserFiltersDto } from '../../../api/dtos/AppUserFiltersDto';
import { AppUserCreateComponent } from './app-user-create.component';
import { AppUserEditComponent } from './app-user-edit.component';
import { AppUserRolesComponent } from './app-user-roles.component';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';

@Component({
  selector: 'mf-app-user-grid',
  templateUrl: './app-user-grid.component.html',
  styleUrls: ['./app-user-grid.component.scss'],
})
export class AppUserGridComponent {
  constructor(private _apiAdminAppUser: ApiAdminAppUser, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  user: AppUserDto;

  columns: IGridColumn<AppUserDto>[] = [
    { field: nameof<AppUserDto>((p) => p.naturalPerson.email), header: 'Nombre', width: 60 },
    { field: nameof<AppUserDto>((p) => p.naturalPerson.firstName), header: 'Nombre', width: 20 },
    { field: nameof<AppUserDto>((p) => p.naturalPerson.lastName), header: 'Nombre', width: 20 },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (userDto: AppUserDto) => {
        this._modalService.openRightModal(AppUserEditComponent, {
          openData: userDto.id,
          onCloseSuccess: (userDto: AppUserDto) => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Roles',
      icon: Icons.edit,
      onClick: (userDto: AppUserDto) => {
        this._modalService.openRightModal(AppUserRolesComponent, {
          openData: userDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<AppUserFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminAppUser.getPaged(search, httpApiRequestOptions);

  openNewUserPopup() {
    this._modalService.openRightModal(AppUserCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
