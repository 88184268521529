<mf-form
  [formGroup]="clientForm"
  [entity]="clientDto"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-field-select
      formControlName="country"
      [enumName]="enumName.Country"
      [enumNameModule]="enumNameModule" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="endpointUrl" />
  </mf-column>
  <mf-column>
    <mf-autocomplete-company
      formControlName="company"
      (itemIdChanged)="clientForm.patchValue({ companyId: $event })" />
    <mf-field-text formControlName="apiToken" />
  </mf-column>
</mf-form>
