<mat-form-field [style.width.%]="100">
  <mat-label>{{ getLabel }}{{ errorNgControl.errors?.required != undefined ? '*' : '' }}</mat-label>
  <input
    matInput
    title="{{ value }}"
    [(ngModel)]="value"
    [errorStateMatcher]="errorStateMatcher"
    [disabled]="disabled || readOnly"
    [readonly]="readOnly"
    [matAutocomplete]="auto"
    (ngModelChange)="onChange()"
    (focus)="onFocus()"
    (blur)="onBlur()" />
  <mat-icon
    matSuffix
    class="icon-expand"
    (click)="expandOptions()">
    {{ icons.expandMore }}
  </mat-icon>

  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelectedItemChange($event.option.value)">
    @for (item of result()?.itemList; track $index) {
    <mat-option
      [value]="item"
      [disabled]="item.mainText == value">
      <span class="main-text">{{ item.mainText }}</span>
      @if (item.secondaryText) {
      <span class="secondary-text">
        {{ item.secondaryText }}
      </span>
      }
    </mat-option>
    } @if (!isLoading && result()?.itemList?.length == 0) {
    <mat-option disabled> No hay resultados para mostrar </mat-option>
    } @if (isLoading) {
    <mat-option disabled>
      <span class="searching-text">
        <mf-spinner></mf-spinner>
        Buscando...
      </span>
    </mat-option>
    }
  </mat-autocomplete>
  @if (validationMessage) {
  <mat-error>
    {{ validationMessage }}
  </mat-error>
  }
</mat-form-field>
