import { Component, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { ClientDto } from '../../../api/dtos/ClientDto';
import { ClientFiltersDto } from '../../../api/dtos/ClientFiltersDto';
import { ApiAdminClient } from '../../../api/endpoints/ApiAdminClient';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';

@Component({
  selector: 'mf-autocomplete-client',
  templateUrl: './autocomplete-client.component.html',
  styleUrls: ['./autocomplete-client.component.scss'],
})
export class AutocompleteClientComponent extends AutoCompleteBaseEntityComponent<ClientDto | null, ClientFiltersDto> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef, private _apiClient: ApiAdminClient) {
    super(ngControl, elRef);
  }

  override defaultOrderBy = nameof<ClientDto>((p) => p.name);

  override endpointSuggest = (search: SuggestSearchDto<ClientFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClient.suggest(search, httpApiRequestOptions);
}
