<mf-page title="{{ message }}">
  <mf-row align="right">
    <mf-button
      text="Cerrar"
      (click)="closeAsCancel()"></mf-button>
    <mf-button
      text="Aceptar"
      (click)="closeAsSuccess()"></mf-button>
  </mf-row>
</mf-page>
