import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Icons } from '../icon/icons';
import { IApiTemplateDesign } from './api/IApiTemplateDesign';
import { TemplateDesignDto } from './dtos/TemplateDesignDto';
import { TemplateViewModelFormGroup } from './view-models/TemplateViewModel';

@Component({
  selector: 'mf-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent implements OnInit {
  constructor(private _formGroup: FormGroupDirective, @Inject('IApiTemplateDesign') private _apiTemplateDesign: IApiTemplateDesign) {}

  form: FormGroup<TemplateViewModelFormGroup>;
  icons = Icons;
  loadingPreview = false;

  sectionMinHeight = 620;

  @Input()
  templateDesign: TemplateDesignDto;

  ngOnInit(): void {
    this.form = this._formGroup.control as FormGroup<TemplateViewModelFormGroup>;
  }
}
