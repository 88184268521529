import { Injectable } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { LabeledFormControl } from './labeled-form-control';

@Injectable({
  providedIn: 'root',
})
export class CustomFormBuilder extends FormBuilder {
  override group<T extends { [key: string]: any }>(controlsConfig: T, options?: AbstractControlOptions): FormGroup {
    const controls: { [P in keyof T]: any } = Object.keys(controlsConfig).reduce((acc, key) => {
      const keyTyped = key as keyof T;
      const value = controlsConfig[keyTyped];

      if ((value as any) instanceof FormGroup) {
        acc[keyTyped] = value;
        return acc;
      }

      if (Array.isArray(value)) {
        let formState, validator, asyncValidator, label;
        switch (value.length) {
          case 4:
            [formState, validator, asyncValidator, label = key] = value as [any, any?, any?, string?];
            break;
          case 3:
            [formState, validator, label = key] = value as [any, any?, string?];
            break;
          case 2:
            [formState, validator] = value as [any, string?];
            label = key;
            break;
          case 1:
            [formState] = value as [any];
            label = key;
            break;
          default:
            throw new Error('Invalid control config array');
        }
        acc[keyTyped] = new LabeledFormControl(label, formState, validator, asyncValidator);
      } else {
        throw new Error('Invalid control config');
      }
      return acc;
    }, {} as { [P in keyof T]: any });

    return super.group(controls, options);
  }
}
