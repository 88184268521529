// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';

export class AccreditationsByAccountFiltersDto extends FiltersDto {
  accreditationId: string | null = null;
  statuses: number[] = [];
  accountNumber: string = '';
  type: number | null = null;
}

export interface AccreditationsByAccountFiltersDtoFormGroup extends FiltersDtoFormGroup {
  accreditationId?: any;
  statuses?: any;
  accountNumber?: any;
  type?: any;
}
