import { Injectable, signal } from '@angular/core';

@Injectable()
export class NotificationService {
  private _timeOut: any;
  notification = signal<Notification | null>(null);

  constructor() {}

  private show(message: string, type: NotificationType, secondsToDisplay?: number) {
    if (secondsToDisplay == null || secondsToDisplay === undefined) {
      secondsToDisplay = 120;
    }

    if (this._timeOut) {
      clearTimeout(this._timeOut);
    }

    // Removing for some time the current notification so we can see the animation for the new one.
    this.notification.set(null);

    setTimeout(() => {
      this.notification.set(new Notification(message, type));
    }, 200);

    if (secondsToDisplay > 0) {
      this._timeOut = setTimeout(() => this.notification.set(null), secondsToDisplay * 1000);
    }
  }

  showError(message: string) {
    this.show(message, NotificationType.Error, 10);
  }

  showSuccess(message: string) {
    this.show(message, NotificationType.Success, 10);
  }

  showSuccessFast(message: string) {
    this.show(message, NotificationType.Success, 3);
  }

  showWarning(message: string) {
    this.show(message, NotificationType.Warning);
  }

  showInfo(message: string) {
    this.show(message, NotificationType.Info);
  }

  remove() {
    this.notification.set(null);
  }
}

export enum NotificationType {
  Success,
  Info,
  Warning,
  Error,
}

export class Notification {
  constructor(public message: string, public type: NotificationType) {}
}
