// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { OperationDto } from "../dtos/OperationDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { OperationFiltersDto } from "../dtos/OperationFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { OperationSimpleDto } from "../dtos/OperationSimpleDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminOperation {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  updateDraftOrders(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/client-admin/operation/add-orders/" + id, null, httpApiRequestOptions);
  }

  createDraft(
    operationDto: OperationDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<OperationDto> {
    return this._httpApiClient.post("/client-admin/operation", operationDto, httpApiRequestOptions);
  }

  closeOperationToNewOrders(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/client-admin/operation/close-operation-to-new-orders/" + id, null, httpApiRequestOptions);
  }

  completeDraftAndStartCashing(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<OperationDto> {
    return this._httpApiClient.put("/client-admin/operation/complete-draft-and-start-cashing/" + id, null, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<OperationDto> {
    return this._httpApiClient.get("/client-admin/operation/" + id, httpApiRequestOptions);
  }

  getByExternalId(
    externalId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<OperationDto> {
    return this._httpApiClient.put("/client-admin/operation/get-by-external-id", externalId, httpApiRequestOptions);
  }

  hasOpenCriticalIssue(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<boolean> {
    return this._httpApiClient.get("/client-admin/operation/has-open-critical-issue/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<OperationFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<OperationDto>> {
    return this._httpApiClient.post("/client-admin/operation/paged", filters, httpApiRequestOptions);
  }

  suggest(
    operationFilterDto: SuggestSearchDto<OperationFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<OperationSimpleDto>> {
    return this._httpApiClient.post("/client-admin/operation/suggest", operationFilterDto, httpApiRequestOptions);
  }

  cancel(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/client-admin/operation/cancel/" + id, null, httpApiRequestOptions);
  }

  delete(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/client-admin/operation/delete/" + id, null, httpApiRequestOptions);
  }

}
