import { Component } from '@angular/core';
import { TemplateDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplateEmailDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateEmailDto';
import { ApiTemplateEmail } from '../../../../api/endpoints/ApiTemplateEmail';
import { TemplateGroup } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/enums/TemplateGroup';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Component({
  selector: 'mf-template-email-create',
  templateUrl: './template-email-create.component.html',
  styleUrls: ['./template-email-create.component.scss'],
})
export class TemplateEmailCreateComponent {
  constructor(private _apiEmailTemplate: ApiTemplateEmail, private _modalService: ModalService) {
    this.templateEmailDto = new TemplateEmailDto();
    this.templateEmailDto.id = GUID_EMPTY;
    this.templateEmailDto.group = TemplateGroup.Email;
  }

  templateEmailDto: TemplateEmailDto;

  async save(templateDto: TemplateDto) {
    await this._apiEmailTemplate.create(templateDto as TemplateEmailDto);
    this._modalService.closeAsSuccess('La plantilla de correo se creó con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
