// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum Country {
	Uruguay = 1,
	Afghanistan = 100,
	AlandIslands = 101,
	Albania = 102,
	Algeria = 103,
	AmericanSamoa = 104,
	Andorra = 105,
	Angola = 106,
	Anguilla = 107,
	Antarctica = 108,
	AntiguaAndBarbuda = 109,
	Argentina = 110,
	Armenia = 111,
	Aruba = 112,
	Australia = 113,
	Austria = 114,
	Azerbaijan = 115,
	Bahrain = 116,
	Bahamas = 117,
	Bangladesh = 118,
	Barbados = 119,
	Belarus = 120,
	Belgium = 121,
	Belize = 122,
	Benin = 123,
	Bermuda = 124,
	Bhutan = 125,
	Bolivia = 126,
	BonaireSintEustatiusAndSaba = 127,
	BosniaAndHerzegovina = 128,
	Botswana = 129,
	BouvetIsland = 130,
	Brazil = 131,
	BritishIndianOceanTerritory = 132,
	BruneiDarussalam = 133,
	Bulgaria = 134,
	BurkinaFaso = 135,
	Burundi = 136,
	Cambodia = 137,
	Cameroon = 138,
	Canada = 139,
	CapeVerde = 140,
	CaymanIslands = 141,
	CentralAfricanRepublic = 142,
	Chad = 143,
	Chile = 144,
	China = 145,
	ChristmasIsland = 146,
	CocosKeelingIslands = 147,
	Colombia = 148,
	Comoros = 149,
	Congo = 150,
	CongoTheDemocraticRepublicOfThe = 151,
	CookIslands = 152,
	CostaRica = 153,
	CotedIvoire = 154,
	Croatia = 155,
	Cuba = 156,
	Curacao = 157,
	Cyprus = 158,
	CzechRepublic = 159,
	Denmark = 160,
	Djibouti = 161,
	Dominica = 162,
	DominicanRepublic = 163,
	Ecuador = 164,
	Egypt = 165,
	ElSalvador = 166,
	EquatorialGuinea = 167,
	Eritrea = 168,
	Estonia = 169,
	Ethiopia = 170,
	FalklandIslandsMalvinas = 171,
	FaroeIslands = 172,
	Fiji = 173,
	Finland = 174,
	France = 175,
	FrenchGuiana = 176,
	FrenchPolynesia = 177,
	FrenchSouthernTerritories = 178,
	Gabon = 179,
	Gambia = 180,
	Georgia = 181,
	Germany = 182,
	Ghana = 183,
	Gibraltar = 184,
	Greece = 185,
	Greenland = 186,
	Grenada = 187,
	Guadeloupe = 188,
	Guam = 189,
	Guatemala = 190,
	Guernsey = 191,
	Guinea = 192,
	GuineaBissau = 193,
	Guyana = 194,
	Haiti = 195,
	HeardIslandAndMcDonaldIslands = 196,
	HolySeeVaticanCityState = 197,
	Honduras = 198,
	HongKong = 199,
	Hungary = 200,
	Iceland = 201,
	India = 202,
	Indonesia = 203,
	Iran = 204,
	Iraq = 205,
	Ireland = 206,
	IsleOfMan = 207,
	Israel = 208,
	Italy = 209,
	Jamaica = 210,
	Japan = 211,
	Jersey = 212,
	Jordan = 213,
	Kazakhstan = 214,
	Kenya = 215,
	Kiribati = 216,
	KoreaNorth = 217,
	KoreaSouth = 218,
	Kuwait = 219,
	Kyrgyzstan = 220,
	LaoPeoplesDemocraticRepublic = 221,
	Latvia = 222,
	Lebanon = 223,
	Lesotho = 224,
	Liberia = 225,
	Libya = 226,
	Liechtenstein = 227,
	Lithuania = 228,
	Luxembourg = 229,
	Macao = 230,
	Macedonia = 231,
	Madagascar = 232,
	Malawi = 233,
	Malaysia = 234,
	Maldives = 235,
	Mali = 236,
	Malta = 237,
	MarshallIslands = 238,
	Martinique = 239,
	Mauritania = 240,
	Mauritius = 241,
	Mayotte = 242,
	Mexico = 243,
	Micronesia = 244,
	Moldova = 245,
	Monaco = 246,
	Mongolia = 247,
	Montenegro = 248,
	Montserrat = 249,
	Morocco = 250,
	Mozambique = 251,
	Myanmar = 252,
	Namibia = 253,
	Nauru = 254,
	Nepal = 255,
	Netherlands = 256,
	NewCaledonia = 257,
	NewZealand = 258,
	Nicaragua = 259,
	Niger = 260,
	Nigeria = 261,
	Niue = 262,
	NorfolkIsland = 263,
	NorthernMarianaIslands = 264,
	Norway = 265,
	Oman = 266,
	Pakistan = 267,
	Palau = 268,
	Palestine = 269,
	Panama = 270,
	PapuaNewGuinea = 271,
	Paraguay = 272,
	Peru = 273,
	Philippines = 274,
	Pitcairn = 275,
	Poland = 276,
	Portugal = 277,
	PuertoRico = 278,
	Qatar = 279,
	Romania = 281,
	Russia = 282,
	Rwanda = 283,
	SaintBarthlemy = 284,
	SaintHelenaAscensionAndTristanDaCunha = 285,
	SaintKittsAndNevis = 286,
	SaintLucia = 287,
	SaintMartinFrenchPart = 288,
	SaintPierreAndMiquelon = 289,
	SaintVincentAndTheGrenadines = 290,
	Samoa = 291,
	SanMarino = 292,
	SaoTomeAndPrincipe = 293,
	SaudiArabia = 294,
	Senegal = 295,
	Serbia = 296,
	Seychelles = 297,
	SierraLeone = 298,
	Singapore = 299,
	SintMaartenDutchPart = 300,
	Slovakia = 301,
	Slovenia = 302,
	SolomonIslands = 303,
	Somalia = 304,
	SouthAfrica = 305,
	SouthGeorgiaAndTheSouthSandwichIslands = 306,
	SouthSudan = 307,
	Spain = 308,
	SriLanka = 309,
	Sudan = 310,
	Suriname = 311,
	SvalbardAndJanMayen = 312,
	Swaziland = 313,
	Sweden = 314,
	Switzerland = 315,
	Syrian = 316,
	Taiwan = 317,
	Tajikistan = 318,
	Tanzania = 319,
	Thailand = 320,
	Timor = 321,
	Togo = 322,
	Tokelau = 323,
	Tonga = 324,
	TrinidadAndTobago = 325,
	Tunisia = 326,
	Turkey = 327,
	Turkmenistan = 328,
	TurksAndCaicosIslands = 329,
	Tuvalu = 330,
	Uganda = 331,
	Ukraine = 332,
	UnitedArabEmirates = 333,
	UnitedKingdom = 334,
	UnitedStates = 335,
	UnitedStatesMinorOutlyingIslands = 336,
	Uzbekistan = 338,
	Vanuatu = 339,
	Venezuela = 340,
	Vietnam = 341,
	VirginIslandsBritish = 342,
	VirginIslandsUs = 343,
	WallisAndFutuna = 344,
	WesternSahara = 345,
	Yemen = 346,
	Zambia = 347,
	Zimbabwe = 348,
	Other = 999,
}
