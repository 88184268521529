import { Component, Input } from '@angular/core';
import { GridMenuItem } from './grid-menu-item';
import { Icons } from '../../icon/icons';

@Component({
  selector: 'mf-grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss'],
})
export class GridMenuComponent {
  @Input()
  menuItems: GridMenuItem[];
  @Input()
  item: any;

  isOpen = false;
  icons = Icons;

  getTooltip(item: GridMenuItem): string {
    const disabled = this.getDisabled(item);
    if (disabled) {
      return disabled;
    }

    return item.tooltip ? item.tooltip(this.item) : '';
  }

  getDisabled(item: GridMenuItem): string | null {
    return item.disabled ? item.disabled(this.item) : null;
  }
}
