// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { OperationSimpleDto, OperationSimpleDtoFormGroup } from './OperationSimpleDto';
import { ClientDto } from './ClientDto';
import { OperationStatus } from '../enums/OperationStatus';
import { PaymentOrderDto } from './PaymentOrderDto';
import { CollectOrderDto } from './CollectOrderDto';

export class OperationDto extends OperationSimpleDto {
  externalId: string = '';
  clientId: string = '';
  client: ClientDto;
  reason: string = '';
  createdDate: Date | null = null;
  status: OperationStatus;
  paymentOrders: PaymentOrderDto[] = [];
  collectOrders: CollectOrderDto[] = [];
  isOpenForNewOrders: boolean = false;
  isFromApi: boolean = false;
}

export interface OperationDtoFormGroup extends OperationSimpleDtoFormGroup {
  externalId?: any;
  clientId?: any;
  client?: any;
  reason?: any;
  createdDate?: any;
  status?: any;
  paymentOrders?: any;
  collectOrders?: any;
  isOpenForNewOrders?: any;
  isFromApi?: any;
}
