import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AppRoute } from './app.routes.path';
import { AuthGuard } from './guards/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { LoginGoogleCallbackComponent } from './login/login-google-callback.component';
import { LoginLayoutComponent } from './login/login-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register/register.component';
import { AccreditationGridComponent } from './pages/accreditations/accreditation-grid.component';
import { AccreditationsByAccountGridComponent } from './pages/accreditations/accreditations-by-account-grid.component';
import { BankAccountGridComponent } from './pages/banks/bank-account/bank-account-grid.component';
import { BankBranchGridComponent } from './pages/banks/bank-branch/bank-branch-grid.component';
import { BankGridComponent } from './pages/banks/bank/bank-grid.component';
import { BcuReportGridComponent } from './pages/banks/bcu-report/bcu-report-grid.component';
import { GatewayBankAccountGridComponent } from './pages/banks/gateway-bank-account/gateway-bank-account-grid.component';
import { ClientGridComponent } from './pages/client/client-grid.component';
import { ConciliationGridComponent } from './pages/conciliations/conciliation-grid.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { LoggingConfigComponent } from './pages/development/logging/logging-config.component';
import { LoggingGridComponent } from './pages/development/logging/logging-grid.component';
import { HomeComponent } from './pages/home/home.component';
import { IssueGridComponent } from './pages/issue/issue-grid.component';
import { NotificationGridComponent } from './pages/notification/notification-grid.component';
import { OperationGridComponent } from './pages/operation/operation-grid.component';
import { CollectOrderGridPageComponent } from './pages/orders/collect-order/collect-order-grid-page.component';
import { PaymentOrderGridPageComponent } from './pages/orders/payment-order/payment-order-grid-page.component';
import { BankMovementGridPageComponent } from './pages/payment-methods/bank-movement/bank-movement-grid-page.component';
import { TransferGridPageComponent } from './pages/payment-methods/transfer/transfer-grid-page.component';
import { CompanyGridComponent } from './pages/person/company/company-grid.component';
import { NaturalPersonGridComponent } from './pages/person/natural-person/natural-person-grid.component';
import { TemplateGridComponent } from './pages/template/template-grid.component';
import { AppUserGridComponent } from './pages/user/app-user-grid.component';
import { ValueListGridComponent } from './pages/value-list/value-list-grid.component';

export const appRoutes: Route[] = [
  {
    path: AppRoute.login.signIn,
    component: LoginLayoutComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: AppRoute.login.register, component: RegisterComponent },
      { path: AppRoute.login.googleCallback, component: LoginGoogleCallbackComponent },
    ],
  },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeComponent },
      { path: AppRoute.accreditations, component: AccreditationGridComponent },
      { path: AppRoute.accreditationsByAccount, component: AccreditationsByAccountGridComponent },
      { path: AppRoute.banks, component: BankGridComponent },
      { path: AppRoute.bankBranches, component: BankBranchGridComponent },
      { path: AppRoute.bankAccounts, component: BankAccountGridComponent },
      { path: AppRoute.gatewayBankAccounts, component: GatewayBankAccountGridComponent },
      { path: AppRoute.clients, component: ClientGridComponent },
      { path: AppRoute.configuration, component: ConfigurationComponent },
      { path: AppRoute.development.logging.list, component: LoggingGridComponent },
      { path: AppRoute.development.logging.config, component: LoggingConfigComponent },
      { path: AppRoute.issue, component: IssueGridComponent },
      { path: AppRoute.notifications, component: NotificationGridComponent },
      { path: AppRoute.development.template.list, component: TemplateGridComponent },
      { path: AppRoute.orders.collect.list, component: CollectOrderGridPageComponent },
      { path: AppRoute.orders.payment.list, component: PaymentOrderGridPageComponent },
      { path: AppRoute.operations, component: OperationGridComponent },
      { path: AppRoute.persons.naturalPersons.list, component: NaturalPersonGridComponent },
      { path: AppRoute.persons.companies.list, component: CompanyGridComponent },
      { path: AppRoute.paymentTransactions.transfers.list, component: TransferGridPageComponent },
      { path: AppRoute.paymentTransactions.bankMovements.list, component: BankMovementGridPageComponent },
      { path: AppRoute.users, component: AppUserGridComponent },
      { path: AppRoute.valueLists, component: ValueListGridComponent },
      { path: AppRoute.conciliations, component: ConciliationGridComponent },
      { path: AppRoute.bcuReport, component: BcuReportGridComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
