import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent, IGridActionButton } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ClientDto } from '../../../api/dtos/ClientDto';
import { ClientFiltersDto } from '../../../api/dtos/ClientFiltersDto';
import { ApiAdminClient } from '../../../api/endpoints/ApiAdminClient';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { ClientAddUsersComponent } from './client-add-users.component';
import { ClientCreateComponent } from './client-create.component';
import { ClientEditComponent } from './client-edit.component';
import { enumName_mi_shared } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';

@Component({
  selector: 'mf-client-grid',
  templateUrl: './client-grid.component.html',
  styleUrls: ['./client-grid.component.scss'],
})
export class ClientGridComponent {
  constructor(
    public apiAdminClient: ApiAdminClient,
    private _modalService: ModalService,
    private _notificationService: NotificationService
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  initialFilters = new ClientFiltersDto();

  columns: IGridColumn<ApiAdminClient>[] = [
    {
      field: nameof<ClientDto>((cl) => cl.name),
      header: 'Nombre',
      width: 20,
      filter: { propertyName: nameof<ClientFiltersDto>((cl) => cl.defaultTextSearch), type: FilterType.Text },
    },
    {
      field: nameof<ClientDto>((cl) => cl.country),
      header: 'País',
      width: 10,
      cellOptions: { type: CellValueType.Enum, enumName: enumName_mi_shared.Country, enumNameModule: moduleName.shared },
      filter: {
        propertyName: nameof<ClientFiltersDto>((cl) => cl.countryList),
        type: FilterType.EnumList,
        enumName: enumName_mi_shared.Country,
        enumNameModule: moduleName.shared,
      },
    },
    {
      field: nameof<ClientDto>((cl) => cl.endpointUrl),
      header: 'Url',
      width: 10,
    },
    {
      field: nameof<ClientDto>((cl) => cl.apiToken),
      header: 'Token',
      width: 10,
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (clientDto: ClientDto) => {
        this._modalService.openRightModal(ClientEditComponent, {
          openData: clientDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Administradores',
      icon: Icons.edit,
      onClick: (clientDto: ClientDto) => {
        this._modalService.openRightModal(ClientAddUsersComponent, {
          openData: clientDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Test Web Hook',
      icon: Icons.edit,
      onClick: (clientDto: ClientDto) => {
        this.testWebHook(clientDto);
      },
    },
  ];

  gridActionButtons: IGridActionButton[] = [
    {
      text: 'Nuevo cliente',
      onClick: () => this.openNewClientPopup(),
    },
  ];

  apiEndpoint = (search: PagedSearchDto<ClientFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this.apiAdminClient.getPaged(search, httpApiRequestOptions);

  openNewClientPopup() {
    this._modalService.openRightModal(ClientCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }

  async testWebHook(clientDto: ClientDto) {
    await this.apiAdminClient.test(clientDto.id);
    this._notificationService.showSuccess('El cliente respondió con éxito');
  }
}
