// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';

export class BankMovementFiltersDto extends FiltersDto {
  currencies: number[] = [];
}

export interface BankMovementFiltersDtoFormGroup extends FiltersDtoFormGroup {
  currencies?: any;
}
