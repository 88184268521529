import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { ClientDto, ClientDtoFormGroup } from '../../../api/dtos/ClientDto';
import { ApiAdminAppUser } from '../../../api/endpoints/ApiAdminAppUser';
import { enumName_mi_shared } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';

@Component({
  selector: 'mf-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent {
  constructor(private _cfb: CustomFormBuilder, public _apiAdminUser: ApiAdminAppUser) {}

  @Input()
  clientDto: ClientDto;

  @Output()
  save = new EventEmitter<ClientDto>();

  clientForm = this._cfb.group<ClientDtoFormGroup>({
    id: [GUID_EMPTY],
    name: ['', Validators.required, 'Nombre'],
    country: ['', Validators.required, 'País'],
    company: ['', Validators.required, 'Persona jurídica'],
    companyId: ['', Validators.required],
    endpointUrl: ['', null, 'Web hook url base'],
    apiToken: [GUID_EMPTY, null, 'Token de API'],
  });

  enumName = enumName_mi_shared;
  enumNameModule = moduleName.shared;
}
