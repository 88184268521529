// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { OrderDto, OrderDtoFormGroup } from './OrderDto';
import { CollectOrderDto } from './CollectOrderDto';

export class PaymentOrderDto extends OrderDto {
  collectOrderToRefundId: string | null = null;
  collectOrderToRefund: CollectOrderDto;
}

export interface PaymentOrderDtoFormGroup extends OrderDtoFormGroup {
  collectOrderToRefundId?: any;
  collectOrderToRefund?: any;
}
