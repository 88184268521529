<mat-form-field [style.width.%]="100">
  <mat-label>{{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }}</mat-label>
  <mat-date-range-input [rangePicker]="datePicker">
    <input
      matStartDate
      placeholder="Start date"
      [errorStateMatcher]="errorStateMatcher"
      [(ngModel)]="value.min" />
    <input
      matEndDate
      placeholder="End date"
      [errorStateMatcher]="errorStateMatcher"
      [(ngModel)]="value.max" />
  </mat-date-range-input>
  <mat-datepicker-toggle
    matIconSuffix
    [for]="datePicker"></mat-datepicker-toggle>
  <mat-date-range-picker #datePicker></mat-date-range-picker>
  <mat-hint>{{ hint }}</mat-hint>
  @if (validationMessage) {
  <mat-error>
    {{ validationMessage }}
  </mat-error>
  }
</mat-form-field>
