import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { TransferDto } from '../../../../api/dtos/TransferDto';
import { TransferFiltersDto } from '../../../../api/dtos/TransferFiltersDto';
import { ApiAdminTransfer } from '../../../../api/endpoints/ApiAdminTransfer';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';

@Component({
  selector: 'mf-transfer-grid',
  templateUrl: './transfer-grid.component.html',
  styleUrls: ['./transfer-grid.component.scss'],
})
export class TransferGridComponent implements OnInit {
  constructor(public _apiAdminTransfer: ApiAdminTransfer, private _modalService: ModalService, private _route: ActivatedRoute) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() hideFilters = false;
  @Input() showCreateButton = false;
  @Input() transferId: string;
  @Input() accreditationsByAccountId: string;

  filters: TransferFiltersDto;

  ngOnInit(): void {
    this.filters = new TransferFiltersDto();
    if (this.accreditationsByAccountId) {
      this.filters.accreditationByAccountId = this.accreditationsByAccountId;
    }
  }

  columns: IGridColumn<TransferDto>[] = [
    {
      field: nameof<TransferDto>((t) => t.createdDate),
      header: 'Fecha',
      width: 10,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      field: nameof<TransferDto>((t) => t.status),
      header: 'Estado',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.PaymentTransactionStatus,
      },
    },
    {
      field: nameof<TransferDto>((t) => t.movementType),
      header: 'Movimiento',
      width: 5,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.MovementType,
      },
    },
    { field: nameof<TransferDto>((t) => t.sourceAccount.bank.name), header: 'Origen', width: 10 },
    { field: nameof<TransferDto>((t) => t.destinationAccount.bank.name), header: 'Destino', width: 10 },
    { field: nameof<TransferDto>((t) => t.conciliationRowId), header: 'DaMap Id', width: 10 },
    { field: nameof<TransferDto>((t) => t.conciliationId), header: 'DaMap Conc.', width: 10 },
    {
      field: nameof<TransferDto>((t) => t.destinationAccount.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<TransferDto>((t) => t.amount),
      header: 'Monto',
      cellOptions: {
        type: CellValueType.Numeric,
      },
      width: 10,
      align: 'right',
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver tracking',
      icon: Icons.search,
      onClick: (transferDto: TransferDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: transferDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Ver órdenes',
      icon: Icons.list,
      onClick: (transferDto: TransferDto) => {
        throw new Error('Not implemented');
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<TransferFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminTransfer.getPaged(search, httpApiRequestOptions);
}
