import { Component, ElementRef, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

@Component({
  selector: 'mf-field-text',
  templateUrl: 'field-text.component.html',
  styleUrls: ['field-text.component.scss'],
})
export class FieldTextComponent extends FieldMatBaseComponent<string> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef) {
    super(ngControl, elRef);
  }

  @Input()
  loading = false;
  @Input()
  multiline = false;
  @Input()
  multilineRows = 5;

  @Output()
  blur = new EventEmitter<any>();

  onBlur() {
    this.blur.emit();
    this.onTouched();
  }

  override get controlType(): string {
    return 'field-text';
  }
}
