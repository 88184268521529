// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { PaymentTransactionFiltersDto, PaymentTransactionFiltersDtoFormGroup } from './PaymentTransactionFiltersDto';

export class TransferFiltersDto extends PaymentTransactionFiltersDto {
  transferId: string | null = null;
  accreditationByAccountId: string | null = null;
  reference: string = '';
}

export interface TransferFiltersDtoFormGroup extends PaymentTransactionFiltersDtoFormGroup {
  transferId?: any;
  accreditationByAccountId?: any;
  reference?: any;
}
