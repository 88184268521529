<!-- <mf-form
  [entity]="templateDto"
  [formGroup]="templateForm"
  (save)="save.emit($event)">
  <mf-template-header-info></mf-template-header-info>
  <mf-column>
    <mf-field-select
      label="Usar en"
      formControlName="restrictTo"
      [enumName]="enumName.TemplateGroup"
      [enumNameModule]="moduleName.templates">
    </mf-field-select>
  </mf-column>
  <mf-template-editor></mf-template-editor>
</mf-form> -->
<mf-template></mf-template>
