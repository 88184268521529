import { Component } from '@angular/core';
import { LayoutColumnComponent } from './column.component';

@Component({
  selector: 'mf-row',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class LayoutRowComponent extends LayoutColumnComponent {
  constructor() {
    super();
    this.md = 12;
  }
}
