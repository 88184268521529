import { Component } from '@angular/core';
import { LayoutColumnComponent } from './column.component';

@Component({
  selector: 'mf-row-separator',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class LayoutRowSeparatorComponent extends LayoutColumnComponent {
  constructor() {
    super();
    this.md = 12;
    this.noPadding = true;
  }
}
