<mf-form
  [formGroup]="issueForm"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-select
      formControlName="type"
      [enumName]="enumName.IssueType"
      [readOnly]="true" />
  </mf-column>

  <mf-column>
    <mf-field-select
      formControlName="level"
      [enumName]="enumName.IssueLevel" />
  </mf-column>

  <mf-column>
    <mf-field-select
      formControlName="status"
      [enumName]="enumName.IssueStatus"
      [readOnly]="edit" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="description" />
  </mf-column>
</mf-form>
