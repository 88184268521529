import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginRequest } from '../dto/LoginRequest';
import { LoginResponse } from '../dto/LoginResponse';
import { MFAuthInit } from './mf-auth-init';

@Injectable()
export class MFAuthService {
  private initOptions: MFAuthInit;

  constructor(private _httpClient: HttpClient) {}

  private getUrl(url: string): string {
    if (!this.initOptions?.baseApiUrl) {
      throw Error('Base API URL is not set. Call init() first.');
    }
    return `${this.initOptions.baseApiUrl}${url}`;
  }

  init(initOptions: MFAuthInit) {
    this.initOptions = initOptions;
  }

  login(request: LoginRequest): Observable<LoginResponse> {
    return this._httpClient.post(this.getUrl(this.initOptions.loginRoute ?? '/auth/login'), request) as Observable<LoginResponse>;
  }

  loginGoogle(returnUrl?: string) {
    window.location.href = this.getUrl('') + '/auth/google-login?returnUrl=' + (returnUrl ?? '');
  }
}
