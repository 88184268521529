<mf-page title="Personas">
  <mf-grid
    #grid
    actionButtonText="Nueva persona"
    [apiEndpoint]="apiEndpoint"
    [columns]="columns"
    [menuItems]="menuItems"
    [initialFilters]="initialFilters"
    (actionButtonClick)="openNewNaturalPersonPopup()" />
</mf-page>
