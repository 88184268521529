import { FormControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';

export class LabeledFormControl extends FormControl {
  constructor(
    public label: string,
    formState?: any,
    validator?: ValidatorFn | ValidatorFn[],
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]
  ) {
    super(formState, validator, asyncValidator);
  }
}
