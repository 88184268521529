import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CollectOrderDto } from '../../../../api/dtos/CollectOrderDto';
import { CollectOrderFiltersDto } from '../../../../api/dtos/CollectOrderFiltersDto';
import { OrderDto } from '../../../../api/dtos/OrderDto';
import { ApiAdminCollectOrder } from '../../../../api/endpoints/ApiAdminCollectOrder';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { OrderStatus } from '../../../../api/enums/OrderStatus';
import { OrderType } from '../../../../api/enums/OrderType';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { CollectOrderVoucherCreateComponent } from '../collect-order-voucher/collect-order-voucher-create.component';
import { CollectOrderCreateComponent } from './collect-order-create.component';
import { CollectOrderEditComponent } from './collect-order-edit.component';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';

@Component({
  selector: 'mf-collect-order-grid',
  templateUrl: './collect-order-grid.component.html',
  styleUrls: ['./collect-order-grid.component.scss'],
})
export class CollectOrderGridComponent implements OnInit {
  constructor(public _apiAdminCollectOrder: ApiAdminCollectOrder, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() disableCreateButton = false;
  @Input() hideFilters = false;
  @Input() operationId: string;
  @Input() showCreateButton = false;

  filters: CollectOrderFiltersDto;

  ngOnInit(): void {
    this.filters = new CollectOrderFiltersDto();
    this.filters.operationId = this.operationId;
  }

  mustIncludeFields = [nameof<CollectOrderDto>((e) => e.personId), nameof<CollectOrderDto>((e) => e.clientId)];

  columns: IGridColumn<CollectOrderDto>[] = [
    {
      field: nameof<CollectOrderDto>((o) => o.operation.operationNumber),
      header: 'Operación',
      width: 5,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.operationNumber),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.orderNumber),
      header: 'Número',
      width: 5,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.orderNumber),
        type: FilterType.Number,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.externalId),
      header: 'Id externo',
      width: 5,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.externalId),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.person.fullName),
      header: 'Persona',
      width: 20,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.personFullName),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<OrderDto>((o) => o.status),
      header: 'Estado',
      width: 10,

      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.OrderStatus,
      },
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.OrderStatus,
      },
    },
    { field: nameof<CollectOrderDto>((o) => o.destinationBankAccount.bank.name), header: 'Banco', width: 10 },
    { field: nameof<CollectOrderDto>((o) => o.destinationBankAccount.accountNumber), header: 'Nro cuenta', width: 10, align: 'right' },
    {
      field: nameof<CollectOrderDto>((o) => o.operation.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.currency),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.amount),
      header: 'Monto',
      width: 10,
      cellOptions: {
        type: CellValueType.Numeric,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (collectOrderDto: CollectOrderDto) => {
        this._modalService.openNormalModal(CollectOrderEditComponent, {
          openData: {
            collectOrderId: collectOrderDto.id,
          },
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Ver tracking',
      icon: Icons.search,
      onClick: (collectOrderDto: CollectOrderDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: collectOrderDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Eliminar',
      icon: Icons.delete,
      hide: (collectOrderDto: CollectOrderDto) => collectOrderDto.status != OrderStatus.Draft,
      onClick: (collectOrderDto: CollectOrderDto) => {
        this._modalService.openYesOrNoModal('¿Está seguro de eliminar la orden de cobro?', {
          onCloseSuccess: async () => {
            await this._apiAdminCollectOrder.delete(collectOrderDto.id);
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Cargar comprobante',
      icon: Icons.upload,
      hide: (collectOrderDto: CollectOrderDto) =>
        collectOrderDto.status !== OrderStatus.Pending && collectOrderDto.type !== OrderType.Collect,
      onClick: (collectOrderDto: CollectOrderDto) => {
        this._modalService.openLargeModal(CollectOrderVoucherCreateComponent, {
          openData: {
            collectOrderId: collectOrderDto.id,
            collectOrderAmount: collectOrderDto.amount,
            personId: collectOrderDto.personId,
            currency: collectOrderDto.operation.currency,
            clientId: collectOrderDto.clientId,
          },
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<CollectOrderFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminCollectOrder.getPaged(search, httpApiRequestOptions);

  openNewCollectOrderPopup() {
    this._modalService.openLargeModal(CollectOrderCreateComponent, {
      openData: {
        operationId: this.operationId,
      },
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
