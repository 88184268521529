import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationService } from '../../../../../../../libs/utils/ng-shared-components/src';
import { SelectOption } from '../../../../../../../libs/utils/ng-shared-components/src/lib/fields/field-select/field-select.component';
import { LoggingConfigCategoryDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LoggingConfigCategoryDto';
import { LabeledFormControl } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/labeled-form-control';
import { ApiAdminLoggingConfig } from '../../../../api/endpoints/ApiAdminLoggingConfig';
import { LoggingLevel } from '../../../../api/enums/logging/LoggingLevel';

@Component({
  selector: 'mf-logging-config',
  templateUrl: './logging-config.component.html',
  styleUrls: ['./logging-config.component.scss'],
})
export class LoggingConfigComponent implements OnInit {
  constructor(private _apiAdminLoggingConfig: ApiAdminLoggingConfig, private _notificationService: NotificationService) {}

  sqlQueryIsEnabledForm = new FormGroup({
    sqlQueryIsEnabled: new LabeledFormControl('Loguear queries de SQL', false),
  });

  loggingCategoryConfigForm = new FormGroup({});
  loggingConfigCategories: LoggingConfigCategoryDto[];

  levelOptions = [
    new SelectOption(LoggingLevel.Trace, 'Trace'),
    new SelectOption(LoggingLevel.Debug, 'Debug'),
    new SelectOption(LoggingLevel.Information, 'Information'),
    new SelectOption(LoggingLevel.Warning, 'Warning'),
    new SelectOption(LoggingLevel.Error, 'Error'),
    new SelectOption(LoggingLevel.Critical, 'Critical'),
    new SelectOption(LoggingLevel.None, 'None'),
  ];

  async ngOnInit() {
    const sqlQueryIsEnabled = await this._apiAdminLoggingConfig.getSqlQueryIsEnabled();
    this.sqlQueryIsEnabledForm.patchValue({
      sqlQueryIsEnabled: sqlQueryIsEnabled,
    });

    this.loggingConfigCategories = await this._apiAdminLoggingConfig.getMiFinanzasAll();
    this.loggingConfigCategories.forEach((categoryConfig) => {
      this.loggingCategoryConfigForm.addControl(categoryConfig.category, new FormControl(categoryConfig.level));
    });
  }

  async sqlQueryIsEnabledClick() {
    const sqlQueryIsEnabled = this.sqlQueryIsEnabledForm.controls.sqlQueryIsEnabled.value ?? false;

    await this._apiAdminLoggingConfig.setSqlQueryIsEnabled(sqlQueryIsEnabled);
    this._notificationService.showSuccessFast(
      sqlQueryIsEnabled ? 'Log de queries de SQL se habilitó' : 'Log de queries de SQL se deshabilitó'
    );
  }

  async setCategoryLevel(category: string, level: LoggingLevel) {
    await this._apiAdminLoggingConfig.setCategoryLevel(category, level);
    this._notificationService.showSuccessFast('La configuración de log se modificó con éxito');
  }

  async testLogClick() {
    await this._apiAdminLoggingConfig.logTest();
    this._notificationService.showSuccessFast('Se enviaron los eventos a loguear con éxito');
  }

  async testException() {
    await this._apiAdminLoggingConfig.throwTestException();
  }
}
