import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { IGridFilterNumeric } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-filter';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { OperationDto } from '../../../api/dtos/OperationDto';
import { OperationFiltersDto } from '../../../api/dtos/OperationFiltersDto';
import { ApiAdminOperation } from '../../../api/endpoints/ApiAdminOperation';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { TrackingPopupComponent } from '../../components/track-grid/tracking-popup.component';
import { OperationCreateComponent } from '../operation/operation-create.component';
import { OperationEditComponent } from '../operation/operation-edit.component';
import { OperationDetailsComponent } from './operation-details.component';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { OperationStatus } from '../../../api/enums/OperationStatus';

@Component({
  selector: 'mf-operation-grid',
  templateUrl: './operation-grid.component.html',
  styleUrls: ['./operation-grid.component.scss'],
})
export class OperationGridComponent {
  constructor(public _apiAdminOperation: ApiAdminOperation, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  operation: OperationDto;

  columns: IGridColumn<OperationDto>[] = [
    {
      field: nameof<OperationDto>((o) => o.operationNumber),
      header: 'Número',
      width: 5,
      filter: {
        propertyName: nameof<OperationFiltersDto>((o) => o.operationNumber),
        type: FilterType.Number,
        intOnly: true,
      } as IGridFilterNumeric,
    },
    {
      field: nameof<OperationDto>((o) => o.externalId),
      header: 'Id externo',
      width: 5,
      filter: {
        propertyName: nameof<OperationDto>((o) => o.externalId),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<OperationDto>((o) => o.client.name),
      header: 'Cliente',
      width: 10,
      filter: {
        propertyName: nameof<OperationFiltersDto>((o) => o.clientName),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<OperationDto>((o) => o.reason),
      header: 'Razón',
      width: 20,
      filter: {
        propertyName: nameof<OperationFiltersDto>((o) => o.reason),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<OperationDto>((o) => o.createdDate),
      header: 'Creada',
      width: 10,
      cellOptions: {
        type: CellValueType.Date,
      },
      filter: {
        propertyName: nameof<OperationFiltersDto>((o) => o.createdDate),
        type: FilterType.Date,
      },
    },
    {
      field: nameof<OperationDto>((o) => o.status),
      header: 'Estado',
      width: 5,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.OperationStatus,
      },
      filter: {
        propertyName: nameof<OperationFiltersDto>((o) => o.statusList),
        enumName: enumName_mi_pspc.OperationStatus,
        type: FilterType.EnumList,
      },
    },
    {
      field: nameof<OperationDto>((o) => o.currency),
      header: 'Moneda',
      width: 5,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<OperationFiltersDto>((o) => o.currency),
        enumName: enumName_mi_pspc.Currency,
        type: FilterType.Enum,
      },
    },
    {
      field: nameof<OperationDto>((o) => o.total),
      header: 'Total',
      width: 10,
      cellOptions: {
        type: CellValueType.Numeric,
      },
      filter: {
        propertyName: nameof<OperationFiltersDto>((o) => o.total),
        type: FilterType.NumericRange,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (operationDto: OperationDto) => {
        this._modalService.openRightModal(OperationEditComponent, {
          openData: {
            operationId: operationDto.id,
          },
          onCloseSuccess: (operationDto: OperationDto) => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Ver detalles',
      icon: Icons.search,
      onClick: (operationDto: OperationDto) => {
        this._modalService.openRightModal(OperationDetailsComponent, {
          openData: operationDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Ver tracking',
      icon: Icons.search,
      onClick: (operationDto: OperationDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: operationDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Cancelar',
      icon: Icons.delete,
      hide: (operationDto: OperationDto) => operationDto.status >= OperationStatus.Paying || operationDto.status == OperationStatus.Draft,
      onClick: (operationDto: OperationDto) => {
        this._modalService.openYesOrNoModal('¿Está seguro de cancelar la operación?', {
          onCloseSuccess: async () => {
            await this._apiAdminOperation.cancel(operationDto.id);
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Borrar',
      icon: Icons.delete,
      hide: (operationDto: OperationDto) => operationDto.status != OperationStatus.Draft,
      onClick: (operationDto: OperationDto) => {
        this._modalService.openYesOrNoModal('¿Está seguro de eliminar la operación?', {
          onCloseSuccess: async () => {
            await this._apiAdminOperation.delete(operationDto.id);
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<OperationFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminOperation.getPaged(search, httpApiRequestOptions);

  openNewOperationPopup() {
    this._modalService.openRightModal(OperationCreateComponent, {
      onCloseSuccess: (operationDto: OperationDto) => {
        this.grid.reloadPage();
      },
    });
  }
}
