import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GatewayBankAccountDto } from '../../../../api/dtos/GatewayBankAccountDto';
import { ApiAdminGatewayBankAccount } from '../../../../api/endpoints/ApiAdminGatewayBankAccount';

@Component({
  selector: 'mf-gateway-bank-account-edit',
  templateUrl: './gateway-bank-account-edit.component.html',
  styleUrls: ['./gateway-bank-account-edit.component.scss'],
})
export class GatewayBankAccountEditComponent {
  constructor(private _apiAdminGatewayBankAccount: ApiAdminGatewayBankAccount, private _modalService: ModalService) {
    this.load();
  }

  gatewayBankAccountDto: GatewayBankAccountDto;

  async load() {
    this.gatewayBankAccountDto = await this._apiAdminGatewayBankAccount.getById(this._modalService.openData);
  }

  async save(gatewayBankAccountDto: GatewayBankAccountDto) {
    await this._apiAdminGatewayBankAccount.edit(gatewayBankAccountDto);
    this._modalService.closeAsSuccess('Cuenta editada correctamente');
  }
}
