// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { TemplateDesignTestDto, TemplateDesignTestDtoFormGroup } from './TemplateDesignTestDto';
import { TemplatePdfDto } from './TemplatePdfDto';

export class TemplateDesignTestPdfDto extends TemplateDesignTestDto {
  template: TemplatePdfDto;
}

export interface TemplateDesignTestPdfDtoFormGroup extends TemplateDesignTestDtoFormGroup {
  template?: any;
}
