/**
 * Returns a value (possibly nested) from an object using a string path
 * @param baseObject The object to get the value from
 * @param propertyName The path to the value
 * @returns A value from the object
 */
export function getValue(baseObject: any, propertyName: string) {
  const keys = propertyName.split('.');
  let nestedValue = baseObject;

  for (const key of keys) {
    if (nestedValue && typeof nestedValue === 'object' && key in nestedValue) {
      nestedValue = nestedValue[key];
    } else {
      return undefined;
    }
  }
  return nestedValue;
}
