// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';

export class PaymentTransactionFiltersDto extends FiltersDto {
  bankOperationTypes: number[] = [];
  currencies: number[] = [];
  destinationAccountId: string | null = null;
  status: number[] = [];
  types: number[] = [];
  sourceAccountId: string | null = null;
}

export interface PaymentTransactionFiltersDtoFormGroup extends FiltersDtoFormGroup {
  bankOperationTypes?: any;
  currencies?: any;
  destinationAccountId?: any;
  status?: any;
  types?: any;
  sourceAccountId?: any;
}
