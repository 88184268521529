import { Component } from '@angular/core';
import { BankDto } from '../../../../api/dtos/BankDto';
import { ApiAdminBank } from '../../../../api/endpoints/ApiAdminBank';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-bank-create',
  templateUrl: './bank-create.component.html',
  styleUrls: ['./bank-create.component.scss'],
})
export class BankCreateComponent {
  constructor(private _apiAdminBank: ApiAdminBank, private _modalService: ModalService) {}

  async save(bankDto: BankDto) {
    await this._apiAdminBank.create(bankDto);
    this._modalService.closeAsSuccess('El banco se creó con éxito');
  }
}
