<mf-form
  [formGroup]="collectOrderForm"
  [entity]="collectOrderDto"
  (save)="save.emit($event)">
  <mf-column-display label="Cliente">
    {{ collectOrderDto.operation.client.name }}
  </mf-column-display>

  <mf-column-display label="Motivo de la Operación">
    {{ collectOrderDto.operation.reason }}
  </mf-column-display>

  <mf-column-display label="Total">
    {{ collectOrderDto.operation.currency | customCurrency }} {{ collectOrderDto.operation.total }}
  </mf-column-display>

  <mf-column [md]="4">
    <mf-field-text formControlName="externalId" />
  </mf-column>

  <mf-column [md]="4">
    <mf-autocomplete-person
      formControlName="person"
      (itemIdChanged)="onPersonSelected($event)" />
  </mf-column>

  <mf-column [md]="4">
    <mf-field-numeric formControlName="amount" />
  </mf-column>
</mf-form>
