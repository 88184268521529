<mf-page title="Conciliaciones">
  <mf-row-buttons>
    <mf-button
      text="Cargar datos y Conciliar"
      (click)="conciliate()" />
  </mf-row-buttons>
  <mf-row>
    <mf-grid
      #grid
      [initialFilters]="filters"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems" />
  </mf-row>
</mf-page>
