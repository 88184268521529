<button
  mat-icon-button
  [matMenuTriggerFor]="menu">
  <mf-icon
    [icon]="icons.notifications"
    [numberBadge]="(notificationTray | async)?.unread?.length ?? 0"
    [rotate]="45"></mf-icon>
</button>

<mat-menu
  class="notification-menu"
  #menu="matMenu">
  <div
    class="mat-menu-content"
    (click)="$event.stopPropagation()">
    <div class="notification-title-container">
      <h2 class="menu-title">Notificaciones</h2>
      <button
        mat-icon-button
        matTooltip="Abrir en una nueva pestaña"
        (click)="openNotifications()"
        closeMenu>
        <mf-icon [icon]="icons.openInNew"></mf-icon>
      </button>
    </div>

    @if (notificationTray | async) { @if (!(notificationTray | async)?.read?.length && !(notificationTray | async)?.unread?.length) {
    <p>No hay notificaciones para mostrar</p>
    } } @for (notification of (notificationTray | async)?.unread; track notification.id) {
    <mf-notification-menu-item [notification]="notification"></mf-notification-menu-item>
    } @if ((notificationTray | async)?.read?.length && (notificationTray | async)?.unread?.length) {
    <hr />
    } @for (notification of (notificationTray | async)?.read; track notification.id) {
    <mf-notification-menu-item [notification]="notification"></mf-notification-menu-item>
    }
  </div>
</mat-menu>
