// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { FilterRangeNumericDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';

export class OrderFiltersDto extends FiltersDto {
  amount: FilterRangeNumericDto;
  currency: number | null = null;
  destinationBankAccountId: string | null = null;
  personFullName: string = '';
  personId: string | null = null;
  accByAccountId: string | null = null;
  notifyUser: boolean = false;
  operationId: string | null = null;
  operationNumber: number | null = null;
  orderNumber: number | null = null;
  sourceBankAccountId: string | null = null;
  statusList: number[] = [];
  transferId: string | null = null;
  type: number | null = null;
  externalId: string = '';
}

export interface OrderFiltersDtoFormGroup extends FiltersDtoFormGroup {
  amount?: any;
  currency?: any;
  destinationBankAccountId?: any;
  personFullName?: any;
  personId?: any;
  accByAccountId?: any;
  notifyUser?: any;
  operationId?: any;
  operationNumber?: any;
  orderNumber?: any;
  sourceBankAccountId?: any;
  statusList?: any;
  transferId?: any;
  type?: any;
  externalId?: any;
}
