<div class="container">
  <div class="label">{{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }}</div>
  <div class="content">
    <div class="action-area">
      @if (!value && progress === null) {
      <div class="no-file">
        <div class="no-file-icon">
          <mf-icon
            [icon]="icons.file"
            (click)="fileInput.click()"
            size="large"></mf-icon>
        </div>
        <div class="no-file-info">
          <div class="no-file-legend">Arrastra y suelta el archivo aquí para subirlo</div>
          <div class="no-file-hint">Se acepta cualquier tipo de archivo</div>
        </div>
      </div>
      } @if (progress !== null) {
      <div class="progress-container">
        <div class="progress-file-info">
          <div class="progress-file-name">archivo {{ fileExtension }}</div>
          <div class="progress-actions">
            @if (progress !== 100) {
            <mf-icon
              (click)="cancelUpload()"
              [icon]="icons.accreditations"
              size="small"></mf-icon>
            } @if (progress === 100) {
            <mf-icon
              [icon]="icons.check"
              size="small"
              color="primary"></mf-icon>
            }
          </div>
        </div>
        <div class="progress">
          <mat-progress-bar [value]="progress"></mat-progress-bar>
        </div>
      </div>
      } @if (value && progress == null) {
      <div class="file-link">
        <mf-link
          [href]="value"
          text="archivo {{ fileExtension }}"></mf-link>
      </div>
      }
    </div>
    <div class="button">
      <mf-button
        (click)="fileInput.click()"
        [disabled]="progress !== null && progress !== 100"
        text="{{ !value ? 'Subir archivo' : 'Reemplazar archivo' }}"></mf-button>
    </div>
  </div>
  @if (errorMessage) {
  <div class="error-message">
    {{ errorMessage }}
  </div>
  }
</div>

<input
  #fileInput
  type="file"
  style="display: none"
  (change)="onFileSelected($event)" />
<input
  matInput
  style="display: none"
  [(ngModel)]="value" />
