<mf-page title="Clientes">
  <mf-row>
    <mf-grid
      #grid
      [actionButtons]="gridActionButtons"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [initialFilters]="initialFilters"
      [menuItems]="menuItems" />
  </mf-row>
</mf-page>
