// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { TemplateDesignDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDesignDto";
import { TemplateDesignTestPreviewHtmlDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDesignTestPreviewHtmlDto";
import { TemplateDesignTestEmailDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDesignTestEmailDto";
import { TemplateDesignTestGenericDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDesignTestGenericDto";
import { TemplateDesignTestPdfDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDesignTestPdfDto";
import { TemplateDesignTestPreviewPdfFileDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDesignTestPreviewPdfFileDto";

@Injectable({providedIn: 'root'})
export class ApiTemplateDesign {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getDesign(
    templateTypeId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateDesignDto> {
    return this._httpApiClient.get("/shared/template/design?" + 
      (templateTypeId ? "&templateTypeId=" + templateTypeId : ""), httpApiRequestOptions);
  }

  getDesignForGeneric(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateDesignDto> {
    return this._httpApiClient.get("/shared/template/design/generic", httpApiRequestOptions);
  }

  previewHtmlEmail(
    templateDesignTestDto: TemplateDesignTestEmailDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateDesignTestPreviewHtmlDto> {
    return this._httpApiClient.post("/shared/template/design/preview-html-email", templateDesignTestDto, httpApiRequestOptions);
  }

  previewHtmlGeneric(
    templateDesignTestDto: TemplateDesignTestGenericDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateDesignTestPreviewHtmlDto> {
    return this._httpApiClient.post("/shared/template/design/preview-html-generic", templateDesignTestDto, httpApiRequestOptions);
  }

  previewHtmlPdf(
    templateDesignTestDto: TemplateDesignTestPdfDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateDesignTestPreviewHtmlDto> {
    return this._httpApiClient.post("/shared/template/design/preview-html-pdf", templateDesignTestDto, httpApiRequestOptions);
  }

  previewPdfFile(
    templatePdfDesignTestDto: TemplateDesignTestPdfDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateDesignTestPreviewPdfFileDto> {
    return this._httpApiClient.post("/shared/template/design/preview-pdf-file", templatePdfDesignTestDto, httpApiRequestOptions);
  }

  previewPdfFileAck(
    fileName: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/shared/template/design/preview-pdf-file-ack?" + 
      (fileName ? "&fileName=" + fileName : ""), null, httpApiRequestOptions);
  }

}
