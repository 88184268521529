// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class AccreditationsByAccountCancelDto  {
  accreditationsByAccountId: string = '';
  notes: string = '';
}

export interface AccreditationsByAccountCancelDtoFormGroup  {
  accreditationsByAccountId?: any;
  notes?: any;
}
