import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { AppUserCreateDto } from '../../../api/dtos/AppUserCreateDto';
import { ApiAdminAppUser } from '../../../api/endpoints/ApiAdminAppUser';

@Component({
  selector: 'mf-app-user-edit',
  templateUrl: './app-user-edit.component.html',
  styleUrls: ['./app-user-edit.component.scss'],
})
export class AppUserEditComponent {
  constructor(private _apiAdminAppUser: ApiAdminAppUser, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  appUserCreateDto: AppUserCreateDto;

  async load(id: string) {
    this.appUserCreateDto = (await this._apiAdminAppUser.getById(id)) as AppUserCreateDto;
  }

  async save(appUserCreateDto: AppUserCreateDto) {
    await this._apiAdminAppUser.edit(appUserCreateDto);
    this._modalService.closeAsSuccess('Usuario editado correctamente');
  }
}
