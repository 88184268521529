import { ChangeDetectionStrategy, Component, ElementRef, Inject, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { IHttpApiRequestOptions } from '../../../../../../../libs/utils/ng-shared-components/src/lib/IHttpApiRequestOptions';
import { CompanyDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto';
import { CompanyFiltersDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/CompanyFiltersDto';
import { IAutocompleteService } from './IAutocomplete';

@Component({
  selector: 'mf-autocomplete-company',
  templateUrl: './autocomplete-company.component.html',
  styleUrl: './autocomplete-company.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteCompanyComponent extends AutoCompleteBaseEntityComponent<CompanyDto | null, CompanyFiltersDto> {
  constructor(
    @Optional() @Self() ngControl: NgControl, elRef: ElementRef,
    @Inject('IAutocompleteService') public autocompleteService: IAutocompleteService
) {
    super(ngControl, elRef);
  }
  override defaultOrderBy = nameof<CompanyDto>((c) => c.name);
  override endpointSuggest = (search: SuggestSearchDto<CompanyFiltersDto>, httpApiRequestOptions: IHttpApiRequestOptions) =>
    this.autocompleteService.suggestCompany(search, httpApiRequestOptions);
}
