<mf-page title="Notificaciones">
  <mf-row-buttons align="left">
    <mf-button
      text="Nueva notificación"
      (click)="openNewNotificationPopup()" />
  </mf-row-buttons>
  <mf-row>
    <mf-grid
      #grid
      [apiEndpoint]="apiEndpoint"
      [columns]="columns" />
  </mf-row>
</mf-page>
