import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mf-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text: string;
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() color: 'primary' | 'secondary' = 'primary';

  constructor(private _ref: ElementRef) {
    _ref.nativeElement.addEventListener('click', this.stopPropagation);
  }

  private stopPropagation = (event: Event) => {
    if (this.disabled || this.isLoading) {
      event.stopImmediatePropagation();
    }
  };

  ngDestroy() {
    this._ref.nativeElement.removeEventListener('click', this.stopPropagation);
  }
}
