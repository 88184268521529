{
	"_locale": "EsUy",
	"LoggingLevel": {
		"2": "Debug",
		"5": "Error",
		"1": "Trace",
		"7": "Ninguno",
		"3": "Information",
		"6": "Critical",
		"4": "Warning"
	}
}
