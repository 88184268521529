import { Component, Input } from '@angular/core';

@Component({
  selector: 'mf-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class LayoutSectionComponent {
  @Input()
  title: string;

  @Input()
  minHeight?: number;

  get style() {
    return this.minHeight ? 'min-height: ' + this.minHeight + 'px;' : '';
  }
}
