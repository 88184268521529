// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { NaturalPersonDto } from "../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { NaturalPersonFiltersDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/NaturalPersonFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminNaturalPerson {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    naturalPersonDto: NaturalPersonDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<NaturalPersonDto> {
    return this._httpApiClient.post("/admin/natural-person", naturalPersonDto, httpApiRequestOptions);
  }

  edit(
    naturalPersonDto: NaturalPersonDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<NaturalPersonDto> {
    return this._httpApiClient.put("/admin/natural-person", naturalPersonDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<NaturalPersonDto> {
    return this._httpApiClient.get("/admin/natural-person/" + id, httpApiRequestOptions);
  }

  getByDocument(
    country: number,
    
    idDocumentType: number,
    
    documentNumber: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<NaturalPersonDto> {
    return this._httpApiClient.get("/admin/natural-person/by-document?" + 
      (country ? "&country=" + country : "") + 
      (idDocumentType ? "&idDocumentType=" + idDocumentType : "") + 
      (documentNumber ? "&documentNumber=" + documentNumber : ""), httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<NaturalPersonFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<NaturalPersonDto>> {
    return this._httpApiClient.post("/admin/natural-person/paged", filters, httpApiRequestOptions);
  }

}
