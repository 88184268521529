import { Component } from '@angular/core';
import { LayoutService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/layout.service';

@Component({
  selector: 'mf-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
})
export class LoginLayoutComponent {
  constructor(public layoutService: LayoutService) {}
}
