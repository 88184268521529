import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from '../icon/icons';

@Component({
  selector: 'mf-button-google',
  templateUrl: './button-google.component.html',
  styleUrls: ['./button-google.component.scss'],
})
export class ButtonGoogleComponent {
  @Input() text: string;
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
}
