import { Component, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

@Component({
  selector: 'mf-field-password',
  templateUrl: 'field-password.component.html',
  styleUrls: ['field-password.component.scss'],
})
export class FieldPasswordComponent extends FieldMatBaseComponent<string> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef) {
    super(ngControl, elRef);
  }

  override get controlType(): string {
    return 'field-password';
  }
}
