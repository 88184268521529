<mf-container>
  <mf-row-separator [minHeight]="120"></mf-row-separator>
  <mf-column [md]="3"></mf-column>
  <mf-column [md]="6">
    <mf-section>
      <mf-row>
        <mf-button-google
          text="Continuar con Google"
          (click)="loginGoogle()" />
      </mf-row>
      <mf-row-separator [minHeight]="30"></mf-row-separator>
      <form
        [formGroup]="authForm"
        (keyup.enter)="login()">
        <mf-row>
          <mf-field-text formControlName="username" />
        </mf-row>
        <mf-row>
          <mf-field-password formControlName="password" />
        </mf-row>
        <mf-column [md]="6">
          <mf-link
            text="Registrarse"
            [href]="appRoute.login.register" />
        </mf-column>
        <mf-column
          [md]="6"
          align="right">
          <mf-button
            text="Ingresar"
            [isLoading]="isLoading"
            (click)="login()" />
        </mf-column>
      </form>
    </mf-section>
  </mf-column>
</mf-container>
