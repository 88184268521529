// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';

export class AddressDto extends BaseEntityDto {
  addressLine: string = '';
  city: string = '';
  country: number | null = null;
}

export interface AddressDtoFormGroup extends BaseEntityDtoFormGroup {
  addressLine?: any;
  city?: any;
  country?: any;
}
