<mf-page title="Empresas">
  <mf-grid
    #grid
    actionButtonText="Nueva empresa"
    [apiEndpoint]="apiEndpoint"
    [columns]="columns"
    [menuItems]="menuItems"
    [initialFilters]="initialFilters"
    (actionButtonClick)="openNewCompanyPopup()" />
</mf-page>
