/* eslint-disable indent */
import { Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

/**
 * Implements control value accessor interface and allows a componente to be
 * bind to a model using [ngModel]
 */
export abstract class ModelValueBaseComponent<T> implements ControlValueAccessor {
  /**
   * Fix: Angular invoking twice to writeValue.
   */
  private flagInitialized = false;
  private _value!: T;

  public get value(): T {
    return this._value;
  }

  public set value(val: T) {
    this._value = val;
    if (this.propagateChange != null) {
      this.propagateChange(this.value);
    }
    this.onValueAssigned(val);
  }

  /**
   * Allows the component to assign a value without trigger the propagate changes.
   */
  protected assignValue(val: T): void {
    this._value = val;
  }

  /**
   * To override at implementation. Invoked after assign to the 'value' property and call propagateChange.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onValueAssigned(val: T): void {
    // Allow override
  }

  /**
   * Listener por defecto.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  protected propagateChange = (_: any) => {
    // Allow override
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched() {
    // Allow override
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public writeValue(val: any): void {
    if (val == null && !this.flagInitialized) {
      this.flagInitialized = true;
      return;
    }
    this._value = val;
    this.onWriteValue(val);
  }

  /**
   * To override. Invoked after writeValue is invoked.
   */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onWriteValue(val: T): void {
    // Allow override
  }
}
