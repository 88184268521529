<mat-form-field [style.width.%]="100">
  <mat-label> {{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }} </mat-label>
  <input
    matInput
    [disabled]="readOnly"
    [matDatepicker]="datePicker"
    [errorStateMatcher]="errorStateMatcher"
    [(ngModel)]="value" />
  <mat-datepicker-toggle
    matIconSuffix
    [for]="datePicker">
  </mat-datepicker-toggle>
  <mat-datepicker #datePicker> </mat-datepicker>
  <mat-hint>{{ hint }}</mat-hint>
  @if (validationMessage) {
  <mat-error>
    {{ validationMessage }}
  </mat-error>
  }
</mat-form-field>
