import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { IssueDto, IssueDtoFormGroup } from '../../../api/dtos/IssueDto';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { IssueType } from '../../../api/enums/IssueType';
import { ApiAdminIssue } from '../../../api/endpoints/ApiAdminIssue';

@Component({
  selector: 'mf-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.scss'],
})
export class IssueComponent implements OnInit {
  constructor(private _cfb: CustomFormBuilder, private _apiAdminIssue: ApiAdminIssue) {}

  @Input()
  issueDto: IssueDto;
  @Input()
  edit = false;
  @Output()
  save = new EventEmitter<IssueDto>();

  ngOnInit(): void {
    if (this.issueDto != null) {
      this.issueForm.patchValue(this.issueDto);
    }
  }

  enumName = enumName_mi_pspc;

  issueForm = this._cfb.group<IssueDtoFormGroup>({
    id: [GUID_EMPTY],
    issueNumber: [null, null],
    type: [IssueType.Others, Validators.required, 'Tipo de incidente'],
    level: [null, Validators.required, 'Nivel del incidente'],
    status: [null, Validators.required, 'Estado del incidente'],
    description: [null, Validators.required, 'Notas'],
  });
}
