// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { ConciliationDto } from "../dtos/ConciliationDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { ConciliationFiltersDto } from "../dtos/ConciliationFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminConciliation {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getPaged(
    filters: PagedSearchDto<ConciliationFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<ConciliationDto>> {
    return this._httpApiClient.post("/admin/conciliation/paged", filters, httpApiRequestOptions);
  }

  sendTransfersAndConciliate(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.get("/admin/conciliation/send-transfers-and-conciliate", httpApiRequestOptions);
  }

}
