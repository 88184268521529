import { Component } from '@angular/core';
import { TemplateDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplateGenericDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateGenericDto';
import { ApiTemplateGeneric } from '../../../../api/endpoints/ApiTemplateGeneric';
import { TemplateGroup } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/enums/TemplateGroup';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Component({
  selector: 'mf-template-generic-create',
  templateUrl: './template-generic-create.component.html',
  styleUrls: ['./template-generic-create.component.scss'],
})
export class TemplateGenericCreateComponent {
  constructor(private _apiGenericTemplate: ApiTemplateGeneric, private _modalService: ModalService) {
    this.templateGenericDto = new TemplateGenericDto();
    this.templateGenericDto.id = GUID_EMPTY;
    this.templateGenericDto.group = TemplateGroup.Generic;
  }

  templateGenericDto: TemplateGenericDto;

  async save(templateDto: TemplateDto) {
    await this._apiGenericTemplate.create(templateDto as TemplateGenericDto);
    this._modalService.closeAsSuccess('La plantilla genérica se ha creado con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
