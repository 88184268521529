import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterRangeDateDto } from '../../../../../../ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';
import { moduleName } from '../../../../../../ts-shared/src/lib/module-names';
import { DateTimeRange } from '../../../fields/field-date-range/field-date-range.component';
import { IGridFilter } from '../../interface/grid-filter';
import { GridFilterBase } from '../grid-filter-base';
import { enumName_searching } from '../../../../../../ts-shared/src/lib/api/searching/enums/_enumName.searching';
import { FilterDateRangeType } from '../../../../../../ts-shared/src/lib/api/searching/enums/FilterDateRangeType';

@Component({
  selector: 'mf-grid-filter-date',
  templateUrl: './grid-filter-date.component.html',
  styleUrls: ['./grid-filter-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridFilterDateComponent),
      multi: true,
    },
  ],
})
export class GridFilterDateComponent extends GridFilterBase<IGridFilter, FilterRangeDateDto | null> {
  enumName = enumName_searching;
  moduleName = moduleName;
  filterDateRangeType = FilterDateRangeType;
  specificDate: Date;
  type?: FilterDateRangeType;
  range = new DateTimeRange(null, null);

  modelChange() {
    if (this.type) {
      const value = new FilterRangeDateDto();
      value.type = this.type;
      this.value = value;
    } else {
      this.value = null;
    }
  }

  protected override onWriteValue(val: FilterRangeDateDto | null): void {
    this.type = val?.type ?? undefined;
  }

  specificDateChange() {
    if (this.value) {
      this.value.specificDate = this.specificDate;
    }
  }

  rangeChange() {
    if (this.value) {
      this.value.min = this.range.min;
      this.value.max = this.range.max;
    }
  }
}
