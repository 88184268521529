// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';

export class IssueFiltersDto extends FiltersDto {
  entityId: string | null = null;
  levels: number[] = [];
  description: string = '';
}

export interface IssueFiltersDtoFormGroup extends FiltersDtoFormGroup {
  entityId?: any;
  levels?: any;
  description?: any;
}
