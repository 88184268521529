@if (clientDto) {
<mf-page title="Administrar usuarios de {{ clientDto }}">
  <mf-row>
    <mf-form
      [formGroup]="addUsersForm"
      (save)="save($event)">
      <mf-autocomplete-user
        formControlName="appUser"
        (itemIdChanged)="addUsersForm.patchValue({ appUserId: $event })" />
    </mf-form>
  </mf-row>
  <mf-grid
    #grid
    [apiEndpoint]="apiEndpoint"
    [columns]="columns"
    [menuItems]="menuItems"
    [initialFilters]="filter" />
</mf-page>
}
