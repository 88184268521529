import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { INotificationTrayService } from '../../../../../libs/utils/ng-shared-components/src/lib/notification-tray/INotificationTray';
import { NotificationTrayDto } from '../../api/dtos/NotificationTrayDto';
import { ApiAdminNotification } from '../../api/endpoints/ApiAdminNotification';
import { AppRoute } from '../app.routes.path';
import { NavigationService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { IssueType } from '../../api/enums/IssueType';
import { ApiAdminPerson } from '../../api/endpoints/ApiAdminPerson';
import { PersonType } from '../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';

@Injectable()
export class NotificationTrayService implements INotificationTrayService {
  constructor(
    private _apiAdminNotification: ApiAdminNotification,
    private _apiAdminPerson: ApiAdminPerson,
    private _navigationService: NavigationService
  ) {
    this.loadNotifications();
    setInterval(() => {
      this.loadNotifications();
    }, 300000);
  }

  notificationTray = new ReplaySubject<NotificationTrayDto>();

  async loadNotifications() {
    this.notificationTray.next(await this._apiAdminNotification.getCurrent({ preventSpinner: true }));
  }

  navigateToNotificationPage(): void {
    this._navigationService.navigateNewTab(AppRoute.notifications);
  }

  async setAsRead(notificationId: string) {
    await this._apiAdminNotification.setAsRead(notificationId, { preventSpinner: true });
    this.loadNotifications();
  }

  async setAsUnread(notificationId: string) {
    await this._apiAdminNotification.setAsUnread(notificationId, { preventSpinner: true });
    this.loadNotifications();
  }

  async inactivate(notificationId: string) {
    await this._apiAdminNotification.inactivate(notificationId, { preventSpinner: true });
    this.loadNotifications();
  }

  async downloadFile(notificationId: string) {
    let fileUrl = await this._apiAdminNotification.downloadNotificationFile(notificationId);
    //this._navigationService.navigateNewTab(fileUrl);
    this.loadNotifications();
    throw new Error('Not implemented yet fileUrl = ' + fileUrl);
  }

  async navigateToRelatedEntityPage(notificationId: string) {
    let relatedIssue = await this._apiAdminNotification.getRelatedIssue(notificationId);

    if (relatedIssue?.entityId == null) {
      return;
    }

    switch (relatedIssue.type) {
      case IssueType.InvalidBankAccount:
        this._navigationService.navigateNewTab('bank-accounts', ['id=' + relatedIssue.entityId]);
        break;
      case IssueType.IncosistentPerson:
        const person = await this._apiAdminPerson.getById(relatedIssue.entityId);
        const personType = person.type === PersonType.NaturalPerson ? 'natural-persons' : 'company';
        this._navigationService.navigateNewTab('persons/' + personType, ['id=' + relatedIssue.entityId]);
        break;
      default:
        break;
    }

    this.setAsRead(notificationId);
  }
}
