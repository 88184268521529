import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiAdminCollectOrder } from '../../../../api/endpoints/ApiAdminCollectOrder';
import { TransferVoucherForCollectOrderDto } from '../../../../api/dtos/TransferVoucherForCollectOrderDto';
import { ApiAdminTransfer } from '../../../../api/endpoints/ApiAdminTransfer';

@Component({
  selector: 'mf-collect-order-voucher-create',
  templateUrl: './collect-order-voucher-create.component.html',
  styleUrls: ['./collect-order-voucher-create.component.scss'],
})
export class CollectOrderVoucherCreateComponent {
  constructor(private _apiAdminTransfer: ApiAdminTransfer, private _modalService: ModalService) {
    this.transferVoucherForCollectOrderDto = new TransferVoucherForCollectOrderDto();
    this.transferVoucherForCollectOrderDto.amount = this._modalService.openData.collectOrderAmount;
    this.transferVoucherForCollectOrderDto.currency = this._modalService.openData.currency;
    this.transferVoucherForCollectOrderDto.clientId = this._modalService.openData.clientId;
    this.personId = this._modalService.openData.personId;
  }

  transferVoucherForCollectOrderDto: TransferVoucherForCollectOrderDto;
  personId: string | null;

  async submit(transferVoucherForCollectOrderDto: TransferVoucherForCollectOrderDto) {
    await this._apiAdminTransfer.uploadVoucherForCollectOrder(transferVoucherForCollectOrderDto);
    this._modalService.closeAsSuccess('El comprobante se cargó con éxito');
  }
}
