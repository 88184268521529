import { EventEmitter, Injectable, Output } from '@angular/core';
import { OperationDto } from '../../../api/dtos/OperationDto';
import { ApiAdminOperation } from '../../../api/endpoints/ApiAdminOperation';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Injectable()
export class OperationService {
  constructor(private apiAdminOperation: ApiAdminOperation, private _notificationService: NotificationService) {}

  @Output()
  updatedOperationDto = new EventEmitter<OperationDto>();

  operationDto: OperationDto;

  public async createDraft(operationDto: OperationDto) {
    operationDto.id = GUID_EMPTY;
    this.operationDto = await this.apiAdminOperation.createDraft(operationDto);
    this.updatedOperationDto.emit(this.operationDto);
    this._notificationService.showSuccess('Se ha creado el borrador de la operación');
  }

  public async reloadOperation(operationId: string) {
    this.operationDto = await this.apiAdminOperation.getById(operationId);
    this.updatedOperationDto.emit(this.operationDto);
  }

  public async updateAllowPayments(operationId: string) {
    await this.apiAdminOperation.closeOperationToNewOrders(operationId);
    this.updatedOperationDto.emit(this.operationDto);
    this._notificationService.showSuccess('Operación modificada con éxito');
  }
}
