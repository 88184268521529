import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';

export class FieldErrorStateMatcher implements ErrorStateMatcher {
  constructor(private _ngControl: NgControl) {}

  isErrorState(_control: FormControl | null, _form: FormGroupDirective | NgForm | null) {
    return !!this._ngControl.errors && !!this._ngControl.touched;
  }
}
