import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { TemplateDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplateGenericDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateGenericDto';
import { ApiTemplateGeneric } from '../../../../api/endpoints/ApiTemplateGeneric';

@Component({
  selector: 'mf-template-generic-edit',
  templateUrl: './template-generic-edit.component.html',
  styleUrls: ['./template-generic-edit.component.scss'],
})
export class TemplateGenericEditComponent {
  constructor(private _apiGenericTemplate: ApiTemplateGeneric, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  templateGenericDto: TemplateGenericDto;

  async load(id: string) {
    this.templateGenericDto = await this._apiGenericTemplate.getById(id);
  }

  async save(templateDto: TemplateDto) {
    await this._apiGenericTemplate.edit(templateDto as TemplateGenericDto);
    this._modalService.closeAsSuccess('La plantilla genérica se ha editado con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
