// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class FilterRangeNumericDto  {
  min: number | null = null;
  max: number | null = null;
}

export interface FilterRangeNumericDtoFormGroup  {
  min?: any;
  max?: any;
}
