// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { TemplateEmailDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateEmailDto";

@Injectable({providedIn: 'root'})
export class ApiTemplateEmail {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    templateEmailDto: TemplateEmailDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateEmailDto> {
    return this._httpApiClient.post("/shared/template/email", templateEmailDto, httpApiRequestOptions);
  }

  edit(
    templateEmailDto: TemplateEmailDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateEmailDto> {
    return this._httpApiClient.put("/shared/template/email", templateEmailDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplateEmailDto> {
    return this._httpApiClient.get("/shared/template/email/" + id, httpApiRequestOptions);
  }

}
