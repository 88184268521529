<mf-form
  [entity]="templateDto"
  [formGroup]="templateForm"
  [closeButtonShow]="true"
  (save)="save.emit($event)"
  (closeButtonClick)="closeButtonClick.emit()">
  <mf-template-header-info
    (templateTypeChanged)="loadTemplateDesign($event)"
    (genericTypeChanged)="loadTemplateDesignForGeneric()" />
  <mf-row-separator />
  @if (templateDesign) {
  <mf-template-editor [templateDesign]="templateDesign" />
  }
</mf-form>
