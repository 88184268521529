<mf-page title="Detalles de evento">
  @if (logEventDto) {
  <mf-column label="Fecha">
    {{ logEventDto.date | dateUtc : true }}
  </mf-column>
  <mf-column label="Tipo">
    {{ logEventDto.level | translate : enumName.LoggingLevel : moduleName.logging }}
  </mf-column>
  <mf-column label="Mensaje">
    {{ logEventDto.message }}
  </mf-column>
  @if (logEventDto.exception) {
  <mf-row label="Error">
    {{ logEventDto.exception }}
  </mf-row>
  } }
</mf-page>
