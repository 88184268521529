<mf-page title="Bancos">
  <mf-row>
    <mf-grid
      #grid
      actionButtonText="Nuevo banco"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems"
      (actionButtonClick)="openNewBankPopup()" />
  </mf-row>
</mf-page>
