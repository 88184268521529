<mat-form-field [style.width.%]="100">
  <mat-label> {{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }} </mat-label>
  <mat-select
    multiple
    [(ngModel)]="value"
    [errorStateMatcher]="errorStateMatcher">
    <div class="select-all">
      <mf-field-check
        [ngModel]="allSelected"
        label="Seleccionar todos"
        (ngModelChange)="toggleAllSelection()">
      </mf-field-check>
    </div>
    @for (option of options; track $index) {
    <mat-option [value]="option.value">
      {{ option.description }}
    </mat-option>
    }
  </mat-select>
  <mat-error>{{ validationMessage }}</mat-error>
</mat-form-field>
