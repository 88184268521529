<mf-page title="Sucursales">
  <mf-row>
    <mf-grid
      #grid
      actionButtonText="Nueva sucursal"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems"
      (actionButtonClick)="openNewBankBranchPopup()" />
  </mf-row>
</mf-page>
