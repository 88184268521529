// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';

export class ValueListFiltersDto extends FiltersDto {
  type: number | null = null;
  text: string = '';
  country: number | null = null;
}

export interface ValueListFiltersDtoFormGroup extends FiltersDtoFormGroup {
  type?: any;
  text?: any;
  country?: any;
}
