// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { IdDocumentDto } from './IdDocumentDto';
import { PersonType } from '../enums/PersonType';
import { AddressDto } from './AddressDto';

export class PersonDto extends BaseEntityDto {
  idDocumentId: string = '';
  idDocument: IdDocumentDto;
  fullName: string = '';
  email: string = '';
  type: PersonType;
  addressId: string | null = null;
  address: AddressDto;
}

export interface PersonDtoFormGroup extends BaseEntityDtoFormGroup {
  idDocumentId?: any;
  idDocument?: any;
  fullName?: any;
  email?: any;
  type?: any;
  addressId?: any;
  address?: any;
}
