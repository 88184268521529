@if (mustShow) {
<mat-error>
  <mf-icon
    [icon]="isExpanded ? icons.expandLess : icons.expandMore"
    (click)="isExpanded = !isExpanded">
  </mf-icon>
  Verifique los campos señalados en rojo
</mat-error>
@if (isExpanded) {
<div>
  @for (error of errorList; track $index) {
  <mat-error>{{ error }}</mat-error>
  }
</div>
} }
