// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { ClientDto } from './ClientDto';

export class ClientUsersAdminFiltersDto extends FiltersDto {
  client: ClientDto;
  appUserName: string = '';
}

export interface ClientUsersAdminFiltersDtoFormGroup extends FiltersDtoFormGroup {
  client?: any;
  appUserName?: any;
}
