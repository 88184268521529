// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class AuthUserRolesUpdateDto  {
  authUserId: string = '';
  rolesToAdd: string[] = [];
  rolesToRemove: string[] = [];
}

export interface AuthUserRolesUpdateDtoFormGroup  {
  authUserId?: any;
  rolesToAdd?: any;
  rolesToRemove?: any;
}
