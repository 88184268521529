import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PaymentOrderDto } from '../../../../api/dtos/PaymentOrderDto';
import { ApiAdminPaymentOrder } from '../../../../api/endpoints/ApiAdminPaymentOrder';
import { OperationService } from '../../operation/operation.service';

@Component({
  selector: 'mf-payment-order-edit',
  templateUrl: './payment-order-edit.component.html',
  styleUrls: ['./payment-order-edit.component.scss'],
})
export class PaymentOrderEditComponent {
  constructor(
    private _apiAdminPaymentOrder: ApiAdminPaymentOrder,
    private _modalService: ModalService,
    private _operationService: OperationService
  ) {
    this.load(this._modalService.openData.paymentOrderId);
  }

  paymentOrderDto: PaymentOrderDto;

  async load(id: string) {
    this.paymentOrderDto = await this._apiAdminPaymentOrder.getById(id);
  }

  async save(paymentOrderDto: PaymentOrderDto) {
    paymentOrderDto.id = this.paymentOrderDto.id;
    await this._apiAdminPaymentOrder.editDraft(paymentOrderDto);
    this._operationService.reloadOperation(paymentOrderDto.operation.id);
    this._modalService.closeAsSuccess('La orden se editó con éxito');
  }
}
