// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { TemplateDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { TemplateFiltersDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiTemplate {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getPaged(
    filters: PagedSearchDto<TemplateFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<TemplateDto>> {
    return this._httpApiClient.post("/shared/template/paged", filters, httpApiRequestOptions);
  }

}
