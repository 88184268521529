// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { TemplatePdfDto } from "../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplatePdfDto";

@Injectable({providedIn: 'root'})
export class ApiTemplatePdf {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    templatePdfDto: TemplatePdfDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplatePdfDto> {
    return this._httpApiClient.post("/shared/template/pdf", templatePdfDto, httpApiRequestOptions);
  }

  edit(
    templatePdfDto: TemplatePdfDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplatePdfDto> {
    return this._httpApiClient.put("/shared/template/pdf", templatePdfDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TemplatePdfDto> {
    return this._httpApiClient.get("/shared/template/pdf/" + id, httpApiRequestOptions);
  }

}
