import { Component, ElementRef, Input, OnChanges, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { BankBranchDto } from '../../../api/dtos/BankBranchDto';
import { BankBranchFiltersDto } from '../../../api/dtos/BankBranchFiltersDto';
import { ApiAdminBankBranch } from '../../../api/endpoints/ApiAdminBankBranch';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';

@Component({
  selector: 'mf-autocomplete-bank-branch',
  templateUrl: './autocomplete-bank-branch.component.html',
  styleUrls: ['./autocomplete-bank-branch.component.scss'],
})
export class AutocompleteBankBranchComponent
  extends AutoCompleteBaseEntityComponent<BankBranchDto | null, BankBranchFiltersDto>
  implements OnChanges
{
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef, private _apiAdminBankBranch: ApiAdminBankBranch) {
    super(ngControl, elRef);
  }

  override defaultOrderBy = nameof<BankBranchDto>((p) => p.name);

  @Input()
  bankId?: string;
  @Input()
  autoCompleteMaxItems: number;

  override endpointSuggest = (search: SuggestSearchDto<BankBranchFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminBankBranch.suggest(search, httpApiRequestOptions);

  override setDefaultFilters() {
    this.filters.bankId = this.bankId ? this.bankId : null;
  }

  ngOnChanges() {
    this.setDefaultFilters();

    if (this.value && this.value.bankId != this.filters.bankId) {
      this.selectedItemChange(null);
    }
  }
}
