import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ValueListDto } from '../../../api/dtos/ValueListDto';
import { ApiAdminValueList } from '../../../api/endpoints/ApiAdminValueList';

@Component({
  selector: 'mf-value-list-edit',
  templateUrl: './value-list-edit.component.html',
  styleUrls: ['./value-list-edit.component.scss'],
})
export class ValueListEditComponent {
  constructor(private _apiValueList: ApiAdminValueList, private _modalService: ModalService) {
    this.load();
  }

  valueListDto: ValueListDto;

  async load() {
    this.valueListDto = await this._apiValueList.getById(this._modalService.openData);
  }

  async save(valueListDto: ValueListDto) {
    valueListDto.id = this.valueListDto.id;
    await this._apiValueList.edit(valueListDto);
    this._modalService.closeAsSuccess('La lista administrable se ha editado correctamente');
  }
}
