// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class FilterRangeDateDto  {
  type: number | null = null;
  min: Date | null = null;
  max: Date | null = null;
  specificDate: Date | null = null;
}

export interface FilterRangeDateDtoFormGroup  {
  type?: any;
  min?: any;
  max?: any;
  specificDate?: any;
}
