<mf-row [style]="rowStyle">
  <textarea
    #editingTextArea
    id="editing"
    [(ngModel)]="value"
    (ngModelChange)="updateCodeValue()"
    (scroll)="syncScroll()"
    [spellcheck]="false"></textarea>

  <pre
    id="highlighting"
    class="language-{{ language }}"
    [style]="rowStyle">
        <code 
            #codeElement 
            class="language-{{ language }}">{{ value }}</code>
    </pre>
</mf-row>
