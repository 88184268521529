import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { TemplateDto } from '../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplateFiltersDto } from '../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateFiltersDto';
import { enumName_templates } from '../../../../../../libs/utils/ng-shared-components/src/lib/templates/enums/_enumName.templates';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { IHttpApiRequestOptions } from '../../../../../../libs/utils/ng-shared-components/src/lib/IHttpApiRequestOptions';
import { ApiTemplate } from '../../../api/endpoints/ApiTemplate';
import { TemplateGroup } from '../../../../../../libs/utils/ng-shared-components/src/lib/templates/enums/TemplateGroup';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { TemplateGenericEditComponent } from './generic/template-generic-edit.component';
import { TemplateGenericCreateComponent } from './generic/template-generic-create.component';
import { TemplateEmailCreateComponent } from './email/template-email-create.component';
import { TemplateEmailEditComponent } from './email/template-email-edit.component';
import { TemplatePdfCreateComponent } from './pdf/template-pdf-create.component';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';

@Component({
  selector: 'mf-template-grid',
  templateUrl: './template-grid.component.html',
  styleUrls: ['./template-grid.component.scss'],
})
export class TemplateGridComponent {
  constructor(private _apiTemplate: ApiTemplate, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  template: TemplateDto;
  templateGroup = TemplateGroup;

  // ngOnInit() {
  //   this.openNewTemplatePdf();
  // }

  columns: IGridColumn<TemplateDto>[] = [
    {
      field: nameof<TemplateDto>((p) => p.group),
      header: 'Grupo',
      width: 10,
      cellOptions: { type: CellValueType.Enum, enumName: enumName_templates.TemplateGroup, enumNameModule: moduleName.templates },
      filter: {
        propertyName: nameof<TemplateFiltersDto>((p) => p.group),
        type: FilterType.Enum,
        enumName: enumName_templates.TemplateGroup,
        enumNameModule: moduleName.templates,
      },
    },
    {
      field: nameof<TemplateDto>((p) => p.type.name),
      header: 'Tipo',
      width: 10,
    },
    {
      field: nameof<TemplateDto>((p) => p.name),
      header: 'Nombre',
      width: 80,
      filter: {
        propertyName: nameof<TemplateFiltersDto>((p) => p.defaultTextSearch),
        type: FilterType.Text,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (templateDto: TemplateDto) => {
        if (templateDto.group === TemplateGroup.Generic) {
          this._modalService.openRightModalFull(TemplateGenericEditComponent, { openData: templateDto.id });
        } else if (templateDto.group === TemplateGroup.Email) {
          this._modalService.openRightModalFull(TemplateEmailEditComponent, { openData: templateDto.id });
        }
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<TemplateFiltersDto>, httpApiRequestOptions: IHttpApiRequestOptions) =>
    this._apiTemplate.getPaged(search, httpApiRequestOptions);

  openNewTemplateGeneric() {
    this._modalService.openRightModalFull(TemplateGenericCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }

  openNewTemplateEmail() {
    this._modalService.openRightModalFull(TemplateEmailCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }

  openNewTemplatePdf() {
    this._modalService.openRightModalFull(TemplatePdfCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
