@switch (gridFilter.type) { @case (filterType.Text) {
<mf-grid-filter-text
  [gridFilter]="gridFilter"
  [(ngModel)]="value">
</mf-grid-filter-text>
} @case (filterType.NumericRange) {
<mf-grid-filter-numeric-range
  [gridFilter]="gridFilter"
  [(ngModel)]="value"></mf-grid-filter-numeric-range>
} @case (filterType.Number) {
<mf-grid-filter-numeric
  [gridFilter]="gridFilter"
  [(ngModel)]="value"></mf-grid-filter-numeric>
} @case (filterType.Date) {
<mf-grid-filter-date
  [gridFilter]="gridFilter"
  [(ngModel)]="value"></mf-grid-filter-date>
} @case (filterType.Enum) {
<mf-grid-filter-select
  [gridFilter]="gridFilter"
  [(ngModel)]="value"></mf-grid-filter-select>
} @case (filterType.EnumList) {
<mf-grid-filter-select-multiple
  [gridFilter]="gridFilter"
  [(ngModel)]="value"></mf-grid-filter-select-multiple>
} }
