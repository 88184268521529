<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  (menuOpened)="isOpen = true"
  [ngStyle]="{ padding: 0 }">
  <mf-icon icon="{{ icons.moreVert }} "></mf-icon>
</button>
<mat-menu #menu="matMenu">
  @if (isOpen) { @for (menuItem of menuItems; track menuItem.label) { @if (menuItem.hide ? !menuItem.hide(item) : true) {
  <button
    [disabled]="getDisabled(menuItem) !== null"
    [title]="getTooltip(menuItem)"
    (click)="menuItem.onClick(item)"
    mat-menu-item>
    <mf-icon
      class="menu-icon"
      [icon]="menuItem.icon"></mf-icon>
    <mat-label>{{ menuItem.label }}</mat-label>
  </button>
  } } }
</mat-menu>
