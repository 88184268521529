import { Component, ElementRef, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IHttpApiRequestOptions } from '../../IHttpApiRequestOptions';
import { Icons } from '../../icon/icons';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';
import { FileUploadResponseDto } from './dtos/FileUploadResponseDto';

@Component({
  selector: 'mf-field-file-upload',
  templateUrl: './field-file-upload.component.html',
  styleUrls: ['./field-file-upload.component.scss'],
})
export class FieldFileUploadComponent extends FieldMatBaseComponent<string | null> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef) {
    super(ngControl, elRef);
  }
  @Input()
  optionsList: any[] = [];
  override get controlType(): string {
    return 'field-file-upload';
  }

  ngOnInit(): void {
    this.fileExtension = this.value ? this.value.substring(this.value.lastIndexOf('.')) : null;
  }

  icons = Icons;
  progress: number | null = null;
  loadingErrorMessage: string | null = null;
  fileExtension: string | null = null;

  @Input()
  endpointUpload: (fileList: FileList, httpApiRequestOptions: IHttpApiRequestOptions) => Promise<FileUploadResponseDto>;

  @Input()
  maxSize: number;

  get errorMessage(): string | null {
    if (this.loadingErrorMessage) {
      return this.loadingErrorMessage;
    }

    if (this.ngControl.control?.valid == false && this.ngControl.control?.touched) {
      return this.ngControl.control?.errors?.loading
        ? 'El archivo se está cargando'
        : this.ngControl.control?.errors?.required
        ? 'El campo es requerido'
        : 'Campo inválido';
    }
    return null;
  }

  onFileSelected(event: any) {
    this.uploadFile(event.target.files);
  }

  cancelUpload() {
    this.progress = null;
  }

  async uploadFile(files: FileList) {
    this.fileExtension = files[0].name.substring(files[0].name.lastIndexOf('.'));
    this.progress = 0;
    this.ngControl.control?.setErrors({ loading: true });
    try {
      const fileUploadResponse = await this.endpointUpload(files, {
        preventSpinner: true,
        preventErrorHandler: true,
        progressCallback: (progress: number) => (this.progress = progress),
      });
      this.value = fileUploadResponse.fileUrl;
      this.progress = 100;
      delete this.ngControl.control?.errors?.loading;
    } catch (error) {
      this.progress = null;
      this.loadingErrorMessage = 'Error: ' + (error as string);
    }
  }
}
