<mf-form
  [formGroup]="notificationForm"
  (save)="save.emit($event)">
  <mf-column>
    <mf-autocomplete-user
      formControlName="appUser"
      (itemIdChanged)="notificationForm.patchValue({ appUserId: $event })" />
  </mf-column>

  <mf-column>
    <mf-field-date formControlName="date" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="text" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="title" />
  </mf-column>

  <mf-column>
    <mf-field-select
      formControlName="type"
      [enumName]="enumName.NotificationType" />
  </mf-column>

  <mf-column>
    <mf-autocomplete-client
      formControlName="client"
      (itemIdChanged)="notificationForm.patchValue({ clientId: $event })" />
  </mf-column>
</mf-form>
