<ng-container [formGroup]="form">
  <mf-column [md]="2">
    <h2>Campos</h2>
    <mf-template-editor-fields [fields]="templateDesign.fields" />
  </mf-column>

  <mf-column [md]="6">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Cuerpo">
        <mf-container>
          <mf-code-editor
            language="html"
            formControlName="body" />
        </mf-container>
      </mat-tab>
      <mat-tab label="Estilos">
        <mf-container>
          <mf-code-editor
            language="css"
            formControlName="styles" />
        </mf-container>
      </mat-tab>
      <mat-tab label="Preview data">
        <mf-container>
          <mf-code-editor
            language="json"
            formControlName="previewData" />
        </mf-container>
      </mat-tab>
    </mat-tab-group>
  </mf-column>

  <mf-column [md]="4">
    <mf-template-editor-preview />
  </mf-column>
</ng-container>
