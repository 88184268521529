<mf-page title="Crear reporte para el BCU">
  <mf-form
    [formGroup]="bcuReportForm"
    (save)="createBcuReport($event)">
    <mf-row>
      <mf-column [md]="3">
        <mf-field-date formControlName="startDate" />
      </mf-column>

      <mf-column [md]="3">
        <mf-field-date formControlName="endDate" />
      </mf-column>

      <mf-column [md]="3">
        <mf-autocomplete-bank
          formControlName="bank"
          (itemIdChanged)="bcuReportForm.patchValue({ bankId: $event })" />
      </mf-column>
    </mf-row>
  </mf-form>
</mf-page>
