import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplateDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplateEmailDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateEmailDto';
import { ApiTemplateEmail } from '../../../../api/endpoints/ApiTemplateEmail';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-template-email-edit',
  templateUrl: './template-email-edit.component.html',
  styleUrls: ['./template-email-edit.component.scss'],
})
export class TemplateEmailEditComponent {
  constructor(private _apiEmailTemplate: ApiTemplateEmail, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  templateEmailDto: TemplateEmailDto;

  async load(id: string) {
    this.templateEmailDto = await this._apiEmailTemplate.getById(id);
  }

  async save(templateDto: TemplateDto) {
    await this._apiEmailTemplate.edit(templateDto as TemplateEmailDto);
    this._modalService.closeAsSuccess('La plantilla de correo se editó con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
