<mf-form
  [formGroup]="collectOrderVoucherForm"
  (save)="save.emit($event)">
  <mf-row>
    <mf-field-file-upload
      formControlName="voucherUrl"
      [endpointUpload]="endpoint" />
  </mf-row>

  <mf-column>
    <mf-field-text formControlName="amount" />
  </mf-column>

  <mf-column>
    <mf-field-date formControlName="uploadDate" />
  </mf-column>

  <mf-row>
    <mf-column>
      <mf-autocomplete-bank-account
        formControlName="sourceBankAccount"
        [personId]="personId"
        [currency]="transferVoucherForCollectOrderDto.currency"
        (itemIdChanged)="collectOrderVoucherForm.patchValue({ sourceBankAccountId: $event })">
      </mf-autocomplete-bank-account>
    </mf-column>
    <mf-column>
      <mf-autocomplete-bank-account
        formControlName="destinationBankAccount"
        [personId]="miPspcId"
        [currency]="transferVoucherForCollectOrderDto.currency"
        (itemIdChanged)="collectOrderVoucherForm.patchValue({ destinationBankAccountId: $event })">
      </mf-autocomplete-bank-account>
    </mf-column>
  </mf-row>

  <mf-row>
    <mf-field-text formControlName="reference" />
  </mf-row>

  <mf-row>
    <mf-collect-order-voucher-grid
      (selectedItems)="selectItems($event)"
      [personId]="personId"
      [currency]="transferVoucherForCollectOrderDto.currency" />
  </mf-row>
</mf-form>
