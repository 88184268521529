import { Component, Input } from '@angular/core';

@Component({
  selector: 'mf-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input()
  size: 'small' | 'normal' | 'big' | 'bigger' = 'normal';
  @Input()
  color = '#8b9e23';

  get pixelSize(): number {
    switch (this.size) {
      case 'small':
        return 16;
      case 'normal':
        return 24;
      case 'big':
        return 42;
      case 'bigger':
        return 76;
    }
  }
}
