<mf-page title="Roles de usuario">
  @if (appUserDto) {
  <mf-column label="Usuario">{{ appUserDto.naturalPerson.email }}</mf-column>

  <mf-form
    [formGroup]="rolesFrom"
    (save)="save($event)">
    @for (role of allRoles; track role.id) {
    <mf-row label="Rol">
      <mf-field-toggle
        label="{{ role.name }}"
        formControlName="{{ role.name }}" />
    </mf-row>
    }
  </mf-form>
  }
</mf-page>
