<mf-page title="Incidentes">
  <mf-grid
    #grid
    actionButtonText="Nuevo incidente"
    [initialFilters]="filters"
    [apiEndpoint]="apiEndpoint"
    [columns]="columns"
    [menuItems]="menuItems"
    [mustIncludeFields]="mustIncludeFields"
    (actionButtonClick)="openNewIssuePopup()" />
</mf-page>
