<mat-card [style]="style">
  @if (title) {
  <mat-card-header>
    <mat-card-title>
      <h4>{{ title }}</h4>
    </mat-card-title>
  </mat-card-header>
  }
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
