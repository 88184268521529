<div class="header">{{ filter.header }}</div>
<div class="tags">
  @for (tagValue of tagValues; track $index) {
  <div
    class="value"
    [title]="tagValue.label">
    {{ tagValue.label }}
    <mf-icon
      [icon]="icons.accreditations"
      size="small"
      class="icon-remove"
      color="secondary"
      (click)="removeFilter(tagValue)"></mf-icon>
  </div>
  }
</div>
