import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { IssueDto } from '../../../api/dtos/IssueDto';
import { IssueFiltersDto } from '../../../api/dtos/IssueFiltersDto';
import { ApiAdminIssue } from '../../../api/endpoints/ApiAdminIssue';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { IssueStatus } from '../../../api/enums/IssueStatus';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { IssueCreateComponent } from './issue-create.component';
import { IssueDetailsComponent } from './issue-details.component';
import { IssueEditComponent } from './issue-edit.component';

@Component({
  selector: 'mf-issue-grid',
  templateUrl: './issue-grid.component.html',
  styleUrls: ['./issue-grid.component.scss'],
})
export class IssueGridComponent implements OnInit {
  constructor(
    public _apiAdminIssue: ApiAdminIssue,
    private _modalService: ModalService,
    private _notificationService: NotificationService
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input()
  showCreateButton = false;

  filters: IssueFiltersDto;

  ngOnInit(): void {
    this.filters = new IssueFiltersDto();
  }

  mustIncludeFields = [nameof<IssueDto>((i) => i.description)];

  columns: IGridColumn<IssueDto>[] = [
    {
      field: nameof<IssueDto>((i) => i.issueNumber),
      header: 'Numero del incidente',
      width: 10,
    },

    {
      field: nameof<IssueDto>((i) => i.level),
      header: 'Nivel del incidente',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.IssueLevel,
      },
    },
    {
      field: nameof<IssueDto>((i) => i.type),
      header: 'Entidad Relacionada',
      width: 20,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.IssueType,
      },
    },
    {
      field: nameof<IssueDto>((i) => i.status),
      header: 'Estado',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.IssueStatus,
      },
    },
    {
      field: nameof<IssueDto>((i) => i.displayText),
      header: 'Descripción',
      width: 30,
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver detalles',
      icon: Icons.search,
      onClick: (issueDto: IssueDto) => {
        this._modalService.openRightModal(IssueDetailsComponent, {
          openData: issueDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Marcar como resuelto',
      icon: Icons.check,
      onClick: async (issueDto: IssueDto) => {
        await this._apiAdminIssue.resolve(issueDto.id);
        this._notificationService.showSuccess('El incidente se marco como resuelto');
        this.grid.reloadPage();
      },
    },
    {
      label: 'Reabrir incidente',
      icon: Icons.undo,
      hide: (issueDto: IssueDto) => issueDto.status !== IssueStatus.Resolved,
      onClick: async (issueDto: IssueDto) => {
        await this._apiAdminIssue.reopen(issueDto.id);
        this._notificationService.showSuccess('El incidente se reabrió con éxito');
        this.grid.reloadPage();
      },
    },
    {
      label: 'Asignar a usuario',
      icon: Icons.person,
      onClick: async (issueDto: IssueDto) => {
        await this._apiAdminIssue.assignToCurrentUser(issueDto.id);
        this._notificationService.showSuccess('El incidente se asignó al usuario con éxito');
        this.grid.reloadPage();
      },
    },
    {
      label: 'Editar incidente',
      icon: Icons.edit,
      onClick: async (issueDto: IssueDto) => {
        this._modalService.openRightModal(IssueEditComponent, {
          openData: issueDto,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<IssueFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminIssue.getPaged(search, httpApiRequestOptions);

  openNewIssuePopup() {
    this._modalService.openRightModal(IssueCreateComponent, {
      openData: {},
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
