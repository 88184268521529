// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { OrderDto, OrderDtoFormGroup } from './OrderDto';

export class CollectOrderDto extends OrderDto {
  referredOrderId: string | null = null;
  referredOrder: CollectOrderDto;
}

export interface CollectOrderDtoFormGroup extends OrderDtoFormGroup {
  referredOrderId?: any;
  referredOrder?: any;
}
