<mat-form-field [style.width.%]="100">
  <mat-label> {{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }} </mat-label>
  <input
    matInput
    [(ngModel)]="value"
    type="password"
    [errorStateMatcher]="errorStateMatcher"
    (blur)="onTouched()" />
  <mat-hint>{{ hint }} </mat-hint>
  @if (validationMessage) {
  <mat-error>
    {{ validationMessage }}
  </mat-error>
  }
</mat-form-field>
