// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { AccreditationsByAccountDto } from "../dtos/AccreditationsByAccountDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { AccreditationsByAccountFiltersDto } from "../dtos/AccreditationsByAccountFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { AccreditationsByAccountVoucherDto } from "../dtos/AccreditationsByAccountVoucherDto";
import { AccreditationsByAccountCancelDto } from "../dtos/AccreditationsByAccountCancelDto";

@Injectable({providedIn: 'root'})
export class ApiAdminAccreditationsByAccount {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    accreditationsByAccountDto: AccreditationsByAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AccreditationsByAccountDto> {
    return this._httpApiClient.post("/admin/accreditations-by-account", accreditationsByAccountDto, httpApiRequestOptions);
  }

  edit(
    accreditationsByAccountDto: AccreditationsByAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AccreditationsByAccountDto> {
    return this._httpApiClient.put("/admin/accreditations-by-account", accreditationsByAccountDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AccreditationsByAccountDto> {
    return this._httpApiClient.get("/admin/accreditations-by-account/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<AccreditationsByAccountFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<AccreditationsByAccountDto>> {
    return this._httpApiClient.post("/admin/accreditations-by-account/paged", filters, httpApiRequestOptions);
  }

  setAsFileDownloaded(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/accreditations-by-account/set-as-file-downloaded/" + id, null, httpApiRequestOptions);
  }

  uploadVoucher(
    accByAccountVoucherDto: AccreditationsByAccountVoucherDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/accreditations-by-account/upload-voucher", accByAccountVoucherDto, httpApiRequestOptions);
  }

  cancel(
    accreditationsByAccountCancelDto: AccreditationsByAccountCancelDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/accreditations-by-account/cancel", accreditationsByAccountCancelDto, httpApiRequestOptions);
  }

}
