<mat-form-field [style.width.%]="100">
  <mat-label>{{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }}</mat-label>
  @if (multiline) {
  <textarea
    matInput
    [(ngModel)]="value"
    [errorStateMatcher]="errorStateMatcher"
    [readonly]="readOnly"
    (blur)="onBlur()"
    [rows]="multilineRows"
    [disabled]="readOnly"
    style="resize: none"></textarea>
  } @else {
  <input
    matInput
    [(ngModel)]="value"
    [errorStateMatcher]="errorStateMatcher"
    [readonly]="readOnly"
    (blur)="onBlur()"
    [disabled]="readOnly" />
  } @if (loading) {
  <mat-spinner
    diameter="24"
    style="position: absolute; right: 6px; top: 12px"></mat-spinner>
  }
  <mat-hint>{{ hint }}</mat-hint>
  @if (validationMessage){
  <mat-error>
    {{ validationMessage }}
  </mat-error>
  }
</mat-form-field>
