<mf-row-separator [minHeight]="80"></mf-row-separator>
<mf-column [md]="3"></mf-column>
<mf-column [md]="6">
  <mf-section title="Registrarse">
    <mf-form
      [formGroup]="appUserRegisterForm"
      [entity]="appUserRegisterDto"
      (save)="register($event)">
      <ng-container formGroupName="naturalPerson">
        <mf-row>
          <mf-field-text formControlName="firstName" />
        </mf-row>
        <mf-row>
          <mf-field-text formControlName="lastName" />
        </mf-row>
        <mf-row>
          <mf-id-document
            [personType]="personType.NaturalPerson"
            formControlName="idDocument">
          </mf-id-document>
        </mf-row>
        <mf-row>
          <mf-field-text
            formControlName="email"
            [readOnly]="appUserRegisterDto.isGoogle">
          </mf-field-text>
        </mf-row>
      </ng-container>
      @if (!appUserRegisterDto.isGoogle) {
      <mf-row>
        <mf-field-password formControlName="password" />
      </mf-row>
      <mf-row>
        <mf-field-password formControlName="passwordConfirm" />
      </mf-row>
      }
    </mf-form>
  </mf-section>
</mf-column>
