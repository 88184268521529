// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { LoggingConfigCategoryDto } from "../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LoggingConfigCategoryDto";

@Injectable({providedIn: 'root'})
export class ApiAdminLoggingConfig {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getDefaultLogLevel(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<LoggingConfigCategoryDto> {
    return this._httpApiClient.get("/admin/logging-config/default-level", httpApiRequestOptions);
  }

  getMiFinanzasAll(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<LoggingConfigCategoryDto[]> {
    return this._httpApiClient.get("/admin/logging-config/mifinanzas-all", httpApiRequestOptions);
  }

  logTest(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.post("/admin/logging-config/log-test", null, httpApiRequestOptions);
  }

  getSqlQueryIsEnabled(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<boolean> {
    return this._httpApiClient.get("/admin/logging-config/sql-query-is-enabled", httpApiRequestOptions);
  }

  setSqlQueryIsEnabled(
    value: boolean,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/logging-config/set-sql-query-is-enabled?" + 
      (value ? "&value=" + value : ""), null, httpApiRequestOptions);
  }

  throwTestException(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.post("/admin/logging-config/throw-test-exception", null, httpApiRequestOptions);
  }

  setDefaultLevel(
    loggingLevel: number,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/logging-config/set-default-level?" + 
      (loggingLevel ? "&loggingLevel=" + loggingLevel : ""), null, httpApiRequestOptions);
  }

  resetAll(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/logging-config/reset-all", null, httpApiRequestOptions);
  }

  setCategoryLevel(
    categoryName: string,
    
    loggingLevel: number | null,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/logging-config/set-category-level?" + 
      (categoryName ? "&categoryName=" + categoryName : "") + 
      (loggingLevel ? "&loggingLevel=" + loggingLevel : ""), null, httpApiRequestOptions);
  }

}
