<mat-toolbar>
  <div class="container">
    <div>
      <button
        mat-icon-button
        class="example-icon"
        aria-label="Example icon-button with menu icon"
        (click)="toggleSidenav()">
        <mf-icon [icon]="icons.menu"></mf-icon>
      </button>
    </div>
    <div>
      <img
        [alt]="logoAlt"
        [src]="logoUrl"
        class="logo" />
    </div>
    <div class="section-quick-access">
      @for (quickAccess of quickAccessList; track $index) {
      <div
        class="quick-access"
        [class.selected]="quickAccess.selected"
        (click)="quickAccessClick(quickAccess)">
        {{ quickAccess.label }}
      </div>
      }
    </div>
    <div>
      @if (showNotifications) {
      <mf-notification-tray></mf-notification-tray>
      }
    </div>
    <div>
      <mf-menu
        [menuItems]="menuItems"
        [buttonName]="userName"
        [buttonLegend]="clientName"></mf-menu>
    </div>
  </div>
</mat-toolbar>
