import { Component, ElementRef, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { EnumTranslateProviderService } from '../../services/enum-translate-provider.service';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

export class SelectOption {
  constructor(public value: number | null, public description: string) {}
}

@Component({
  selector: 'mf-field-select-multiple',
  templateUrl: './field-select-multiple.component.html',
  styleUrls: ['./field-select-multiple.component.scss'],
})
export class FieldSelectMultipleComponent extends FieldMatBaseComponent<number[] | null> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _enumTranslateProviderService: EnumTranslateProviderService
  ) {
    super(ngControl, elRef);
  }

  override get controlType(): string {
    return 'field-select';
  }

  @Input() options: SelectOption[] = [];
  @Input() enumName: string = '';
  @Input() enumNameModule?: string = '';
  @Input() allowSelectAll: boolean = true;

  get allSelected(): boolean {
    return !!this.value && this.value.length === this.options?.length;
  }

  ngOnInit(): void {
    if (this.enumName) {
      this.options = this._enumTranslateProviderService.getEnumOptions(this.enumName, this.enumNameModule);
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.value = [];
      return;
    }
    this.value = this.options.map((item) => item.value ?? 0);
  }
}
