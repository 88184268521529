<mf-column [md]="6">
  <mf-field-numeric
    label="{{ gridFilter.header }} min"
    [allowNulls]="true"
    [decimals]="gridFilter.intOnly ? 0 : 2"
    [(ngModel)]="min"></mf-field-numeric>
</mf-column>
<mf-column [md]="6">
  <mf-field-numeric
    label="{{ gridFilter.header }} max"
    [allowNulls]="true"
    [decimals]="gridFilter.intOnly ? 0 : 2"
    [(ngModel)]="max"></mf-field-numeric>
</mf-column>
