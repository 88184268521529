export function enumValueText(value: number, enumType: any): string {
  if (Number(value) >= 0) {
    return enumType[value];
  }
  throw new Error('Invalid enum value provided: ' + value);
}

export function enumValueTextList(values: number[], enumType: any): string[] {
  let valueList: string[] = [];
  values.forEach((value) => {
    valueList.push(enumValueText(value, enumType));
  });
  return valueList;
}
