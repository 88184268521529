import { Component, Input } from '@angular/core';
import { TemplateDesignDto } from '../dtos/TemplateDesignDto';
import { enumName_templates } from '../enums/_enumName.templates';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { TemplateDesignFieldDto } from '../dtos/TemplateDesignFieldDto';

@Component({
  selector: 'mf-template-editor-fields',
  templateUrl: './template-editor-fields.component.html',
  styleUrls: ['./template-editor-fields.component.scss'],
})
export class TemplateEditorFieldsComponent {
  @Input()
  fields: TemplateDesignFieldDto[];
}
