// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';

export class AccreditationFiltersDto extends FiltersDto {
  type: number | null = null;
  statuses: number[] = [];
}

export interface AccreditationFiltersDtoFormGroup extends FiltersDtoFormGroup {
  type?: any;
  statuses?: any;
}
