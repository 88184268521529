import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CollectOrderDto } from '../../../../api/dtos/CollectOrderDto';
import { CollectOrderFiltersDto } from '../../../../api/dtos/CollectOrderFiltersDto';
import { OrderDto } from '../../../../api/dtos/OrderDto';
import { ApiAdminCollectOrder } from '../../../../api/endpoints/ApiAdminCollectOrder';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { OrderStatus } from '../../../../api/enums/OrderStatus';
import { Currency } from '../../../../api/enums/Currency';

@Component({
  selector: 'mf-collect-order-voucher-grid',
  templateUrl: './collect-order-voucher-grid.component.html',
  styleUrls: ['./collect-order-voucher-grid.component.scss'],
})
export class CollectOrderVoucherGridComponent implements OnInit {
  constructor(private _apiAdminCollectOrder: ApiAdminCollectOrder) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() disableCreateButton = false;
  @Input() hideFilters = false;
  @Input() showCreateButton = false;
  @Input() personId: string | null;
  @Input() currency: Currency;
  @Output() selectedItems = new EventEmitter<any>();

  filters: CollectOrderFiltersDto;

  ngOnInit(): void {
    this.filters = new CollectOrderFiltersDto();
    this.filters.personId = this.personId;
    this.filters.currency = this.currency;
    this.filters.statusList.push(OrderStatus.Pending);
    this.hideFilters = true;
  }

  columns: IGridColumn<CollectOrderDto>[] = [
    {
      field: nameof<CollectOrderDto>((o) => o.operation.operationNumber),
      header: 'Operación',
      width: 5,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.operationNumber),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.orderNumber),
      header: 'Número',
      width: 5,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.orderNumber),
        type: FilterType.Number,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.person.fullName),
      header: 'Persona',
      width: 20,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.personFullName),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<OrderDto>((o) => o.status),
      header: 'Estado',
      width: 10,

      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.OrderStatus,
      },
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.OrderStatus,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.operation.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.currency),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    { field: nameof<CollectOrderDto>((o) => o.amount), header: 'Monto', width: 10, align: 'right' },
  ];

  //menuItems: GridMenuItem[] = [];

  apiEndpoint = (search: PagedSearchDto<CollectOrderFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminCollectOrder.getPaged(search, httpApiRequestOptions);

  selectItems(event: any) {
    this.selectedItems.emit(event);
  }
}
