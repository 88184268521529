<div class="pager-page-size">
  <span>Filas por página</span>
  <select
    title="Filas"
    [(ngModel)]="searchDto.pageSize"
    (ngModelChange)="pageChange()">
    @for (pageSize of pageSizeOptions; track $index) {
    <option [ngValue]="pageSize">
      {{ pageSize }}
    </option>
    }
  </select>
</div>

<div class="pager-page-info">
  @if (searchDto && page && page.totalItemCount > 0) {
  {{ itemRangeText }}
  de
  {{ page.totalItemCount | number : '1.0-0' }}
  }
</div>

<div class="pager-buttons">
  <mf-button-icon
    tooltip="Primera página"
    [icon]="icons.firstPage"
    (click)="goToPage(1)"
    [disabled]="!page || page.currentPageIndex === 1"></mf-button-icon>

  <mf-button-icon
    tooltip="Página anterior"
    [icon]="icons.chevronLeft"
    (click)="goToPage(page.currentPageIndex - 1)"
    [disabled]="!page || page.currentPageIndex === 1"></mf-button-icon>

  <mf-button-icon
    tooltip="Página siguiente"
    [icon]="icons.chevronRight"
    (click)="goToPage(page.currentPageIndex + 1)"
    [disabled]="!page || page.totalPageCount === page.currentPageIndex || page.totalItemCount === 0"></mf-button-icon>

  <mf-button-icon
    tooltip="Última página"
    [icon]="icons.lastPage"
    (click)="goToPage(page.totalPageCount)"
    [disabled]="!page || page.totalPageCount === page.currentPageIndex || page.totalItemCount === 0"></mf-button-icon>
</div>
