// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { NaturalPersonDto } from '../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto';

export class AppUserRegisterDto  {
  naturalPerson: NaturalPersonDto;
  password: string = '';
  passwordConfirm: string = '';
  isGoogle: boolean = false;
}

export interface AppUserRegisterDtoFormGroup  {
  naturalPerson?: any;
  password?: any;
  passwordConfirm?: any;
  isGoogle?: any;
}
