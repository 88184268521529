// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { PersonFiltersDto, PersonFiltersDtoFormGroup } from './PersonFiltersDto';

export class NaturalPersonFiltersDto extends PersonFiltersDto {
  firstName: string = '';
  lastName: string = '';
}

export interface NaturalPersonFiltersDtoFormGroup extends PersonFiltersDtoFormGroup {
  firstName?: any;
  lastName?: any;
}
