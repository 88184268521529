import { Component } from '@angular/core';
import { OperationDto } from '../../../api/dtos/OperationDto';
import { OperationService } from './operation.service';

@Component({
  selector: 'mf-operation-create',
  templateUrl: './operation-create.component.html',
  styleUrls: ['./operation-create.component.scss'],
})
export class OperationCreateComponent {
  constructor(private _operationService: OperationService) {
    this._operationService.operationDto = new OperationDto();
  }

  async save(operationDto: OperationDto) {
    await this._operationService.createDraft(operationDto);
  }
}
