<div class="spinner">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; background: none; display: block; shape-rendering: auto"
    [style.width.px]="pixelSize"
    [style.height.px]="pixelSize"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid">
    <circle
      cx="50"
      cy="50"
      fill="none"
      [attr.stroke]="color"
      stroke-width="3"
      r="45"
      stroke-dasharray="212.05750411731105 72.68583470577035">
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"></animateTransform>
    </circle>
    <!-- <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; background: none; display: inline-block; shape-rendering: auto"
    [style.width.px]="pixelSize"
    [style.height.px]="pixelSize"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid">
    <path
      d="M36 50A14 14 0 0 0 64 50A14 15 0 0 1 36 50"
      [ngStyle]="{ fill: color }"
      stroke="none">
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1.0204081632653061s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 50.5;360 50 50.5"></animateTransform>
    </path>-->
  </svg>
</div>
