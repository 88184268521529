{
	"_locale": "EsUy",
	"Country": {
		"328": "Turkmenistan",
		"188": "Guadeloupe",
		"299": "Singapore",
		"232": "Madagascar",
		"343": "Virgin Islands (US)",
		"242": "Mayotte",
		"124": "Bermuda",
		"120": "Belarus",
		"121": "Belgium",
		"272": "Paraguay",
		"228": "Lithuania",
		"229": "Luxembourg",
		"270": "Panama",
		"276": "Poland",
		"231": "Macedonia",
		"296": "Serbia",
		"248": "Montenegro",
		"273": "Peru",
		"322": "Togo",
		"200": "Hungary",
		"173": "Fiji",
		"156": "Cuba",
		"143": "Chad",
		"189": "Guam",
		"262": "Niue",
		"204": "Iran",
		"205": "Iraq",
		"236": "Mali",
		"266": "Oman",
		"257": "NewCaledonia",
		"316": "SyrianArabRepublic",
		"314": "Sweden",
		"302": "Slovenia",
		"301": "Slovakia",
		"347": "Zambia",
		"306": "South Georgia And The South Sandwich Islands",
		"331": "Uganda",
		"294": "SaudiArabia",
		"317": "Taiwan",
		"327": "Turkey",
		"330": "Tuvalu",
		"126": "Bolivia",
		"292": "SanMarino",
		"187": "Grenada",
		"216": "Kiribati",
		"152": "CookIslands",
		"141": "CaymanIslands",
		"132": "British Indian Ocean Territory",
		"249": "Montserrat",
		"239": "Martinique",
		"101": "AlandIslands",
		"264": "NorthernMarianaIslands",
		"131": "Brazil",
		"217": "Korea del norte",
		"218": "Korea del sur",
		"323": "Tokelau",
		"122": "Belize",
		"125": "Bhutan",
		"198": "Honduras",
		"199": "HongKong",
		"135": "BurkinaFaso",
		"149": "Comoros",
		"182": "Germany",
		"342": "Virgin Islands (British)",
		"278": "PuertoRico",
		"181": "Georgia",
		"108": "Antarctica",
		"175": "France",
		"172": "FaroeIslands",
		"113": "Australia",
		"325": "Trinidad AndTobago",
		"106": "Angola",
		"133": "BruneiDarussalam",
		"196": "Heard Island and McDonald Islands",
		"240": "Mauritania",
		"221": "Laos",
		"158": "Cyprus",
		"139": "Canada",
		"269": "Palestina",
		"129": "Botswana",
		"251": "Mozambique",
		"213": "Jordan",
		"212": "Jersey",
		"193": "Guinea-Bissau",
		"295": "Senegal",
		"345": "WesternSahara",
		"184": "Gibraltar",
		"192": "Guinea",
		"194": "Guyana",
		"185": "Greece",
		"285": "SaintHelena, Ascension And Tristan Da Cunha",
		"180": "Gambia",
		"161": "Djibouti",
		"265": "Norway",
		"315": "Switzerland",
		"119": "Barbados",
		"208": "Israel",
		"219": "Kuwait",
		"326": "Tunisia",
		"174": "Finland",
		"281": "Romania",
		"159": "CzechRepublic",
		"286": "Saint Kitts And Nevis",
		"246": "Monaco",
		"243": "Mexico",
		"233": "Malawi",
		"111": "Armenia",
		"225": "Liberia",
		"222": "Latvia",
		"238": "MarshallIslands",
		"320": "Thailand",
		"279": "Qatar",
		"268": "Palau",
		"291": "Samoa",
		"310": "Sudan",
		"308": "Spain",
		"324": "Tonga",
		"321": "Timor",
		"244": "Micronesia",
		"346": "Yemen",
		"123": "Benin",
		"114": "Austria",
		"112": "Aruba",
		"145": "China",
		"144": "Chile",
		"150": "Congo",
		"211": "Japan",
		"165": "Egypt",
		"179": "Gabon",
		"183": "Ghana",
		"260": "Niger",
		"255": "Nepal",
		"254": "Nauru",
		"209": "Italy",
		"202": "India",
		"195": "Haiti",
		"215": "Kenya",
		"237": "Malta",
		"230": "Macao",
		"226": "Libya",
		"999": "Otro",
		"271": "PapuaNuevaGuinea",
		"224": "Lesotho",
		"151": "Congo",
		"223": "Lebanon",
		"336": "UnitedStatesMinorOutlyingIslands",
		"304": "Somalia",
		"100": "Afghanistan",
		"311": "Suriname",
		"138": "Cameroon",
		"227": "Liechtenstein",
		"137": "Cambodia",
		"339": "Vanuatu",
		"146": "ChristmasIsland",
		"170": "Ethiopia",
		"210": "Jamaica",
		"167": "EquatorialGuinea",
		"153": "CostaRica",
		"289": "Saint Pierre And Miquelon",
		"107": "Anguilla",
		"341": "Vietnam",
		"263": "NorfolkIsland",
		"329": "Turks And Caicos Islands",
		"293": "Sao Tome And Principe",
		"168": "Eritrea",
		"154": "Costa de Marfil",
		"169": "Estonia",
		"305": "SouthAfrica",
		"203": "Indonesia",
		"284": "Saint Barthelemy",
		"275": "Pitcairn",
		"261": "Nigeria",
		"105": "Andorra",
		"148": "Colombia",
		"333": "UnitedArabEmirates",
		"104": "AmericanSamoa",
		"235": "Maldives",
		"234": "Malaysia",
		"297": "Seychelles",
		"186": "Greenland",
		"115": "Azerbaijan",
		"103": "Algeria",
		"214": "Kazakhstan",
		"102": "Albania",
		"253": "Namibia",
		"319": "Tanzania",
		"303": "SolomonIslands",
		"178": "French Southern Territories",
		"207": "Isle of Man",
		"335": "UnitedStates",
		"164": "Ecuador",
		"256": "Netherlands",
		"241": "Mauritius",
		"348": "Zimbabwe",
		"288": "Saint Martin (Francesa)",
		"334": "UnitedKingdom",
		"206": "Ireland",
		"287": "SaintLucia",
		"307": "SouthSudan",
		"338": "Uzbekistan",
		"127": "Bonaire,Sint Eustatius y Saba",
		"197": "HolySee (Ciudad Del Vaticano)",
		"298": "SierraLeone",
		"134": "Bulgaria",
		"147": "Cocos (Keeling) Islands",
		"130": "BouvetIsland",
		"110": "Argentina",
		"247": "Mongolia",
		"258": "NewZealand",
		"166": "ElSalvador",
		"259": "Nicaragua",
		"290": "Saint Vincent And The Grenadines",
		"162": "Dominica",
		"176": "French Guiana",
		"252": "Myanmar",
		"309": "SriLanka",
		"171": "Falkland Islands (Malvinas)",
		"201": "Iceland",
		"313": "Swaziland",
		"300": "Sint Maarten (Alemana)",
		"128": "Bosnia and Herzegovina",
		"344": "Wallis And Futuna",
		"274": "Philippines",
		"318": "Tajikistan",
		"340": "Venezuela",
		"157": "Curacao",
		"163": "Dominican Republic",
		"136": "Burundi",
		"155": "Croatia",
		"277": "Portugal",
		"177": "French Polynesia",
		"332": "Ukraine",
		"160": "Denmark",
		"220": "Kyrgyzstan",
		"250": "Morocco",
		"142": "Central African Republic",
		"245": "Moldova",
		"1": "Uruguay",
		"267": "Pakistán",
		"109": "Antigua And Barbuda",
		"118": "Bangladesh",
		"190": "Guatemala",
		"191": "Guernsey",
		"283": "Rwanda",
		"282": "RussianFederation",
		"116": "Bahrain",
		"117": "Bahamas",
		"312": "Svalbard And Jan Mayen",
		"140": "CapeVerde"
	},
	"IdDocumentType": {
		"999": "PASSPORT",
		"3": "CPF",
		"2": "DNI",
		"1004": "NIT",
		"1005": "RUC",
		"1001": "RUT",
		"1006": "RIF",
		"1003": "CNPJ",
		"1002": "CUIT",
		"4": "CC",
		"1": "CI"
	},
	"PersonType": {
		"1": "Persona física",
		"2": "Empresa"
	}
}
