<mf-grid
  #grid
  actionButtonText="{{ showCreateButton ? 'Nueva orden de cobro' : '' }}"
  [hideFilters]="hideFilters"
  [disableActionButton]="disableCreateButton"
  [initialFilters]="filters"
  [apiEndpoint]="apiEndpoint"
  [columns]="columns"
  [rowSelection]="true"
  (selectedItems)="selectItems($event)" />
