import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalService } from '../../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CustomFormBuilder } from '../../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { BcuReportDto, BcuReportDtoFormGroup } from '../../../../../api/dtos/BcuReportDto';
import { ApiAdminBcuReport } from '../../../../../api/endpoints/ApiAdminBcuReport';

@Component({
  selector: 'mf-create-bcu-report-popup',
  templateUrl: './create-bcu-report-popup.component.html',
  styleUrls: ['./create-bcu-report-popup.component.scss'],
})
export class CreateBcuReportPopupComponent {
  constructor(private _cfb: CustomFormBuilder, private _apiAdminBcuReport: ApiAdminBcuReport, private _modalService: ModalService) {}

  bcuReportForm = this._cfb.group<BcuReportDtoFormGroup>({
    startDate: [new Date(), Validators.required, 'Fecha desde'],
    endDate: [new Date(), Validators.required, 'Fechas hasta'],
    bank: [null, Validators.required, 'Banco'],
    bankId: ['', Validators.required],
  });

  async createBcuReport(bcuReportDto: BcuReportDto) {
    await this._apiAdminBcuReport.create(bcuReportDto);
    this._modalService.closeAsSuccess('El reporte se creo con éxito');
  }

  openBcuReportPopup() {
    return;
  }
}
