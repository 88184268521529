<mf-page title="Plantillas">
  <mf-row-buttons align="left">
    <mf-button
      text="Nueva genérica"
      (click)="openNewTemplateGeneric()" />
    <mf-button
      text="Nuevo PDF"
      (click)="openNewTemplatePdf()" />
    <mf-button
      text="Nuevo correo"
      (click)="openNewTemplateEmail()" />
  </mf-row-buttons>
  <mf-row>
    <mf-grid
      #grid
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems" />
  </mf-row>
</mf-page>
