import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { CollectOrderDto, CollectOrderDtoFormGroup } from '../../../../api/dtos/CollectOrderDto';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { PersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';

@Component({
  selector: 'mf-collect-order',
  templateUrl: './collect-order.component.html',
  styleUrls: ['./collect-order.component.scss'],
})
export class CollectOrderComponent {
  constructor(private _cfb: CustomFormBuilder) {}

  @Input()
  collectOrderDto: CollectOrderDto;

  @Output()
  save = new EventEmitter<CollectOrderDto>();

  enumName = enumName_mi_pspc;

  get personId(): string {
    return this.collectOrderForm.value.personId as string;
  }

  collectOrderForm = this._cfb.group<CollectOrderDtoFormGroup>({
    amount: ['', Validators.required, 'Monto'],
    personId: ['', Validators.required],
    person: [null, Validators.required, 'Persona'],
    externalId: ['', , 'Id externo'],
  });

  onPersonSelected($event: any) {
    this.collectOrderForm.patchValue({ personId: $event });
    this.collectOrderDto.person = this.collectOrderForm.value.person as PersonDto;
  }
}
