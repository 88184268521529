import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { AccreditationsByAccountVoucherDto } from '../../../../api/dtos/AccreditationsByAccountVoucherDto';
import { AccreditationsByAccountDto } from '../../../../api/dtos/AccreditationsByAccountDto';
import { ApiAdminAccreditationsByAccount } from '../../../../api/endpoints/ApiAdminAccreditationsByAccount';

@Component({
  selector: 'mf-accreditations-by-account-voucher-create',
  templateUrl: './accreditations-by-account-voucher-create.component.html',
  styleUrls: ['./accreditations-by-account-voucher-create.component.scss'],
})
export class AccreditationsByAccountVoucherCreateComponent {
  constructor(private _apiAdminAccreditationsByAccount: ApiAdminAccreditationsByAccount, private _modalService: ModalService) {
    this.accreditationsByAccountDto = this._modalService.openData.accreditationsByAccountDto;
    this.accreditationsByAccountVoucherDto = new AccreditationsByAccountVoucherDto();
    this.accreditationsByAccountVoucherDto.accreditationId = this.accreditationsByAccountDto.accreditationId;
    this.accreditationsByAccountVoucherDto.accreditationsByAccountId = this.accreditationsByAccountDto.id;
  }

  accreditationsByAccountDto: AccreditationsByAccountDto;
  accreditationsByAccountVoucherDto: AccreditationsByAccountVoucherDto;

  async submit(accreditationsByAccountVoucherDto: AccreditationsByAccountVoucherDto) {
    await this._apiAdminAccreditationsByAccount.uploadVoucher(accreditationsByAccountVoucherDto);
    this._modalService.closeAsSuccess('El comprobante se cargó con éxito');
  }
}
