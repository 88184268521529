@if (menuGroupToShow.showTitle) {
<div class="title">
  {{ menuGroup.title }}
</div>
} @for (menuItem of menuGroupToShow.items; track menuItem.label) {
<mf-menu-item
  (click)="navigate(menuItem.path)"
  [menuItem]="menuItem">
</mf-menu-item>
}
