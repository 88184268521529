import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { NaturalPersonDto, NaturalPersonDtoFormGroup } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto';
import { PersonType } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';

@Component({
  selector: 'mf-natural-person',
  templateUrl: './natural-person.component.html',
  styleUrls: ['./natural-person.component.scss'],
})
export class NaturalPersonComponent {
  constructor(private _cfb: CustomFormBuilder) {}

  @Input()
  naturalPersonDto: NaturalPersonDto;

  @Output()
  save = new EventEmitter<NaturalPersonDto>();

  personType = PersonType;

  naturalPersonForm = this._cfb.group<NaturalPersonDtoFormGroup>({
    firstName: ['', Validators.required, 'Nombre'],
    lastName: ['', Validators.required, 'Apellido'],
    idDocument: [null, Validators.required],
    email: ['', Validators.required, 'Email'],
    address: [null, Validators.required],
  });

  ngOnInit(): void {
    this.naturalPersonForm.patchValue(this.naturalPersonDto);
  }
}
