<ng-container [formGroup]="templateForm">
  <mf-column [md]="1">
    <mf-field-select
      formControlName="paperSize"
      [enumName]="enumName.PaperSize"
      [enumNameModule]="moduleName.templates" />
  </mf-column>
  <mf-column [md]="1">
    <mf-field-select
      formControlName="orientation"
      [enumName]="enumName.PaperOrientation"
      [enumNameModule]="moduleName.templates" />
  </mf-column>
  <mf-column [md]="1">
    <mf-field-toggle formControlName="displayPageNumber" />
  </mf-column>
</ng-container>
