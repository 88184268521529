<mf-form
  [formGroup]="companyForm"
  [entity]="companyDto"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="commercialName" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="email" />
  </mf-column>
  <mf-id-document
    [personType]="personType.Company"
    formControlName="idDocument" />
  <mf-address formControlName="address" />
</mf-form>
