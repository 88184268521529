// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { TemplateTypeDto } from './TemplateTypeDto';

export class TemplateDto  {
  id: string = '';
  inactive: boolean = false;
  body: string = '';
  typeId: string | null = null;
  type: TemplateTypeDto;
  isTypeDefault: boolean = false;
  styles: string = '';
  name: string = '';
  group: number | null = null;
}

export interface TemplateDtoFormGroup  {
  id?: any;
  inactive?: any;
  body?: any;
  typeId?: any;
  type?: any;
  isTypeDefault?: any;
  styles?: any;
  name?: any;
  group?: any;
}
