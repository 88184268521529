export const AppRoute = {
  accreditations: 'accreditations',
  accreditationsByAccount: 'accreditations-by-account',
  banks: 'banks',
  bankBranches: 'bank-branches',
  bankAccounts: 'bank-accounts',
  bankAccountsVerification: 'bank-accounts-verification',
  bcuReport: 'bcu-report',
  clients: 'clients',
  conciliations: 'conciliations',
  configuration: 'configuration',
  development: {
    logging: {
      list: 'development/logging',
      config: 'development/logging/config',
    },
    template: {
      list: 'development/templates',
      generic: {
        create: 'development/templates/generic/create',
        edit: 'development/templates/generic/edit/:id',
      },
      email: { create: 'development/templates/email/create', edit: 'development/templates/email/edit/:id' },
      pdf: { create: 'development/templates/pdf/create', edit: 'development/templates/pdf/edit/:id' },
    },
  },
  gatewayBankAccounts: 'gateway-bank-accounts',
  gateways: 'gateways',
  issue: 'issue',
  login: {
    signIn: 'login',
    register: 'register',
    googleCallback: 'google-callback',
  },
  notifications: 'notification',
  orders: {
    collect: {
      list: 'orders/collect-orders',
      create: 'orders/collect-orders/create',
      edit: 'orders/collect-orders/edit',
    },
    payment: {
      list: 'orders/payment-orders',
      create: 'orders/payment-orders/create',
      edit: 'orders/payment-orders/edit',
    },
  },
  persons: {
    list: 'persons',
    naturalPersons: {
      list: 'persons/natural-persons',
      create: 'persons/natural-persons/create',
      edit: 'persons/natural-persons/edit',
    },
    companies: {
      list: 'persons/company',
      create: 'persons/company/create',
      edit: 'persons/company/edit',
    },
  },
  operations: 'operation',
  paymentTransactions: {
    transfers: {
      list: 'transfers',
    },
    bankMovements: {
      list: 'bankMovements',
    },
  },
  users: 'users',
  valueLists: 'value-lists',
};
