import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { OrderDto } from '../../../../api/dtos/OrderDto';
import { OrderFiltersDto } from '../../../../api/dtos/OrderFiltersDto';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { TrackGridComponent } from '../../../components/track-grid/track-grid.component';
import { ApiAdminOrderGeneric } from '../../../../api/endpoints/ApiAdminOrderGeneric';
import { NavigationService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { AppRoute } from '../../../app.routes.path';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';

@Component({
  selector: 'mf-order-grid',
  templateUrl: './order-grid.component.html',
  styleUrls: ['./order-grid.component.scss'],
})
export class OrderGridComponent implements OnInit {
  constructor(
    public _apiAdminOrder: ApiAdminOrderGeneric,
    private _modalService: ModalService,
    private _navigationService: NavigationService
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() hideFilters = false;
  @Input() showCreateButton = false;
  @Input() accreditationsByAccountId: string;

  filters: OrderFiltersDto;

  ngOnInit(): void {
    this.filters = new OrderFiltersDto();
    if (this.accreditationsByAccountId) {
      this.filters.accByAccountId = this.accreditationsByAccountId;
    }
  }

  columns: IGridColumn<OrderDto>[] = [
    { field: nameof<OrderDto>((o) => o.paymentTransactionId), header: 'Transferecia', width: 10 },
    {
      field: nameof<OrderDto>((o) => o.status),
      header: 'Estado',
      width: 10,

      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.OrderStatus,
      },
      filter: {
        propertyName: nameof<OrderFiltersDto>((o) => o.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.OrderStatus,
      },
    },
    { field: nameof<OrderDto>((o) => o.destinationBankAccount.bank.name), header: 'Banco', width: 10 },
    { field: nameof<OrderDto>((o) => o.destinationBankAccount.accountNumber), header: 'Nro cuenta', width: 10, align: 'right' },
    {
      field: nameof<OrderDto>((o) => o.destinationBankAccount.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<OrderFiltersDto>((o) => o.currency),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    { field: nameof<OrderDto>((o) => o.amount), header: 'Monto', width: 10, align: 'right' },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver tracking',
      icon: Icons.search,
      onClick: (orderDto: OrderDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: orderDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Ver transferencia',
      icon: Icons.search,
      onClick: (orderDto: OrderDto) => {
        this._navigationService.navigate(AppRoute.paymentTransactions.transfers.list, undefined, {
          transferId: orderDto.paymentTransactionId,
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<OrderFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminOrder.getPaged(search, httpApiRequestOptions);
}
