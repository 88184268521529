// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { SearchDto, SearchDtoFormGroup } from './SearchDto';

export class PagedSearchDto<TFilter> extends SearchDto<TFilter> {
  page: number | null = null;
  pageSize: number | null = null;
  pageContinuationToken: string = '';
}

export interface PagedSearchDtoFormGroup<TFilter> extends SearchDtoFormGroup<TFilter> {
  page?: any;
  pageSize?: any;
  pageContinuationToken?: any;
}
