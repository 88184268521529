import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class LayoutService {
  //TODO: No se debe hacer referencia a "MiPspc" desde el shared
  private STORAGE_ITEM_NAME_DARK_MODE = 'mi-pspc-dark-mode';

  private _isDarkMode = false;
  private toggleSidenav: Subject<void> = new Subject<void>();

  public spinnerTokens: number[] = [];
  public globalSpinnerIsVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private setThemeMode() {
    if (this._isDarkMode) {
      localStorage.setItem(this.STORAGE_ITEM_NAME_DARK_MODE, 'true');
      window.document.body.classList.add('dark-mode');
    } else {
      localStorage.removeItem(this.STORAGE_ITEM_NAME_DARK_MODE);
      window.document.body.classList.remove('dark-mode');
    }
  }

  constructor() {
    if (localStorage.getItem(this.STORAGE_ITEM_NAME_DARK_MODE)) {
      this._isDarkMode = true;
      this.setThemeMode();
    }
  }

  getToggleSidenav(): Observable<void> {
    return this.toggleSidenav.asObservable();
  }

  executeToggleSidenav(): void {
    this.toggleSidenav.next();
  }

  get isDarkMode(): boolean {
    return this._isDarkMode;
  }

  toggleDarkMode() {
    this._isDarkMode = !this._isDarkMode;
    this.setThemeMode();
  }

  globalSpinnerShow(): number {
    this.globalSpinnerIsVisible.next(true);
    const lastToken = this.spinnerTokens.length > 0 ? this.spinnerTokens[this.spinnerTokens.length - 1] : 0;
    return this.spinnerTokens.push(lastToken + 1);
  }

  globalSpinnerHide(token: number) {
    const index = this.spinnerTokens.indexOf(token);
    if (index > -1) {
      this.spinnerTokens.splice(index, 1);
    }
    if (this.spinnerTokens.length === 0) {
      this.globalSpinnerIsVisible.next(false);
    }
  }
}
