<mf-auto-complete
  [disabled]="!bankId"
  [label]="label"
  [endpointSuggest]="endpointSuggest"
  [ngModel]="value?.displayText ?? ''"
  [filters]="filters"
  [orderBy]="orderBy"
  [wrappedNgControl]="ngControl"
  [autoCompleteMaxItems]="autoCompleteMaxItems"
  (selectedItemChange)="selectedItemChange($event)" />
