import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { BankAccountDto } from '../../../../api/dtos/BankAccountDto';
import { ApiAdminBankAccount } from '../../../../api/endpoints/ApiAdminBankAccount';

@Component({
  selector: 'mf-bank-account-edit',
  templateUrl: './bank-account-edit.component.html',
  styleUrls: ['./bank-account-edit.component.scss'],
})
export class BankAccountEditComponent {
  constructor(private _apiAdminBankAccount: ApiAdminBankAccount, private _modalService: ModalService) {
    this.load();
  }

  bankAccountDto: BankAccountDto;

  async load() {
    this.bankAccountDto = await this._apiAdminBankAccount.getById(this._modalService.openData);
  }

  async save(bankAccountDto: BankAccountDto) {
    bankAccountDto.isVerified = false;
    await this._apiAdminBankAccount.edit(bankAccountDto);
    this._modalService.closeAsSuccess('Cuenta editada correctamente');
  }

  async verify() {
    this.bankAccountDto.isVerified = true;
    await this._apiAdminBankAccount.validate(this.bankAccountDto);
    this._modalService.closeAsSuccess('Titular verificado correctamente');
  }
}
