import { Component } from '@angular/core';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'mf-yes-or-no-modal',
  templateUrl: './yes-or-no-modal.component.html',
  styleUrls: ['./yes-or-no-modal.component.scss'],
})
export class YesOrNoModalComponent {
  constructor(private _modalService: ModalService) {
    this.message = this._modalService.openData;
  }

  message: string;

  closeAsSuccess() {
    this._modalService.closeAsSuccess();
  }

  closeAsCancel() {
    this._modalService.closeAsCancel();
  }
}
