// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { BcuReportDto } from "../dtos/BcuReportDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { BcuReportFiltersDto } from "../dtos/BcuReportFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminBcuReport {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getPaged(
    filters: PagedSearchDto<BcuReportFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<BcuReportDto>> {
    return this._httpApiClient.post("/admin/bcu-report/paged", filters, httpApiRequestOptions);
  }

  create(
    bcuReportDto: BcuReportDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BcuReportDto> {
    return this._httpApiClient.post("/admin/bcu-report", bcuReportDto, httpApiRequestOptions);
  }

}
