// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { BankDto } from './BankDto';

export class BankBranchDto extends BaseEntityDto {
  bankId: string = '';
  bank: BankDto;
  name: string = '';
  branchCode: string = '';
}

export interface BankBranchDtoFormGroup extends BaseEntityDtoFormGroup {
  bankId?: any;
  bank?: any;
  name?: any;
  branchCode?: any;
}
