/* eslint-disable indent */
import { Directive, ElementRef, Input, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { FieldErrorStateMatcher } from '../../field-error-state-matcher';
import { LabeledFormControl } from '../../validation/form-builder/labeled-form-control';

@Directive()
/**
 * Implements control value accessor interface and allows a componente to be
 * bind to a model using [ngModel]
 */
export abstract class FieldBaseComponent<T> implements ControlValueAccessor, Validator {
  constructor(@Optional() @Self() public ngControl: NgControl, protected elRef: ElementRef) {
    if (this.ngControl == null) {
      throw new Error(
        `Can't find NgControl. ControlType: ${this.controlType}. ${
          this.formControlName ? this.formControlName : 'Control is missing formControlName property'
        }`
      );
    }
    this.ngControl.valueAccessor = this;
    this.fieldErrorStateMatcher = new FieldErrorStateMatcher(this.ngControl);
  }

  /**
   * Fix: Angular invoking twice to writeValue.
   */
  private flagInitialized = false;
  private _value: T;
  public fieldErrorStateMatcher: FieldErrorStateMatcher;
  public onTouched: () => void;

  @Input()
  label?: string = '';
  @Input()
  hint: string;
  @Input()
  formControlName: string;
  @Input()
  readOnly = false;
  @Input()
  wrappedNgControl: NgControl;

  public get value(): T {
    return this._value;
  }

  get errorNgControl(): NgControl {
    return this.wrappedNgControl ?? this.ngControl;
  }

  get errorStateMatcher() {
    return new FieldErrorStateMatcher(this.errorNgControl);
  }

  get getLabel(): string {
    const labeledControl = this.errorNgControl.control as LabeledFormControl;
    return this.label !== undefined && this.label !== '' ? this.label : labeledControl.label;
  }

  public set value(val: T) {
    this._value = val;
    if (this.propagateChange != null) {
      this.propagateChange(this.value);
    }
    this.onValueAssigned(val);
  }

  get controlType(): string {
    throw Error('Must override controlType');
  }

  /**
   * Allows the component to assign a value without trigger the propagate changes.
   */
  protected assignValue(val: T): void {
    this._value = val;
  }

  /**
   * To override at implementation. Invoked after assign to the 'value' property and call propagateChange.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onValueAssigned(val: T): void {
    // Allow override
  }

  /**
   * Listener por defecto.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected propagateChange = (_: any) => {
    // Allow override
  };

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
    // Allow override
  }

  public writeValue(val: any): void {
    if (val == null && !this.flagInitialized) {
      this.flagInitialized = true;
      return;
    }
    this._value = val;
    this.onWriteValue(val);
  }

  /**
   * To override. Invoked after writeValue is invoked.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onWriteValue(val: T): void {
    // Allow override
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    return null;
  }

  get validationMessage(): string {
    const ngControl = this.errorNgControl;

    if (ngControl.errors == null) {
      return '';
    }

    if (ngControl.errors.required) {
      return 'Campo requerido';
    }

    if (ngControl.errors.minlength) {
      return 'El largo mínimo es de ' + ngControl.errors.minlength.requiredLength;
    }

    if (ngControl.errors.maxlength) {
      return 'El largo máximo es de ' + ngControl.errors.maxlength.requiredLength;
    }

    if (ngControl.errors.email) {
      return 'Correo inválido';
    }

    if (ngControl.errors[Object.keys(ngControl.errors)[0]].displayMessage) {
      return ngControl.errors[Object.keys(ngControl.errors)[0]].displayMessage;
    }

    return 'Campo inválido';
  }
}
