<mf-form
  [acceptButtonHide]="true"
  [formGroup]="bankAccountForm"
  [entity]="bankAccountDto">
  <mf-row>
    <mf-column>
      <mf-autocomplete-bank
        formControlName="bank"
        (itemIdChanged)="bankAccountForm.patchValue({ bankId: $event })" />
    </mf-column>

    <mf-column>
      <mf-autocomplete-bank-branch
        formControlName="bankBranch"
        [bankId]="bankId"
        [autoCompleteMaxItems]="100"
        (itemIdChanged)="bankAccountForm.patchValue({ bankBranchId: $event })" />
    </mf-column>

    <mf-column>
      <mf-field-select
        formControlName="accountType"
        [enumName]="enumName.AccountType" />
    </mf-column>
  </mf-row>

  <mf-row>
    <mf-column>
      <mf-field-select
        formControlName="currency"
        [enumName]="enumName.Currency" />
    </mf-column>

    <mf-column>
      <mf-field-text formControlName="accountNumber" />
    </mf-column>

    <mf-column>
      <mf-field-check formControlName="isDefault" />
    </mf-column>
  </mf-row>

  <mf-row>
    <mf-column>
      <mf-autocomplete-person
        formControlName="person"
        [personType]="personType.NaturalPerson"
        (itemIdChanged)="setPersonValue($event)" />
    </mf-column>

    <mf-column>
      <mf-field-text formControlName="holderName" />
    </mf-column>

    <mf-column>
      <mf-icon
        [icon]="bankAccountForm.value.isVerified ? icons.check : icons.accreditations"
        size="small">
      </mf-icon>
      {{ bankAccountForm.value.isVerified ? 'Verificada' : 'No verificada' }}
    </mf-column>
  </mf-row>

  <mf-row>
    <mf-holder-verification
      #holderVerificationComponent
      (validate)="validationInfoDto = $event">
    </mf-holder-verification>
  </mf-row>

  <mf-row align="right">
    @if (!hideVerifyButton) {
    <mf-button-secondary
      text="Verificar"
      (click)="verifyAccount()" />
    }
    <mf-button-secondary
      text="Consultar banco"
      (click)="verifyHolder()" />
    <mf-button
      text="Guardar"
      (click)="validateAndSubmit()" />
  </mf-row>
</mf-form>
