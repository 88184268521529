<mf-row>
  <mf-field-select
    [label]="gridFilter.header"
    [enumName]="enumName.FilterDateRangeType"
    [enumNameModule]="moduleName.searching"
    [(ngModel)]="type"
    (ngModelChange)="modelChange()" />
</mf-row>
@if (value?.type == filterDateRangeType.Range) {
<mf-row>
  <mf-field-date-range
    label="Rango"
    [(ngModel)]="value"
    (ngModelChange)="modelChange()"></mf-field-date-range>
</mf-row>
} @if (value?.type == filterDateRangeType.Date) {
<mf-row>
  <mf-field-date
    label="Fecha"
    [(ngModel)]="specificDate"
    (ngModelChange)="specificDateChange()"></mf-field-date>
</mf-row>
}
