<button
  mat-icon-button
  [title]="tooltip"
  [disabled]="disabled"
  [style.width.px]="size"
  [style.height.px]="size"
  [style.padding.px]="0">
  <mf-icon
    [icon]="icon"
    [size]="size"></mf-icon>
</button>
