<mat-form-field [style.width.%]="100">
  <mat-label> {{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }} </mat-label>
  <mat-select
    [(ngModel)]="value"
    [errorStateMatcher]="errorStateMatcher"
    [disabled]="readOnly">
    @if (!hideEmptyOption) {
    <mat-option [value]="">
      {{ emptyOptionLabel }}
    </mat-option>
    } @for (option of options; track option.value){
    <mat-option [value]="option.value">
      {{ option.description }}
    </mat-option>
    }
  </mat-select>
  <mat-hint>{{ showEmptySelectionAsHint && value == null ? emptyOptionLabel : hint }}</mat-hint>
  <mat-error>{{ validationMessage }}</mat-error>
</mat-form-field>
