<mf-form
  [formGroup]="valueListForm"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-select
      formControlName="valueListType"
      [enumName]="enumNameMiPspc.ValueListType" />
  </mf-column>

  <mf-column>
    <mf-field-select
      formControlName="country"
      [enumName]="enumNameMiShared.Country"
      [enumNameModule]="enumNameModule" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="description" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="color" />
  </mf-column>
</mf-form>
