import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { BankBranchDto } from '../../../../api/dtos/BankBranchDto';
import { ApiAdminBankBranch } from '../../../../api/endpoints/ApiAdminBankBranch';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Component({
  selector: 'mf-bank-branch-create',
  templateUrl: './bank-branch-create.component.html',
  styleUrls: ['./bank-branch-create.component.scss'],
})
export class BankBranchCreateComponent {
  constructor(private _apiAdminBankBranch: ApiAdminBankBranch, private _modalService: ModalService) {}

  bankBranchDto = new BankBranchDto();

  async save(bankBranchDto: BankBranchDto) {
    bankBranchDto.id = GUID_EMPTY;
    await this._apiAdminBankBranch.create(bankBranchDto);
    this._modalService.closeAsSuccess('La sucursal se creó con éxito');
  }
}
