// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { TemplateDto, TemplateDtoFormGroup } from './TemplateDto';
import { TemplateGenericType } from '../enums/TemplateGenericType';

export class TemplateGenericDto extends TemplateDto {
  restrictTo: number | null = null;
  genericType: TemplateGenericType;
  code: string = '';
}

export interface TemplateGenericDtoFormGroup extends TemplateDtoFormGroup {
  restrictTo?: any;
  genericType?: any;
  code?: any;
}
