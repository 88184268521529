import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FieldBaseComponent } from './field-base.component';

@Directive()
export abstract class FieldMatBaseComponent<T> extends FieldBaseComponent<T> implements MatFormFieldControl<T>, OnDestroy {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef) {
    super(ngControl, elRef);
  }

  static nextId = 0;
  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  inputHasFocus = false;
  stateChanges = new Subject<void>();
  focused = false;

  get errorState() {
    return this.ngControl.errors !== null && !this.ngControl.pristine;
  }

  @HostBinding()
  id = `${this.controlType}${FieldMatBaseComponent.nextId++}`;
  @HostBinding('attr.aria-describedby')
  describedBy = '';
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeHolder: string) {
    this._placeholder = placeHolder;
    this.stateChanges.next();
  }

  get empty() {
    return this.value == null;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  autofocus = false;

  protected override onValueAssigned(_val: T): void {
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    //(this as any)['_focusMonitor'].stopMonitoring(this.elRef.nativeElement);
  }
}
