import { Component, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { CollectOrderDto } from '../../../api/dtos/CollectOrderDto';
import { CollectOrderFiltersDto } from '../../../api/dtos/CollectOrderFiltersDto';
import { OrderFiltersDto } from '../../../api/dtos/OrderFiltersDto';
import { ApiAdminCollectOrder } from '../../../api/endpoints/ApiAdminCollectOrder';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';

@Component({
  selector: 'mf-autocomplete-collect-order',
  templateUrl: './autocomplete-collect-order.component.html',
  styleUrls: ['./autocomplete-collect-order.component.scss'],
})
export class AutocompleteCollectOrderComponent extends AutoCompleteBaseEntityComponent<CollectOrderDto | null, CollectOrderFiltersDto> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef, private _apiAdminCollectOrder: ApiAdminCollectOrder) {
    super(ngControl, elRef);
  }

  override defaultOrderBy = nameof<CollectOrderDto>((c) => c.amount);

  override endpointSuggest = (search: SuggestSearchDto<CollectOrderFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminCollectOrder.suggest(search, httpApiRequestOptions);
}
