<mf-form
  [formGroup]="clientForm"
  [entity]="clientDto"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="endpointUrl" />
  </mf-column>
  <mf-column>
    <mf-autocomplete-company
      formControlName="company"
      (itemIdChanged)="clientForm.patchValue({ companyId: $event })" />
    <mf-field-text formControlName="apiKey" />
  </mf-column>
</mf-form>
