<mf-page title="Cuentas de pasarela">
  <mf-row-buttons>
    <mf-button
      text="Actualizar Saldos"
      (click)="updateGatewayBalance()" />
  </mf-row-buttons>
  <mf-grid
    #grid
    actionButtonText="Nueva cuenta de pasarela"
    [apiEndpoint]="apiEndpoint"
    [columns]="columns"
    [menuItems]="menuItems"
    (actionButtonClick)="openNewGatewayBankAccountPopup()" />
</mf-page>
