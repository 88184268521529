// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { PersonFiltersDto, PersonFiltersDtoFormGroup } from './PersonFiltersDto';

export class CompanyFiltersDto extends PersonFiltersDto {
  name: string = '';
  commercialName: string = '';
}

export interface CompanyFiltersDtoFormGroup extends PersonFiltersDtoFormGroup {
  name?: any;
  commercialName?: any;
}
