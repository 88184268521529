import { Component } from '@angular/core';
import { TemplateDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplatePdfDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplatePdfDto';
import { ApiTemplatePdf } from '../../../../api/endpoints/ApiTemplatePdf';
import { TemplateGroup } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/enums/TemplateGroup';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { ApiTemplateType } from '../../../../api/endpoints/ApiTemplateType';
import { PaperSize } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/enums/PaperSize';
import { PaperOrientation } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/enums/PaperOrientation';

@Component({
  selector: 'mf-template-pdf-create',
  templateUrl: './template-pdf-create.component.html',
  styleUrls: ['./template-pdf-create.component.scss'],
})
export class TemplatePdfCreateComponent {
  constructor(private _apiPdfTemplate: ApiTemplatePdf, private _modalService: ModalService, private apiTemplateType: ApiTemplateType) {
    this.load();
  }

  templatePdfDto: TemplatePdfDto;

  async load() {
    //const type = await this.apiTemplateType.getById('bb14fac5-4af7-4680-38b6-08dc15c8fb56');
    this.templatePdfDto = new TemplatePdfDto();
    this.templatePdfDto.id = GUID_EMPTY;
    this.templatePdfDto.group = TemplateGroup.Pdf;
    // this.templatePdfDto.typeId = type.id;
    // this.templatePdfDto.type = type;
    this.templatePdfDto.paperSize = PaperSize.A4;
    this.templatePdfDto.orientation = PaperOrientation.Portrait;
  }

  async save(templateDto: TemplateDto) {
    await this._apiPdfTemplate.create(templateDto as TemplatePdfDto);
    this._modalService.closeAsSuccess('La plantilla de correo se creó con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
