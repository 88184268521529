import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { TemplateDto, TemplateDtoFormGroup } from '../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplateGroup } from './enums/TemplateGroup';
import { enumName_templates } from './enums/_enumName.templates';
import { moduleName } from '../../../../ts-shared/src/lib/module-names';
import { TemplateGenericDto } from './dtos/TemplateGenericDto';
import { TemplateGenericType } from './enums/TemplateGenericType';
import { TemplateTypeDto } from './dtos/TemplateTypeDto';

@Component({
  selector: 'mf-template-header-info',
  templateUrl: './template-header-info.component.html',
  styleUrls: ['./template-header-info.component.scss'],
})
export class TemplateHeaderInfoComponent {
  constructor(private _formGroup: FormGroupDirective) {}

  templateForm: FormGroup<TemplateDtoFormGroup>;
  templateGroup = TemplateGroup;
  enumName = enumName_templates;
  templateGenericType = TemplateGenericType;
  moduleName = moduleName;

  @Output()
  templateTypeChanged = new EventEmitter<string | null>();

  @Output()
  genericTypeChanged = new EventEmitter<number | null>();

  get templateDto() {
    return this.templateForm.value as TemplateDto;
  }

  get templateGenericDto(): TemplateGenericDto | null {
    return this.templateDto.group == TemplateGroup.Generic ? (this.templateForm.value as TemplateGenericDto) : null;
  }

  genericTypeChange() {
    this.genericTypeChanged.emit((this.templateDto as TemplateGenericDto).genericType);
  }

  ngOnInit(): void {
    this.templateForm = this._formGroup.control as FormGroup<TemplateDtoFormGroup>;
  }
}
