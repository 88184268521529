@if (expandable) {<mf-button-icon
  [icon]="expandIcon"
  title="{{ templateDesignFieldDto.fieldType | translate : enumNameTemplates.TemplateDesignFieldType : moduleName.templates }}"
  (click)="templateDesignFieldDto.expanded = !templateDesignFieldDto.expanded" />
} @else {
<mf-icon
  [icon]="icon"
  title="{{ templateDesignFieldDto.fieldType | translate : enumNameTemplates.TemplateDesignFieldType : moduleName.templates }}" />
}
<div class="field-name">{{ templateDesignFieldDto.name }}</div>
<div class="field-code">%%{{ templateDesignFieldDto.code }}%%</div>
@if (templateDesignFieldDto.expanded) {
<mf-template-editor-fields [fields]="templateDesignFieldDto.childFields" />
}
