// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { TemplateGroup } from '../enums/TemplateGroup';

export class TemplateDesignTestDto  {
  previewJsonData: string = '';
  templateGroup: TemplateGroup;
}

export interface TemplateDesignTestDtoFormGroup  {
  previewJsonData?: any;
  templateGroup?: any;
}
