// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { AppUserDto } from './AppUserDto';

export class BcuReportFiltersDto extends FiltersDto {
  appUserId: string | null = null;
  appUser: AppUserDto;
  communicationCode: number | null = null;
  bankCode: string = '';
  startDate: Date | null = null;
  endDate: Date | null = null;
  createdDate: Date | null = null;
  isSent: boolean | null = null;
  xmlFilePath: string = '';
}

export interface BcuReportFiltersDtoFormGroup extends FiltersDtoFormGroup {
  appUserId?: any;
  appUser?: any;
  communicationCode?: any;
  bankCode?: any;
  startDate?: any;
  endDate?: any;
  createdDate?: any;
  isSent?: any;
  xmlFilePath?: any;
}
