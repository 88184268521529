{
	"_locale": "EsUy",
	"FilterDateRangeType": {
		"7": "Última semana",
		"10": "Último año",
		"12": "Fecha",
		"2": "Ayer",
		"9": "Último cuarto",
		"4": "Este mes",
		"11": "Rango",
		"1": "Hoy",
		"5": "Este cuarto",
		"6": "Este año",
		"3": "Esta semana",
		"8": "Último mes"
	}
}
