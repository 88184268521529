<mat-form-field [style.width.%]="100">
  <mat-label> {{ getLabel }}{{ ngControl.errors?.required != undefined ? '*' : '' }} </mat-label>
  <input
    matInput
    title="{{ textValue }}"
    inputmode="numeric"
    [errorStateMatcher]="errorStateMatcher"
    [disabled]="readOnly"
    [(ngModel)]="textValue"
    (focus)="onFocus($event)"
    (blur)="onBlur()"
    (keydown)="onKeyDown($event)"
    (keydown.enter)="onEnter($event)"
    (paste)="onPaste($event)" />
  <mat-hint>{{ hint }}</mat-hint>
  @if (validationMessage){
  <mat-error>
    {{ validationMessage }}
  </mat-error>
  }
</mat-form-field>
