import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { LoginService } from '../../../../../libs/utils/auth-shared/src/lib/services/login.service';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  constructor(private router: Router, private _loginService: LoginService) {}

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this._loginService.jwtToken) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } }).then();
      return false;
    }
    return true;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
};
