import { Component } from '@angular/core';
import { TemplateDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplatePdfDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplatePdfDto';
import { ApiTemplatePdf } from '../../../../api/endpoints/ApiTemplatePdf';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-template-pdf-edit',
  templateUrl: './template-pdf-edit.component.html',
  styleUrls: ['./template-pdf-edit.component.scss'],
})
export class TemplatePdfEditComponent {
  constructor(private _apiPdfTemplate: ApiTemplatePdf, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  templatePdfDto: TemplatePdfDto;

  async load(id: string) {
    this.templatePdfDto = await this._apiPdfTemplate.getById(id);
  }

  async save(templateDto: TemplateDto) {
    await this._apiPdfTemplate.edit(templateDto as TemplatePdfDto);
    this._modalService.closeAsSuccess('La plantilla de correo se editó con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
