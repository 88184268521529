import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { NotificationDto } from '../../../api/dtos/NotificationDto';
import { ApiAdminNotification } from '../../../api/endpoints/ApiAdminNotification';
@Component({
  selector: 'mf-notification-create',
  templateUrl: './notification-create.component.html',
  styleUrls: ['./notification-create.component.scss'],
})
export class NotificationCreateComponent {
  constructor(private _apiAdminNotification: ApiAdminNotification, private _modalService: ModalService) {}

  async submit(notificationDto: NotificationDto) {
    await this._apiAdminNotification.create(notificationDto);
    this._modalService.closeAsSuccess('La notificación se creo con éxito');
  }
}
