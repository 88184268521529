import { Component } from '@angular/core';
import { LoginService } from '../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { HeaderMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/menu/header-menu-item';
import { LayoutService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/layout.service';
import { NavigationService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { ToolBarQuickAccess } from '../../../../../../libs/utils/ng-shared-components/src/lib/tool-bar/tool-bar.component';
import { AppRoute } from '../../app.routes.path';

@Component({
  selector: 'mf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private _loginService: LoginService, private _navigationService: NavigationService, private _layoutService: LayoutService) {
    this.username = _loginService.loggedUsername;
    this.clientName = _loginService.loggedClientName;
  }

  logoUrl = '/assets/img/Mi_Finanzas.png';
  username: string;
  clientName: string;

  quickAccessList: ToolBarQuickAccess[] = [
    {
      label: 'Operaciones',
      url: AppRoute.operations,
    },
    {
      label: 'Cobros',
      url: AppRoute.orders.collect.list,
    },
    {
      label: 'Pagos',
      url: AppRoute.orders.payment.list,
    },
    {
      label: 'Acreditaciones',
      url: AppRoute.accreditations,
    },
    {
      label: 'Transferencias',
      url: AppRoute.paymentTransactions.transfers.list,
    },
    {
      label: 'Movimientos bancarios',
      url: AppRoute.paymentTransactions.bankMovements.list,
    },
  ];

  menuItems: HeaderMenuItem[] = [
    {
      icon: Icons.accountCircle,
      label: 'Mi perfil',
      onClick: () => {
        this._navigationService.navigate(AppRoute.clients);
      },
    },
    {
      icon: Icons.accountCircle,
      isToggle: true,
      toggleValue: this._layoutService.isDarkMode,
      label: 'Modo oscuro',
      onClick: () => {
        this._layoutService.toggleDarkMode();
      },
    },
    {
      icon: Icons.logout,
      label: 'Salir',
      onClick: () => {
        this._loginService.logout();
        window.location.reload();
      },
    },
  ];
}
