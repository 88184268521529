<mf-page title="Configuración de logs">
  <mf-form
    [formGroup]="sqlQueryIsEnabledForm"
    [acceptButtonHide]="true">
    <mf-field-toggle
      formControlName="sqlQueryIsEnabled"
      (click)="sqlQueryIsEnabledClick()" />
  </mf-form>

  <mf-row-separator />

  <mf-form
    [formGroup]="loggingCategoryConfigForm"
    [acceptButtonHide]="true">
    @for (loggingConfigCategory of loggingConfigCategories; track loggingConfigCategory.category) {
    <mf-column [md]="2">
      <mf-field-select
        [label]="loggingConfigCategory.shortName"
        [formControlName]="loggingConfigCategory.category"
        [options]="levelOptions"
        [showEmptySelectionAsHint]="true"
        emptyOptionLabel="Por defecto"
        (ngModelChange)="setCategoryLevel(loggingConfigCategory.category, $event)">
      </mf-field-select>
    </mf-column>
    }
  </mf-form>

  <mf-row-buttons>
    <mf-button
      text="Test logs"
      (click)="testLogClick()" />
    <mf-button
      text="Test excepción"
      (click)="testException()" />
  </mf-row-buttons>
</mf-page>
