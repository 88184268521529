import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'mf-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss'],
})
export class ButtonIconComponent {
  @Input() icon: string;
  @Input() tooltip: string = '';
  @Input() disabled: boolean = false;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Output() click = new EventEmitter<any>();

  get styleSize(): number {
    return this.size === 'small' ? 16 : this.size === 'medium' ? 24 : 32;
  }

  get stylePadding(): number {
    return this.size === 'small' ? 0 : this.size === 'medium' ? 8 : 12;
  }

  constructor(private _ref: ElementRef) {
    _ref.nativeElement.addEventListener('click', this.stopPropagation);
  }

  private stopPropagation = (event: Event) => {
    if (this.disabled) {
      event.stopImmediatePropagation();
    }
  };

  ngDestroy() {
    this._ref.nativeElement.removeEventListener('click', this.stopPropagation);
  }
}
