import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ClientDto } from '../../../api/dtos/ClientDto';
import { ApiAdminClient } from '../../../api/endpoints/ApiAdminClient';

@Component({
  selector: 'mf-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss'],
})
export class ClientEditComponent {
  constructor(private _apiAdminClient: ApiAdminClient, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  clientDto: ClientDto;

  async load(id: string) {
    this.clientDto = await this._apiAdminClient.getById(id);
  }

  async save(client: ClientDto) {
    await this._apiAdminClient.edit(client);
    this._modalService.closeAsSuccess('El cliente se editó con éxito');
  }
}
