// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { Country } from '../enums/Country';
import { IdDocumentType } from '../enums/IdDocumentType';

export class IdDocumentDto extends BaseEntityDto {
  country: Country;
  type: IdDocumentType;
  documentNumber: string = '';
}

export interface IdDocumentDtoFormGroup extends BaseEntityDtoFormGroup {
  country?: any;
  type?: any;
  documentNumber?: any;
}
