import { Component, Inject } from '@angular/core';
import { IApiTemplateEmail } from '../../api/IApiTemplateEmail';
import { TemplateEmailDto } from '../../dtos/TemplateEmailDto';
import { TemplateDto } from '../../dtos/TemplateDto';
import { nameof } from 'ts-simple-nameof';
import { ModalService } from '../../../services/modal.service';

@Component({
  selector: 'mf-template-email-edit',
  templateUrl: './template-email-edit.component.html',
  styleUrls: ['./template-email-edit.component.scss'],
})
export class TemplateEmailEditComponent {
  constructor(@Inject('IApiTemplateEmail') private _apiEmailTemplate: IApiTemplateEmail, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  templateEmailDto: TemplateEmailDto;

  async load(id: string) {
    this.templateEmailDto = await this._apiEmailTemplate.getById(id);
  }

  async save(templateDto: TemplateDto) {
    await this._apiEmailTemplate.edit(templateDto as TemplateEmailDto);
    this._modalService.closeAsSuccess('La plantilla de correo se editó con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
