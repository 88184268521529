import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-tracking-popup',
  templateUrl: './tracking-popup.component.html',
  styleUrls: ['./tracking-popup.component.scss'],
})
export class TrackingPopupComponent {
  constructor(modalService: ModalService) {
    this.id = modalService.openData;
  }

  id: string;
}
