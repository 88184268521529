import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class NavigationService {
  constructor(private _router: Router, private _location: Location, private _notificationService: NotificationService) {}

  navigate(url: string, id?: string, params?: any) {
    if (!url) {
      console.error('URL is undefined or null.');
      return;
    }
    if (id) {
      this._router.navigate([url.replace('/:id', ''), id]);
      return;
    }
    this._router.navigate([url], { queryParams: params }).then();
  }

  navigateBack(successMessage?: string) {
    if (successMessage) {
      this._notificationService.showSuccess(successMessage);
    }
    this._location.back();
  }

  navigateNewTab(url: string, params?: string[]) {
    if (!url) {
      console.error(' URL is undefined or null.');
      return;
    }

    if (params) {
      window.open(url + '?' + params.join('&'));
      return;
    }
    window.open(url);
  }
}
