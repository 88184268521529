import { Component, Optional, Self, ElementRef } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { AddressDto, AddressDtoFormGroup } from '../../../../ts-shared/src/lib/api/dtos/AddressDto';
import { enumName_mi_shared } from '../../../../ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../ts-shared/src/lib/module-names';
import { GUID_EMPTY } from '../../../../ts-shared/src/lib/utils-guid';
import { FieldMatBaseComponent } from '../fields/base/field-mat-base.component';
import { CustomFormBuilder } from '../validation/form-builder/custom-form-builder';

@Component({
  selector: 'mf-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends FieldMatBaseComponent<AddressDto> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef, private _cfb: CustomFormBuilder) {
    super(ngControl, elRef);
  }

  override get controlType(): string {
    return 'field-address';
  }

  protected override onWriteValue(val: AddressDto): void {
    this.addressForm.patchValue(val);
    this.addressForm.valueChanges.subscribe(() => {
      this.value = this.addressForm.value as AddressDto;
    });
  }

  enumName = enumName_mi_shared;
  enumNameModule = moduleName.shared;

  addressForm = this._cfb.group<AddressDtoFormGroup>({
    id: [GUID_EMPTY],
    addressLine: [null, Validators.required, 'Dirección'],
    city: [null, Validators.required, 'Ciudad'],
    country: [null, Validators.required, 'País'],
  });
}
