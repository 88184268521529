import { Component, Input } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { AccreditationsByAccountDto } from '../../../../api/dtos/AccreditationsByAccountDto';
import { ApiAdminAccreditationsByAccount } from '../../../../api/endpoints/ApiAdminAccreditationsByAccount';
import {
  AccreditationsByAccountCancelDto,
  AccreditationsByAccountCancelDtoFormGroup,
} from '../../../../api/dtos/AccreditationsByAccountCancelDto';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { Validators } from '@angular/forms';

@Component({
  selector: 'mf-accreditations-by-account-cancel',
  templateUrl: './accreditations-by-account-cancel.component.html',
  styleUrls: ['./accreditations-by-account-cancel.component.scss'],
})
export class AccreditationsByAccountCancelComponent {
  constructor(
    private _cfb: CustomFormBuilder,
    private _apiAdminAccreditationsByAccount: ApiAdminAccreditationsByAccount,
    private _modalService: ModalService
  ) {
    this.accreditationsByAccountDto = this._modalService.openData.accreditationsByAccountDto;
    this.accreditationsByAccountCancelDto = new AccreditationsByAccountCancelDto();
    this.accreditationsByAccountCancelDto.accreditationsByAccountId = this.accreditationsByAccountDto.id;
  }

  endpoint: any;
  enumName = enumName_mi_pspc;

  accreditationsByAccountDto: AccreditationsByAccountDto;
  accreditationsByAccountCancelDto: AccreditationsByAccountCancelDto;

  ngOnInit(): void {
    this.accreditationsByAccountCancelForm.patchValue(this.accreditationsByAccountCancelDto);
  }

  accreditationsByAccountCancelForm = this._cfb.group<AccreditationsByAccountCancelDtoFormGroup>({
    accreditationsByAccountId: ['', Validators.required],
    notes: ['', Validators.required, 'Notas'],
  });

  async submit(accreditationsByAccountCancelDto: AccreditationsByAccountCancelDto) {
    await this._apiAdminAccreditationsByAccount.cancel(accreditationsByAccountCancelDto);
    this._modalService.closeAsSuccess('Transferencias por cuenta canceladas');
  }
}
