import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { PaymentOrderDto, PaymentOrderDtoFormGroup } from '../../../../api/dtos/PaymentOrderDto';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { PersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';

@Component({
  selector: 'mf-payment-order',
  templateUrl: './payment-order.component.html',
  styleUrls: ['./payment-order.component.scss'],
})
export class PaymentOrderComponent {
  constructor(private _cfb: CustomFormBuilder) {}

  @Input()
  paymentOrderDto: PaymentOrderDto;

  @Output()
  save = new EventEmitter<PaymentOrderDto>();

  enumName = enumName_mi_pspc;

  get personId(): string {
    return this.paymentOrderForm.value.personId as string;
  }

  paymentOrderForm = this._cfb.group<PaymentOrderDtoFormGroup>({
    amount: ['', Validators.required, 'Monto'],
    personId: ['', Validators.required],
    person: [null, Validators.required, 'Persona'],
    destinationBankAccountId: ['', Validators.required],
    destinationBankAccount: [null, Validators.required, 'Nro. de cuenta'],
    externalId: ['', , 'Id externo'],
  });

  onPersonSelected($event: any) {
    this.paymentOrderForm.patchValue({ personId: $event });
    this.paymentOrderDto.person = this.paymentOrderForm.value.person as PersonDto;
  }
}
