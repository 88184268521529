// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { BankBranchDto } from "../dtos/BankBranchDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { BankBranchFiltersDto } from "../dtos/BankBranchFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminBankBranch {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    bankBranchDto: BankBranchDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankBranchDto> {
    return this._httpApiClient.post("/admin/bank-branch", bankBranchDto, httpApiRequestOptions);
  }

  edit(
    bankBranchDto: BankBranchDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankBranchDto> {
    return this._httpApiClient.put("/admin/bank-branch", bankBranchDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankBranchDto> {
    return this._httpApiClient.get("/admin/bank-branch/" + id, httpApiRequestOptions);
  }

  getByBankSwiftCodeAndBranchCode(
    bankSwiftCode: string,
    
    branchCode: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankBranchDto> {
    return this._httpApiClient.get("/admin/bank-branch/by-bank-swift-code-and-branch-code?" + 
      (bankSwiftCode ? "&bankSwiftCode=" + bankSwiftCode : "") + 
      (branchCode ? "&branchCode=" + branchCode : ""), httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<BankBranchFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<BankBranchDto>> {
    return this._httpApiClient.post("/admin/bank-branch/paged", filters, httpApiRequestOptions);
  }

  suggest(
    bankBranchFilterDto: SuggestSearchDto<BankBranchFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<BankBranchDto>> {
    return this._httpApiClient.post("/admin/bank-branch/suggest", bankBranchFilterDto, httpApiRequestOptions);
  }

}
