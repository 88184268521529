import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PaymentOrderDto } from '../../../../api/dtos/PaymentOrderDto';
import { ApiAdminOperation } from '../../../../api/endpoints/ApiAdminOperation';
import { ApiAdminPaymentOrder } from '../../../../api/endpoints/ApiAdminPaymentOrder';
import { OrderStatus } from '../../../../api/enums/OrderStatus';
import { OrderType } from '../../../../api/enums/OrderType';
import { OperationService } from '../../operation/operation.service';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Component({
  selector: 'mf-payment-order-create',
  templateUrl: './payment-order-create.component.html',
  styleUrls: ['./payment-order-create.component.scss'],
})
export class PaymentOrderCreateComponent {
  constructor(
    private _apiAdminPaymentOrder: ApiAdminPaymentOrder,
    private _apiAdminOperation: ApiAdminOperation,
    private _modalService: ModalService,
    private _operationService: OperationService
  ) {
    this.loadOperation();
  }

  paymentOrderDto: PaymentOrderDto;

  async loadOperation() {
    const operationDto = await this._apiAdminOperation.getById(this._modalService.openData.operationId);
    this.paymentOrderDto = new PaymentOrderDto();
    this.paymentOrderDto.id = GUID_EMPTY;
    this.paymentOrderDto.operationId = operationDto.id;
    this.paymentOrderDto.operation = operationDto;
    this.paymentOrderDto.clientId = operationDto.clientId;
    this.paymentOrderDto.type = OrderType.Payment;
    this.paymentOrderDto.status = OrderStatus.Draft;
  }

  async save(paymentOrderDto: PaymentOrderDto) {
    const paymentOrder = await this._apiAdminPaymentOrder.create(paymentOrderDto);
    this._modalService.closeAsSuccess('La orden de pago se creó con éxito');
    this._operationService.reloadOperation(paymentOrder.operation.id);
  }
}
