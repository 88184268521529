// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class FiltersDto  {
  defaultTextSearch: string = '';
  includeInactive: boolean = false;
}

export interface FiltersDtoFormGroup  {
  defaultTextSearch?: any;
  includeInactive?: any;
}
