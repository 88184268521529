// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum AccreditationStatus {
	Pending = 1,
	CheckingBalances = 2,
	GeneratingFiles = 3,
	WaitingFilesUploadConfirmation = 4,
	CompletedOk = 100,
	CompletedWithErrors = 101,
	Cancelled = 102,
}
