<mf-page title="Filtros de búsqueda">
  @for (gridFilter of gridFiltersService.gridFilters; track $index) {
  <mf-grid-filter
    [gridFilter]="gridFilter"
    [(ngModel)]="gridFiltersService.editingFilters[gridFilter.propertyName]"></mf-grid-filter>
  }
  <mf-row-buttons>
    <mf-button-secondary
      text="Aplicar filtros"
      (click)="applyFiltersClick()"></mf-button-secondary>
  </mf-row-buttons>
</mf-page>
