import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { IGridColumn, CellValueType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { enumName_mi_shared } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { GatewayDto } from '../../../api/dtos/GatewayDto';
import { GatewayFiltersDto } from '../../../api/dtos/GatewayFiltersDto';
import { ApiAdminGateway } from '../../../api/endpoints/ApiAdminGateway';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { GatewayCreateComponent } from './gateway-create.component';
import { GatewayEditComponent } from './gateway-edit.component';

@Component({
  selector: 'mf-gateway-grid',
  templateUrl: './gateway-grid.component.html',
  styleUrls: ['./gateway-grid.component.scss'],
})
export class GatewayGridComponent {
  constructor(private _apiAdminGateway: ApiAdminGateway, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  gateway: GatewayDto;

  columns: IGridColumn<GatewayDto>[] = [
    {
      field: nameof<GatewayDto>((g) => g.name),
      header: 'Nombre',
      width: 20,
      filter: {
        propertyName: nameof<GatewayFiltersDto>((g) => g.defaultTextSearch),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<GatewayDto>((g) => g.country),
      header: 'País',
      width: 20,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_shared.Country,
        enumNameModule: moduleName.shared,
      },
      filter: {
        propertyName: nameof<GatewayFiltersDto>((g) => g.countryList),
        type: FilterType.EnumList,
        enumName: enumName_mi_shared.Country,
        enumNameModule: moduleName.shared,
      },
    },
    {
      field: nameof<GatewayDto>((g) => g.domain),
      header: 'Largo máximo',
      width: 10,
    },
    {
      field: nameof<GatewayDto>((g) => g.domain),
      header: 'Dominio',
      width: 10,
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (gatewayDto: GatewayDto) => {
        this._modalService.openRightModal(GatewayEditComponent, {
          openData: gatewayDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<GatewayFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminGateway.getPaged(search, httpApiRequestOptions);

  openNewGatewayPopup() {
    this._modalService.openRightModal(GatewayCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
