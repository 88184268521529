// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { PersonDto, PersonDtoFormGroup } from './PersonDto';

export class CompanyDto extends PersonDto {
  name: string = '';
  commercialName: string = '';
}

export interface CompanyDtoFormGroup extends PersonDtoFormGroup {
  name?: any;
  commercialName?: any;
}
