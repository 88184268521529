import { Component } from '@angular/core';
import { OperationDto } from '../../../api/dtos/OperationDto';
import { ApiOperation } from '../../../api/endpoints/ApiOperation';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-operation-details',
  templateUrl: './operation-details.component.html',
  styleUrls: ['./operation-details.component.scss'],
})
export class OperationDetailsComponent {
  constructor(private _apiOperation: ApiOperation, modalService: ModalService) {
    this.load(modalService.openData);
  }

  operationDto: OperationDto;

  async load(id: string) {
    this.operationDto = await this._apiOperation.getById(id);
  }
}
