<mf-form
  [formGroup]="gatewayBankAccountForm"
  [entity]="gatewayBankAccountDto"
  (save)="save.emit($event)">
  <mf-column>
    <mf-autocomplete-bank
      formControlName="bank"
      (itemIdChanged)="gatewayBankAccountForm.patchValue({ bankId: $event })" />
  </mf-column>
  <mf-column>
    <mf-autocomplete-bank-branch
      formControlName="bankBranch"
      [bankId]="bankId"
      (itemIdChanged)="gatewayBankAccountForm.patchValue({ bankBranchId: $event })" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="accountNumber" />
  </mf-column>
  <mf-column>
    <mf-autocomplete-person
      formControlName="person"
      (itemIdChanged)="gatewayBankAccountForm.patchValue({ personId: $event })" />
  </mf-column>
  <mf-column>
    <mf-field-select
      formControlName="accountType"
      [enumName]="enumName.AccountType" />
  </mf-column>
  <mf-column>
    <mf-field-select
      formControlName="currency"
      [enumName]="enumName.Currency" />
  </mf-column>
</mf-form>
