import { Component, Optional, Self, ElementRef, Input, Inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { PersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';
import { PersonType } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';
import { PersonFiltersDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/PersonFiltersDto';
import { SuggestSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { IAutocompleteService } from '../auto-complete/IAutocomplete';
import { IHttpApiRequestOptions } from '../../IHttpApiRequestOptions';


@Component({
  selector: 'mf-autocomplete-person',
  templateUrl: './autocomplete-person.component.html',
  styleUrls: ['./autocomplete-person.component.scss'],
})
export class AutocompletePersonComponent extends AutoCompleteBaseEntityComponent<PersonDto | null, PersonFiltersDto> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef,
  @Inject('IAutocompleteService') public autocompleteService: IAutocompleteService) {
    super(ngControl, elRef);
  }

  override defaultOrderBy = nameof<PersonDto>((p) => p.fullName);

  @Input()
  personType?: PersonType;

  override setDefaultFilters() {
    this.filters.type = this.personType ?? null;
  }

  override endpointSuggest = (search: SuggestSearchDto<PersonFiltersDto>, httpApiRequestOptions: IHttpApiRequestOptions) => {
    return this.autocompleteService.suggestPerson(search, httpApiRequestOptions);
  };
}
