// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { PersonDto } from "../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto";
import { NaturalPersonDto } from "../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto";
import { CompanyDto } from "../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { PersonFiltersDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/PersonFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminPerson {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    personDto: PersonDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PersonDto> {
    return this._httpApiClient.post("/client-admin/person", personDto, httpApiRequestOptions);
  }

  edit(
    personDto: PersonDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PersonDto> {
    return this._httpApiClient.put("/client-admin/person", personDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PersonDto> {
    return this._httpApiClient.get("/client-admin/person/" + id, httpApiRequestOptions);
  }

  getNaturalPersonById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<NaturalPersonDto> {
    return this._httpApiClient.get("/client-admin/person/natural-person/" + id, httpApiRequestOptions);
  }

  getCompanyById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CompanyDto> {
    return this._httpApiClient.get("/client-admin/person/company/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<PersonFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<PersonDto>> {
    return this._httpApiClient.post("/client-admin/person/paged", filters, httpApiRequestOptions);
  }

  suggest(
    personFilterDto: SuggestSearchDto<PersonFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<PersonDto>> {
    return this._httpApiClient.post("/client-admin/person/suggest", personFilterDto, httpApiRequestOptions);
  }

}
