// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { IdDocumentDto } from "../../../../../libs/utils/ts-shared/src/lib/api/dtos/IdDocumentDto";

@Injectable({providedIn: 'root'})
export class ApiIdDocument {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getByCountry(
    country: number,
    
    personType: number,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<number[]> {
    return this._httpApiClient.get("/id-document/by-country?" + 
      (country ? "&country=" + country : "") + 
      (personType ? "&personType=" + personType : ""), httpApiRequestOptions);
  }

  validate(
    idDocumentDto: IdDocumentDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<boolean> {
    return this._httpApiClient.post("/id-document/validate", idDocumentDto, httpApiRequestOptions);
  }

}
