@if (buttonName && menuItems) {
<button
  mat-button
  [matMenuTriggerFor]="menu">
  <div>{{ buttonName }}</div>
  <div class="client-name">{{ buttonLegend }}</div>
</button>
<mat-menu #menu="matMenu">
  @for (item of menuItems; track item.label) { @if (item.isToggle) {
  <mf-field-toggle
    [label]="item.label"
    [(ngModel)]="item.toggleValue"
    (click)="item.onClick()"></mf-field-toggle>
  } @else {

  <button
    mat-menu-item
    (click)="item.onClick()">
    {{ item.label }}
  </button>
  } }
</mat-menu>
}
