import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MFAuthService } from './services/mf-auth.service';
import { UtilsNgSharedComponentsModule } from '../../../ng-shared-components/src/lib/utils-ng-shared-components.module';
import { LoginService } from './services/login.service';

@NgModule({
  declarations: [],
  exports: [],
  providers: [MFAuthService, LoginService],
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatCardModule,
    UtilsNgSharedComponentsModule,
  ],
})
export class UtilsAuthSharedModule {}
