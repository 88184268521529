<mf-page
  title="Cancelar transferencias desde la cuenta {{ accreditationsByAccountDto.sourceAccount.bank.name }} 
  en {{ accreditationsByAccountDto.sourceAccount.currency | customCurrency }}">
  <mf-form
    [formGroup]="accreditationsByAccountCancelForm"
    (save)="submit($event)">
    <mf-row>
      <mf-field-text
        [multiline]="true"
        formControlName="notes" />
    </mf-row>
  </mf-form>
  <p>{{ accreditationsByAccountCancelForm.value | json }}</p>
</mf-page>
