import { Component } from '@angular/core';
import { ApiAdminLoggingViewer } from '../../../../api/endpoints/ApiAdminLoggingViewer';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { LogEventDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventDto';
import { LogEventCompleteDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventCompleteDto';
import { enumName_logging } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/enums/_enumName.logging';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';

@Component({
  selector: 'mf-logging-details',
  templateUrl: './logging-details.component.html',
  styleUrls: ['./logging-details.component.scss'],
})
export class LoggingDetailsComponent {
  constructor(private _apiAdminLoggingViewer: ApiAdminLoggingViewer, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  enumName = enumName_logging;
  moduleName = moduleName;

  logEventDto: LogEventCompleteDto;

  async load(logEventDto: LogEventDto) {
    this.logEventDto = await this._apiAdminLoggingViewer.getEventComplete(logEventDto.partitionKey, logEventDto.rowKey);
  }
}
