import { Component, ElementRef, Inject, Input, Optional, Self } from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, Validators } from '@angular/forms';
import { FieldMatBaseComponent } from '../fields/base/field-mat-base.component';
import { CustomFormBuilder } from '../validation/form-builder/custom-form-builder';
import { GUID_EMPTY } from '../../../../ts-shared/src/lib/utils-guid';
import { IHttpApiRequestOptions } from '../IHttpApiRequestOptions';
import { PersonType } from '../../../../ts-shared/src/lib/api/enums/PersonType';
import { IdDocumentType } from '../../../../ts-shared/src/lib/api/enums/IdDocumentType';
import { enumName_mi_shared } from '../../../../ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { IdDocumentDto, IdDocumentDtoFormGroup } from '../../../../ts-shared/src/lib/api/dtos/IdDocumentDto';
import { Country } from '../../../../ts-shared/src/lib/api/enums/Country';
import { HttpApiRequestOptions } from '../../../../../../apps/mi-pspc/src/api/endpoints/HttpApiRequestOptions';
import { IApiIdDocument } from './IApiIdDocument';
import { moduleName } from '../../../../ts-shared/src/lib/module-names';

@Component({
  selector: 'mf-id-document',
  templateUrl: './id-document.component.html',
  styleUrls: ['./id-document.component.scss'],
})
export class IdDocumentComponent extends FieldMatBaseComponent<IdDocumentDto> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _cfb: CustomFormBuilder,
    @Inject('IApiIdDocument') private _apiIdDocument: IApiIdDocument
  ) {
    super(ngControl, elRef);
  }

  @Input()
  personType: PersonType;

  enumName = enumName_mi_shared;
  enumNameModule = moduleName.shared;
  idDocumentTypesList?: IdDocumentType[];
  documentNumberErrorMessage: string;
  documentNumberValidation = true;
  type = PersonType;
  loadingValidation = false;

  override get controlType(): string {
    return 'field-idDocument';
  }

  get country(): Country {
    return this.idDocumentForm.value.country as Country;
  }

  protected override onWriteValue(val: IdDocumentDto): void {
    this.idDocumentForm.patchValue(val);
    this.idDocumentForm.valueChanges.subscribe(() => {
      this.value = this.idDocumentForm.value as IdDocumentDto;
    });
  }

  idDocumentForm = this._cfb.group<IdDocumentDtoFormGroup>({
    id: [GUID_EMPTY],
    country: ['', Validators.required, 'País'],
    type: ['', Validators.required, 'Tipo de documento'],
    documentNumber: [
      '',
      {
        validators: [Validators.required],
        asyncValidators: [
          async (control: AbstractControl): Promise<ValidationErrors | null> => {
            if (!control.value || !control.dirty) {
              return null;
            }

            this.loadingValidation = true;
            const isValid = await this.validateDocumentNumber(control.value);
            this.loadingValidation = false;
            return !isValid
              ? {
                  forbiddenName: {
                    displayMessage: 'El numero de identificación no es válido',
                  },
                }
              : null;
          },
        ],
        updateOn: 'blur',
      },
      'Número de documento',
    ],
  });

  ngOnInit(): void {
    if (this.idDocumentForm.value.country) {
      this.loadIdDocumentByCountry();
    }
  }

  async loadIdDocumentByCountry() {
    if (!this.idDocumentForm.value.country) {
      return;
    }
    this.idDocumentTypesList = undefined;
    this.idDocumentTypesList = await this._apiIdDocument.getByCountry(this.idDocumentForm.value.country as Country, this.personType, {
      preventSpinner: true,
    } as IHttpApiRequestOptions);
  }

  validateDocumentNumber(documentNumber: string): Promise<boolean> {
    let idDocumentDto = this.idDocumentForm.value as IdDocumentDto;
    idDocumentDto.documentNumber = documentNumber;
    return this._apiIdDocument.validate(idDocumentDto, { preventSpinner: true } as IHttpApiRequestOptions);
  }
}
