import { Component } from '@angular/core';
import {} from '@angular/material/dialog';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { AppUserDto } from '../../../api/dtos/AppUserDto';
import { AuthRoleDto } from '../../../api/dtos/AuthRoleDto';
import { ApiAdminAppUser } from '../../../api/endpoints/ApiAdminAppUser';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthUserRolesUpdateDto } from '../../../api/dtos/AuthUserRolesUpdateDto';

@Component({
  selector: 'mf-app-user-roles',
  templateUrl: './app-user-roles.component.html',
  styleUrls: ['./app-user-roles.component.scss'],
})
export class AppUserRolesComponent {
  constructor(private _modalService: ModalService, private _apiAdminAppUser: ApiAdminAppUser) {
    this.authUserRolesUpdateDto = new AuthUserRolesUpdateDto();
    this.load();
  }

  async load() {
    this.appUserDto = await this._apiAdminAppUser.getById(this._modalService.openData);
    this.authUserRolesUpdateDto.authUserId = this.appUserDto.authUserId;
    this.userCurrentRoles = await this._apiAdminAppUser.rolesUser(this.appUserDto.authUserId);
    this.allRoles = await this._apiAdminAppUser.rolesAll();
    this.allRoles.forEach((role) => {
      const userHasRole = this.userCurrentRoles.find((r) => r.name === role.name) != null;
      this.rolesFrom.addControl(role.name, new FormControl(userHasRole));
    });
  }

  rolesFrom = new FormGroup({});
  authUserRolesUpdateDto = new AuthUserRolesUpdateDto();
  appUserDto: AppUserDto;
  userCurrentRoles: AuthRoleDto[];
  allRoles: AuthRoleDto[];

  async save(formValue: any) {
    this.authUserRolesUpdateDto.rolesToRemove = this.userCurrentRoles.filter((r) => formValue[r.name] === false).map((r) => r.name);
    this.authUserRolesUpdateDto.rolesToAdd = this.allRoles
      .filter((r) => formValue[r.name] === true && this.userCurrentRoles.find((ur) => ur.name === r.name) == null)
      .map((r) => r.name);

    const roles = await this._apiAdminAppUser.rolesUpdate(this.authUserRolesUpdateDto);
    this._modalService.closeAsSuccess('Los roles del usuario se actualizaron con éxito', roles);
  }
}
