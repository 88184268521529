export const Icons = {
  accountCircle: 'account_circle',
  accountBalance: 'account_balance',
  accreditations: 'accreditations',
  computer: 'computer',
  conciliations: 'compare_arrows',
  chevronLeft: 'chevron_left',
  checkCircle: 'check_circle',
  chevronRight: 'chevron_right',
  check: 'check',
  clear: 'clear',
  details: 'details',
  delete: 'delete',
  download: 'download',
  edit: 'edit',
  error: 'warning',
  expandLess: 'expand_less',
  expandMore: 'expand_more',
  file: 'file_upload',
  filterAlt: 'filter_alt',
  filterList: 'filter_list',
  firstPage: 'first_page',
  google: 'label_important',
  group: 'group',
  issue: 'report',
  lastPage: 'last_page',
  list: 'list',
  logout: 'logout',
  payments: 'payments',
  person: 'person',
  markAsRead: 'visibility',
  markAsUnread: 'visibility_off',
  menu: 'menu',
  moreVert: 'more_vert',
  notifications: 'notifications',
  openInNew: 'open_in_new',
  refresh: 'refresh',
  search: 'search',
  settings: 'settings',
  undo: 'undo',
  upload: 'upload',
  bcuReport: 'post_add',
};
